import React from 'react';
import { Theme, ThemeColours } from '../themes';

import './island.css';

function Island(): JSX.Element {

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300">
			<polyline fill="#00FFFF" points="1,300 1,0 1001,0 1001,300 " />
			<g id="backSea" className="sea">
			<g id="backSea1">
				<path fill="#0068FF" d="M1002,183c0,0-46.809,58.5-82,0c0,0-23.186,33.84-58.186,30.045c0,0-33.781,3.705-53.781-30.045
					c0,0-36.267,63.75-100.017,0c0,0-41.258,67.5-103.758,0c0,0-41.254,66.25-97.504,0c0,0-57.502,67.5-103.752,0
					c0,0-46.251,70-92.501,0c0,0-41.251,63.75-85.001,0c0,0-50,50-87.5,0c0,0-41.751,43.75-73.001,0c0,0-39,50-67,0v117h1004" />
				<path fill="none" stroke="#FFFFFF" strokeWidth={3} strokeLinecap="round" strokeMiterlimit={10} d="M1000,183
					c0,0-44.809,58.5-80,0c0,0-23.186,33.84-58.186,30.045c0,0-33.781,3.705-53.781-30.045c0,0-36.267,63.75-100.017,0
					c0,0-41.258,67.5-103.758,0c0,0-41.254,66.25-97.504,0c0,0-57.502,67.5-103.752,0c0,0-46.251,70-92.501,0
					c0,0-41.251,63.75-85.001,0c0,0-50,50-87.5,0c0,0-41.751,43.75-73.001,0c0,0-37,50-65,0" />
			</g>
			<g id="backSea2">
				<path fill="#0068FF" d="M0,183c0,0-49.809,58.5-85,0c0,0-18.186,33.84-53.186,30.045c0,0-33.781,3.705-53.781-30.045
					c0,0-36.266,63.75-100.016,0c0,0-41.258,67.5-103.758,0c0,0-41.254,66.25-97.504,0c0,0-57.502,67.5-103.752,0
					c0,0-46.251,70-92.501,0c0,0-41.251,63.75-85.001,0c0,0-50,50-87.5,0c0,0-36.751,43.75-68.001,0c0,0-42,50-70,0v117H-1" />
				<path fill="none" stroke="#FFFFFF" strokeWidth={3} strokeMiterlimit={10} d="M0,183c0,0-49.809,58.5-85,0
					c0,0-18.186,33.84-53.186,30.045c0,0-33.781,3.705-53.781-30.045c0,0-36.266,63.75-100.016,0c0,0-41.258,67.5-103.758,0
					c0,0-41.254,66.25-97.504,0c0,0-57.502,67.5-103.752,0c0,0-46.251,70-92.501,0c0,0-41.251,63.75-85.001,0c0,0-50,50-87.5,0
					c0,0-36.751,43.75-68.001,0c0,0-42,50-70,0" />
			</g>
			</g>
			<path id="island" fill="#F4F06E" d="M74.5,300c0,0,441.091-300,796.25-1" />
			<g id="birdsWrapper">
			<g id="birds">
				<g id='bird1Wrapper' className='birdWrapper'>
					<g id="bird1" className="bird">
					<path className="leftWing" fill="#FFFFFF" d="M-102.971,70.807c0,0,11.639-10.227,13.384-9.332c0,0,2.774,0.212,11.115,6.401
							c0,0,1.097,3.796-0.465,3.704c-3.573-0.211-9.821-6.943-11.667-7.596C-90.605,63.984-100.935,69.062-102.971,70.807z" />
					<path className="rightWing" fill="#FFFFFF" d="M-66.43,63.984c-1.846,0.653-8.095,7.385-11.667,7.596c-1.562,0.092-0.466-3.704-0.465-3.704
							c8.34-6.188,11.115-6.401,11.115-6.401c1.745-0.895,13.384,9.332,13.384,9.332C-56.1,69.062-66.43,63.984-66.43,63.984z" />
					<g>
						<path fill="#FFFFFF" d="M-89.909,67.727c0,0,12.223-0.527,15.623-0.007c0,0-0.205,0.116,0,0c3.315-1.868,6.346-0.618,6.346,0.007
								c0,0.702-2.595,0.917-3.312,1.564C-74.3,72.039-79.894,73.167-89.909,67.727z" />
						<path fill="#FFB46A" d="M-68.315,67.398l2.312,0.25c0,0,0.548,0.223,0.267,0.316l-2.759,0.131
								C-68.495,68.096-68.815,67.7-68.315,67.398z" />
					</g>
					</g>
				</g>
				<g id='bird3Wrapper' className='birdWrapper'>
					<g id="bird3" className="bird">
					<path className="leftWing" fill="#FFFFFF" d="M-60.202,65.726c0,0,11.639-10.227,13.384-9.332c0,0,2.774,0.212,11.115,6.401
							c0,0,1.097,3.796-0.465,3.704c-3.573-0.211-9.821-6.943-11.667-7.596C-47.835,58.903-58.166,63.981-60.202,65.726z" />
					<path className="rightWing" fill="#FFFFFF" d="M-23.66,58.903c-1.846,0.653-8.095,7.385-11.667,7.596c-1.562,0.092-0.466-3.704-0.465-3.704
							c8.34-6.188,11.115-6.401,11.115-6.401c1.745-0.895,13.384,9.332,13.384,9.332C-13.33,63.981-23.66,58.903-23.66,58.903z" />
					<g>
						<path fill="#FFFFFF" d="M-47.14,62.646c0,0,12.223-0.527,15.623-0.007c0,0-0.205,0.116,0,0c3.315-1.868,6.346-0.618,6.346,0.007
								c0,0.702-2.595,0.917-3.312,1.564C-31.53,66.958-37.125,68.085-47.14,62.646z" />
						<path fill="#FFB46A" d="M-25.546,62.317l2.312,0.25c0,0,0.548,0.223,0.267,0.316l-2.759,0.131
								C-25.726,63.015-26.046,62.619-25.546,62.317z" />
					</g>
					</g>
				</g>
				<g id='bird2Wrapper' className='birdWrapper'>
					<g id="bird2" className="bird">
					<path className="leftWing" fill="#FFFFFF" d="M-84.075,84.249c0,0,11.639-10.227,13.384-9.332c0,0,2.774,0.212,11.115,6.401
							c0,0,1.097,3.796-0.465,3.704c-3.573-0.211-9.821-6.943-11.667-7.596C-71.709,77.425-82.039,82.503-84.075,84.249z" />
					<path className="rightWing" fill="#FFFFFF" d="M-47.534,77.425c-1.846,0.653-8.095,7.385-11.667,7.596c-1.562,0.092-0.466-3.704-0.465-3.704
							c8.34-6.188,11.115-6.401,11.115-6.401c1.745-0.895,13.384,9.332,13.384,9.332C-37.204,82.503-47.534,77.425-47.534,77.425z" />
					<g>
						<path fill="#FFFFFF" d="M-71.013,81.168c0,0,12.223-0.527,15.623-0.007c0,0-0.205,0.116,0,0c3.315-1.868,6.346-0.618,6.346,0.007
								c0,0.702-2.595,0.917-3.312,1.564C-55.404,85.48-60.998,86.608-71.013,81.168z" />
						<path fill="#FFB46A" d="M-49.419,80.84l2.312,0.25c0,0,0.548,0.223,0.267,0.316l-2.759,0.131
								C-49.599,81.538-49.919,81.142-49.419,80.84z" />
					</g>
					</g>
				</g>
			</g>
			</g>
			<g id="tree">
			<ellipse id="shadow" opacity="0.1" cx="492.007" cy="201.418" rx="52.301" ry="9.918" />
			<path id="treeTrunk" fill="#BC8A61" d="M490.895,76c0,0-2,88-8.599,126.5c0,0,11.705,6.5,17.705,0c0,0-5.824-118.527-6.482-126.5
				C492.77,66.929,490.895,76,490.895,76z" />
			<g id="coconuts">
				<circle fill="#754C24" cx="487.848" cy="73.272" r="8.105" />
				<circle fill="#754C24" cx="498.848" cy="78.272" r="8.105" />
				<circle fill="#754C24" cx="484.848" cy="80.272" r="8.105" />
			</g>
			<g id="leaves">
				<g id="leaf4">
				<g>
					<path id="stem4'" className="stem" fill="#39B54A" d="M378.276,104.536c0,0,1.524-1.146,4.281-2.993c1.377-0.925,3.056-2.038,5.009-3.266
							c0.979-0.609,2.002-1.29,3.119-1.943c1.141-0.624,2.34-1.279,3.592-1.963c2.525-1.331,5.254-2.794,8.2-4.217
							c2.995-1.311,6.241-2.494,9.567-3.759c0.83-0.319,1.667-0.641,2.51-0.965c0.861-0.281,1.715-0.593,2.578-0.898
							c1.729-0.603,3.451-1.279,5.187-1.962c1.73-0.695,3.461-1.433,5.241-2.063c1.765-0.675,3.531-1.37,5.296-2.081l2.649-1.059
							l2.689-0.932c1.795-0.606,3.59-1.199,5.379-1.761c0.888-0.304,1.791-0.543,2.69-0.771c0.899-0.235,1.794-0.468,2.685-0.701
							c1.782-0.452,3.532-0.951,5.282-1.321c1.75-0.373,3.479-0.694,5.172-1.017c0.848-0.148,1.677-0.35,2.515-0.449
							c0.836-0.104,1.662-0.206,2.478-0.307c1.632-0.183,3.207-0.44,4.75-0.544c1.543-0.098,3.033-0.193,4.464-0.284
							c1.427-0.111,2.809-0.054,4.112-0.084c1.307-0.017,2.543-0.013,3.709,0.06c1.165,0.053,2.252,0.106,3.257,0.18
							c1.005,0.101,1.926,0.191,2.755,0.294c1.658,0.226,2.954,0.421,3.832,0.613c0.875,0.183,1.341,0.311,1.341,0.311l-0.053,0.296
							c0,0-0.468-0.033-1.354-0.04c-0.88-0.032-2.182-0.029-3.826,0.016c-0.822,0.028-1.731,0.06-2.723,0.094
							c-0.989,0.063-2.059,0.132-3.2,0.205c-1.141,0.042-2.352,0.197-3.628,0.308c-1.275,0.135-2.618,0.201-4.01,0.416
							c-1.395,0.175-2.846,0.357-4.349,0.545c-1.5,0.199-3.044,0.47-4.634,0.712c-3.186,0.446-6.509,1.157-9.976,1.73
							c-1.731,0.301-3.466,0.742-5.241,1.1c-1.765,0.402-3.578,0.709-5.361,1.2c-1.789,0.469-3.6,0.916-5.425,1.353l-2.75,0.635
							l-2.721,0.763c-3.62,1.047-7.293,1.994-10.721,3.437c-1.729,0.684-3.441,1.365-5.107,2.102c-0.834,0.365-1.667,0.719-2.48,1.1
							c-0.799,0.415-1.592,0.826-2.379,1.234c-3.134,1.659-6.151,3.263-9.063,4.666c-2.848,1.524-5.597,2.847-8.129,4.089
							c-1.277,0.602-2.5,1.179-3.665,1.728c-1.151,0.589-2.244,1.148-3.271,1.673c-2.054,1.05-3.869,1.923-5.368,2.634
							c-2.997,1.425-4.761,2.148-4.761,2.148L378.276,104.536z" />
				</g>
				<g>
					<path fill="none" d="M462.986,72.613" />
				</g>
				<g>
					<g>
					<path fill="#39B54A" d="M458.076,51.871c1.445,0.479,2.844,1.039,4.211,1.653c1.371,0.607,2.72,1.252,4.045,1.938
								c0.668,0.333,1.328,0.681,1.978,1.046c0.651,0.362,1.312,0.708,1.956,1.083c1.289,0.749,2.531,1.576,3.742,2.458
								c2.407,1.789,4.774,3.646,7.396,5.066c2.582,1.49,5.344,2.669,8.061,3.928l1.177,0.545l-0.911,1.193
								c-1.498,1.961-3.111,3.816-4.692,5.7c-1.589,1.877-3.208,3.727-4.935,5.479c-0.857,0.88-1.733,1.744-2.617,2.601
								c-0.872,0.868-1.789,1.695-2.686,2.54c-1.831,1.656-3.686,3.291-5.688,4.79l-0.223-0.202c1.313-2.129,2.774-4.124,4.26-6.096
								c0.761-0.969,1.502-1.957,2.289-2.903c0.773-0.958,1.555-1.908,2.354-2.841c1.59-1.876,3.286-3.656,5.013-5.407
								c1.734-1.744,3.438-3.518,5.256-5.186l0.265,1.738c-2.433-1.745-4.819-3.569-7.387-5.083c-2.527-1.583-5.31-2.729-8.052-3.944
								c-1.363-0.619-2.695-1.293-3.981-2.047c-0.645-0.376-1.271-0.78-1.906-1.169c-0.638-0.386-1.266-0.789-1.884-1.207
								c-1.25-0.815-2.475-1.671-3.679-2.566c-1.208-0.888-2.384-1.829-3.513-2.852L458.076,51.871z" />
					</g>
					<g>
					<path fill="#39B54A" d="M451.576,51.871c1.455,0.493,2.863,1.066,4.24,1.693c1.381,0.619,2.74,1.275,4.074,1.974
								c0.672,0.34,1.34,0.69,1.993,1.062l1.972,1.099c1.299,0.76,2.559,1.585,3.777,2.481c2.435,1.797,4.81,3.693,7.443,5.149
								c2.596,1.521,5.368,2.743,8.108,4.018l1.117,0.52l-0.852,1.165c-1.498,2.048-3.138,3.97-4.732,5.932
								c-1.606,1.951-3.239,3.878-4.98,5.71c-0.869,0.917-1.752,1.822-2.649,2.715c-0.881,0.906-1.809,1.772-2.715,2.655
								c-1.855,1.731-3.73,3.443-5.761,5.019l-0.226-0.199c1.324-2.202,2.804-4.268,4.303-6.314c0.77-1.005,1.521-2.028,2.314-3.011
								c0.781-0.996,1.574-1.98,2.383-2.951c1.613-1.946,3.334-3.795,5.081-5.622c1.761-1.815,3.475-3.671,5.331-5.4l0.267,1.685
								c-2.447-1.775-4.863-3.603-7.454-5.131c-2.554-1.593-5.365-2.747-8.119-4c-1.375-0.629-2.709-1.329-4.004-2.094l-1.919-1.188
								c-0.644-0.391-1.273-0.803-1.897-1.225c-1.259-0.827-2.494-1.695-3.706-2.601c-1.217-0.898-2.403-1.85-3.542-2.882
								L451.576,51.871z" />
					</g>
					<g>
					<path fill="#39B54A" d="M446.083,51.875c1.356,0.532,2.661,1.141,3.933,1.8c1.276,0.652,2.528,1.339,3.754,2.067
								c2.457,1.445,4.879,2.944,7.068,4.791c1.107,0.905,2.175,1.869,3.285,2.77c0.545,0.465,1.124,0.88,1.686,1.32
								c0.575,0.421,1.156,0.832,1.754,1.218c1.191,0.777,2.405,1.523,3.661,2.206c1.253,0.686,2.523,1.346,3.787,2.017l1.042,0.554
								l-0.871,1.095c-1.521,1.913-3.182,3.688-4.803,5.503c-1.635,1.801-3.288,3.584-5.049,5.262
								c-0.877,0.842-1.771,1.668-2.677,2.482c-0.894,0.827-1.825,1.614-2.743,2.416c-1.871,1.57-3.764,3.12-5.805,4.522l-0.214-0.21
								c1.36-2.07,2.871-3.992,4.402-5.895c0.783-0.935,1.552-1.883,2.36-2.792c0.795-0.923,1.603-1.832,2.427-2.727
								c1.641-1.795,3.391-3.483,5.158-5.155c1.78-1.657,3.521-3.354,5.403-4.914l0.172,1.649c-1.104-0.909-2.202-1.829-3.316-2.723
								c-1.112-0.898-2.266-1.732-3.442-2.534c-0.586-0.403-1.188-0.783-1.798-1.152c-0.623-0.35-1.228-0.725-1.866-1.05
								c-1.258-0.68-2.558-1.295-3.817-1.97c-2.546-1.313-4.86-2.974-7.138-4.689c-1.143-0.851-2.258-1.745-3.35-2.672
								c-1.096-0.921-2.16-1.892-3.171-2.94L446.083,51.875z" />
					</g>
					<g>
					<path fill="#39B54A" d="M438.085,51.876c1.383,0.57,2.713,1.218,4.01,1.915c1.302,0.689,2.579,1.414,3.828,2.179
								c1.267,0.741,2.479,1.562,3.709,2.354c1.212,0.82,2.382,1.702,3.505,2.651c1.131,0.939,2.222,1.938,3.354,2.875
								c1.13,0.94,2.287,1.841,3.506,2.651c0.614,0.398,1.212,0.82,1.842,1.194c0.62,0.39,1.251,0.764,1.891,1.125
								c1.276,0.725,2.572,1.422,3.86,2.131l1.036,0.57l-0.891,1.074c-1.611,1.943-3.36,3.746-5.072,5.586
								c-0.871,0.905-1.723,1.829-2.61,2.718c-0.88,0.896-1.789,1.763-2.706,2.621c-0.921,0.854-1.856,1.692-2.808,2.517
								c-0.937,0.838-1.912,1.637-2.874,2.45c-1.958,1.591-3.935,3.162-6.061,4.582l-0.211-0.214c1.455-2.102,3.06-4.052,4.684-5.983
								c0.829-0.948,1.644-1.91,2.498-2.833c0.84-0.936,1.693-1.858,2.563-2.765c0.874-0.903,1.755-1.797,2.666-2.662
								c0.904-0.872,1.843-1.708,2.762-2.564c1.87-1.681,3.702-3.399,5.671-4.978l0.146,1.644c-1.127-0.944-2.247-1.899-3.385-2.827
								c-0.569-0.465-1.146-0.917-1.733-1.354c-0.576-0.452-1.188-0.856-1.78-1.285c-1.197-0.842-2.455-1.595-3.741-2.307
								c-1.283-0.716-2.609-1.37-3.895-2.084c-1.292-0.703-2.537-1.475-3.74-2.307c-1.185-0.861-2.389-1.693-3.537-2.604
								c-1.167-0.887-2.305-1.816-3.418-2.779c-1.119-0.957-2.204-1.962-3.237-3.044L438.085,51.876z" />
					</g>
					<g>
					<path fill="#39B54A" d="M431.083,54.216c1.345,0.513,2.634,1.11,3.89,1.755c0.639,0.308,1.247,0.66,1.87,0.991
								c0.626,0.326,1.222,0.699,1.832,1.047c1.22,0.7,2.403,1.455,3.574,2.229c1.169,0.775,2.271,1.655,3.349,2.564
								c1.072,0.921,2.131,1.863,3.228,2.748c0.557,0.429,1.105,0.87,1.681,1.272c0.571,0.408,1.16,0.79,1.752,1.166
								c1.18,0.759,2.405,1.453,3.654,2.109c1.244,0.665,2.509,1.297,3.741,1.979l1.18,0.653l-1.007,1.169
								c-1.456,1.69-2.988,3.303-4.507,4.93c-1.513,1.633-3.085,3.205-4.731,4.702c-0.816,0.754-1.661,1.482-2.5,2.214
								c-0.836,0.735-1.706,1.437-2.564,2.149c-1.742,1.399-3.517,2.768-5.418,4.005l-0.211-0.214c1.264-1.885,2.656-3.639,4.08-5.361
								c0.725-0.849,1.438-1.709,2.186-2.535c0.744-0.83,1.482-1.663,2.249-2.47c1.52-1.625,3.113-3.176,4.768-4.665
								c1.647-1.496,3.282-3.005,4.993-4.437l0.173,1.822c-1.101-0.877-2.169-1.805-3.259-2.7c-1.084-0.904-2.193-1.77-3.345-2.571
								c-0.574-0.404-1.152-0.802-1.748-1.174c-0.592-0.377-1.209-0.716-1.818-1.067c-1.237-0.675-2.512-1.293-3.771-1.932
								c-1.253-0.65-2.487-1.33-3.65-2.115c-1.163-0.786-2.312-1.591-3.426-2.451c-0.556-0.431-1.127-0.838-1.668-1.292
								c-0.543-0.449-1.102-0.877-1.63-1.349c-1.076-0.915-2.121-1.877-3.109-2.924L431.083,54.216z" />
					</g>
					<g>
					<path fill="#39B54A" d="M426.591,58.643c1.087,0.434,2.118,0.942,3.111,1.5c0.999,0.55,1.969,1.138,2.918,1.752
								c0.948,0.616,1.869,1.269,2.776,1.939c0.901,0.677,1.754,1.419,2.556,2.229c1.603,1.619,3.139,3.325,4.982,4.626
								c1.8,1.361,3.809,2.446,5.779,3.582l0.849,0.489l-0.649,1.077c-0.984,1.631-2.115,3.147-3.199,4.699
								c-1.097,1.542-2.222,3.062-3.46,4.494c-0.617,0.717-1.249,1.422-1.895,2.116c-0.631,0.707-1.31,1.375-1.967,2.061
								c-1.356,1.337-2.735,2.657-4.276,3.849l-0.235-0.186c0.8-1.776,1.762-3.425,2.746-5.057c0.513-0.799,1.005-1.614,1.545-2.392
								c0.524-0.79,1.062-1.57,1.616-2.337c1.103-1.538,2.318-2.986,3.562-4.412c1.257-1.417,2.468-2.869,3.824-4.207l0.199,1.566
								c-1.62-1.596-3.202-3.243-4.994-4.613c-1.747-1.43-3.801-2.455-5.789-3.567c-0.994-0.557-1.937-1.18-2.83-1.869
								c-0.888-0.695-1.763-1.409-2.609-2.159s-1.671-1.529-2.469-2.344c-0.802-0.809-1.566-1.666-2.274-2.598L426.591,58.643z" />
					</g>
					<g>
					<path fill="#39B54A" d="M419.585,62.8c1.027,0.332,2,0.743,2.944,1.198c0.948,0.447,1.869,0.936,2.772,1.447
								c1.788,1.053,3.567,2.12,5.109,3.533c0.784,0.687,1.522,1.441,2.297,2.143c0.764,0.717,1.574,1.366,2.422,1.959
								c0.855,0.583,1.731,1.137,2.649,1.629c0.911,0.502,1.857,0.951,2.789,1.424l0.805,0.408l-0.575,1.037
								c-0.905,1.632-1.962,3.147-3.002,4.676c-1.047,1.523-2.103,3.04-3.292,4.454c-1.181,1.42-2.426,2.791-3.721,4.123
								c-1.313,1.318-2.653,2.617-4.163,3.784l-0.238-0.183c0.739-1.76,1.65-3.388,2.586-4.996c0.955-1.593,1.959-3.149,3.028-4.656
								c1.062-1.512,2.256-2.922,3.458-4.325c1.209-1.398,2.401-2.81,3.747-4.104l0.229,1.445c-0.776-0.698-1.538-1.418-2.335-2.086
								c-0.79-0.679-1.623-1.295-2.476-1.881c-0.86-0.577-1.758-1.097-2.703-1.551c-0.934-0.468-1.904-0.884-2.828-1.368
								c-1.875-0.926-3.512-2.2-5.141-3.487c-0.805-0.659-1.592-1.34-2.353-2.063c-0.765-0.715-1.5-1.475-2.181-2.312L419.585,62.8z" />
					</g>
				</g>
				<g>
					<path fill="#39B54A" d="M410.577,66.696c1.033,0.241,2.01,0.574,2.96,0.954c0.484,0.174,0.938,0.398,1.409,0.595
							c0.468,0.202,0.923,0.423,1.375,0.65c0.906,0.453,1.793,0.936,2.656,1.458c0.863,0.523,1.66,1.156,2.44,1.817
							c0.775,0.669,1.539,1.357,2.342,1.979c0.802,0.624,1.661,1.154,2.546,1.64c1.769,0.977,3.725,1.641,5.572,2.485l1.078,0.493
							l-0.711,1.255c-0.771,1.356-1.598,2.671-2.426,3.986c-0.813,1.325-1.717,2.587-2.685,3.803c-0.477,0.613-0.991,1.2-1.491,1.796
							c-0.505,0.593-1.034,1.168-1.565,1.743c-1.077,1.139-2.195,2.249-3.455,3.258l-0.245-0.173c0.532-1.524,1.205-2.949,1.919-4.344
							c0.365-0.692,0.731-1.384,1.121-2.058c0.396-0.67,0.777-1.351,1.196-2.004c0.824-1.318,1.712-2.59,2.69-3.798
							c0.964-1.219,1.928-2.438,2.949-3.616l0.366,1.748c-1.61-1.238-3.112-2.656-4.803-3.762c-0.844-0.556-1.715-1.066-2.642-1.484
							c-0.925-0.419-1.891-0.773-2.845-1.145c-0.95-0.38-1.882-0.788-2.748-1.307c-0.866-0.518-1.708-1.076-2.531-1.665
							c-0.412-0.293-0.821-0.592-1.218-0.912c-0.394-0.324-0.804-0.621-1.185-0.966c-0.779-0.662-1.531-1.371-2.227-2.17
							L410.577,66.696z" />
				</g>
				<g>
					<path fill="#39B54A" d="M405.076,70.207c0.956,0.198,1.865,0.478,2.745,0.808c0.885,0.321,1.744,0.686,2.589,1.076
							c1.673,0.808,3.332,1.642,4.773,2.848c0.733,0.582,1.429,1.228,2.162,1.811c0.725,0.596,1.487,1.127,2.292,1.586
							c1.599,0.937,3.36,1.593,5.109,2.272l0.785,0.305l-0.387,1.112c-0.53,1.531-1.233,2.97-1.895,4.431
							c-0.347,0.722-0.675,1.454-1.041,2.165c-0.359,0.715-0.755,1.41-1.162,2.1c-0.411,0.687-0.842,1.363-1.291,2.03
							c-0.432,0.676-0.911,1.327-1.375,1.985c-0.969,1.295-1.962,2.577-3.144,3.758l-0.264-0.143c0.336-1.636,0.86-3.171,1.41-4.692
							c0.295-0.75,0.575-1.508,0.902-2.24c0.311-0.741,0.639-1.473,0.986-2.194c0.352-0.719,0.715-1.432,1.114-2.125
							c0.393-0.697,0.824-1.374,1.236-2.06c0.857-1.355,1.671-2.734,2.659-4.02l0.398,1.417c-1.454-1.186-2.894-2.394-4.498-3.321
							c-0.797-0.473-1.635-0.874-2.511-1.21c-0.869-0.35-1.774-0.636-2.642-0.986c-1.76-0.658-3.304-1.689-4.833-2.746
							c-0.756-0.542-1.498-1.11-2.214-1.721c-0.721-0.603-1.413-1.255-2.058-1.989L405.076,70.207z" />
				</g>
				<g>
					<path fill="#39B54A" d="M397.074,72.936c1.009,0.171,1.953,0.457,2.878,0.775c0.917,0.333,1.823,0.686,2.693,1.104
							s1.734,0.843,2.551,1.353c0.817,0.511,1.569,1.135,2.313,1.775c0.743,0.641,1.49,1.276,2.296,1.805
							c0.808,0.527,1.658,0.979,2.543,1.368c0.885,0.39,1.808,0.712,2.725,1.046c0.924,0.32,1.819,0.693,2.695,1.099l1.04,0.482
							l-0.485,1.264c-0.463,1.207-0.936,2.41-1.429,3.603c-0.505,1.188-1.093,2.335-1.733,3.459c-0.654,1.117-1.324,2.227-2.073,3.299
							c-0.741,1.076-1.529,2.129-2.453,3.119l-0.271-0.128c0.178-1.342,0.49-2.621,0.851-3.876c0.352-1.26,0.783-2.482,1.23-3.697
							c0.461-1.208,0.974-2.392,1.57-3.536c0.608-1.139,1.236-2.268,1.874-3.393l0.555,1.746c-0.8-0.541-1.58-1.115-2.332-1.742
							c-0.759-0.613-1.511-1.238-2.301-1.795c-0.79-0.559-1.615-1.054-2.483-1.474c-0.87-0.418-1.798-0.73-2.73-1.037
							c-0.932-0.307-1.855-0.63-2.714-1.066c-0.858-0.438-1.669-0.958-2.475-1.489c-0.806-0.53-1.576-1.124-2.334-1.738
							c-0.75-0.629-1.481-1.291-2.148-2.067L397.074,72.936z" />
				</g>
				<g>
					<path fill="#39B54A" d="M393.079,75.607c0.925,0.191,1.793,0.473,2.633,0.801c0.429,0.149,0.828,0.347,1.243,0.519
							c0.412,0.177,0.812,0.372,1.209,0.574c0.793,0.402,1.569,0.832,2.319,1.304c0.749,0.474,1.431,1.054,2.095,1.665
							c0.66,0.617,1.308,1.251,2,1.818c0.69,0.567,1.438,1.042,2.213,1.475c0.783,0.42,1.589,0.801,2.428,1.131
							c0.829,0.345,1.676,0.661,2.484,1.04l0.982,0.46l-0.586,1.229c-0.585,1.227-1.224,2.42-1.866,3.612
							c-0.626,1.201-1.347,2.344-2.132,3.447c-0.386,0.556-0.812,1.087-1.22,1.629c-0.417,0.536-0.855,1.06-1.298,1.58
							c-0.897,1.033-1.841,2.039-2.929,2.955l-0.255-0.158c0.333-1.382,0.811-2.675,1.334-3.94c0.268-0.629,0.541-1.254,0.833-1.867
							c0.302-0.607,0.587-1.226,0.912-1.819c0.635-1.196,1.336-2.351,2.131-3.448c0.779-1.106,1.562-2.211,2.398-3.283l0.396,1.69
							c-0.696-0.558-1.354-1.179-2.029-1.77c-0.666-0.607-1.364-1.163-2.085-1.68c-0.73-0.504-1.486-0.966-2.3-1.335
							c-0.813-0.371-1.669-0.673-2.514-0.993c-0.841-0.326-1.663-0.683-2.418-1.146c-0.755-0.465-1.483-0.971-2.194-1.506
							c-0.356-0.266-0.708-0.54-1.048-0.832c-0.337-0.297-0.69-0.568-1.014-0.887c-0.664-0.609-1.301-1.263-1.88-2.009L393.079,75.607
							z" />
				</g>
				<g>
					<path fill="#39B54A" d="M389.076,79.764c0.817,0.129,1.586,0.343,2.332,0.595c0.752,0.241,1.473,0.537,2.187,0.844
							c1.407,0.65,2.804,1.319,4.02,2.296c1.215,0.979,2.31,2.167,3.619,2.984c1.297,0.839,2.737,1.433,4.22,1.953l0.661,0.232
							l-0.325,0.98c-0.235,0.709-0.474,1.416-0.75,2.103c-0.287,0.681-0.591,1.353-0.899,2.021c-0.618,1.337-1.225,2.681-1.993,3.936
							c-0.765,1.257-1.593,2.479-2.5,3.657c-0.455,0.588-0.921,1.17-1.422,1.734c-0.497,0.565-1.014,1.12-1.593,1.64l-0.263-0.146
							c0.129-0.768,0.32-1.501,0.532-2.223c0.208-0.725,0.449-1.43,0.703-2.129c0.51-1.397,1.099-2.75,1.75-4.069
							c0.648-1.321,1.458-2.553,2.257-3.791c0.4-0.619,0.804-1.235,1.226-1.842c0.432-0.601,0.901-1.181,1.375-1.76l0.335,1.211
							c-1.184-1.033-2.411-1.992-3.78-2.708c-1.357-0.735-2.93-1.102-4.381-1.676c-1.45-0.576-2.721-1.461-3.98-2.364
							c-0.62-0.469-1.232-0.95-1.813-1.486c-0.587-0.524-1.152-1.088-1.668-1.735L389.076,79.764z" />
				</g>
				<g>
					<path fill="#39B54A" d="M386.072,84.249c0.708,0.045,1.37,0.175,2.009,0.346c0.648,0.155,1.265,0.367,1.87,0.601
							c1.197,0.491,2.385,0.997,3.396,1.828c1.012,0.829,1.911,1.862,3.021,2.513c1.095,0.676,2.33,1.098,3.601,1.453l0.653,0.183
							l-0.282,1.047c-0.162,0.6-0.329,1.196-0.538,1.771c-0.214,0.572-0.448,1.133-0.683,1.695c-0.472,1.121-0.937,2.246-1.564,3.285
							c-0.622,1.042-1.308,2.051-2.071,3.019c-0.773,0.962-1.582,1.907-2.587,2.748l-0.266-0.14c0.122-1.305,0.441-2.506,0.794-3.689
							c0.365-1.177,0.806-2.314,1.311-3.418c0.501-1.106,1.163-2.127,1.818-3.151c0.329-0.512,0.658-1.023,1.008-1.524
							c0.354-0.498,0.75-0.975,1.153-1.448l0.371,1.23c-0.984-0.88-2.003-1.694-3.163-2.253c-1.146-0.584-2.5-0.787-3.744-1.193
							c-1.244-0.404-2.31-1.134-3.368-1.878c-0.522-0.384-1.033-0.79-1.512-1.252c-0.488-0.447-0.954-0.935-1.373-1.507
							L386.072,84.249z" />
				</g>
				<g>
					<path fill="#39B54A" d="M384.056,89.312c1.107-0.172,2.104-0.068,3.067,0.122c0.951,0.218,1.892,0.463,2.711,1.013
							c0.816,0.557,1.533,1.364,2.404,1.783c0.862,0.443,1.825,0.63,2.83,0.715l0.699,0.059l-0.261,1.147
							c-0.108,0.477-0.204,0.961-0.346,1.419c-0.135,0.463-0.325,0.896-0.496,1.338c-0.35,0.882-0.695,1.767-1.196,2.567
							c-0.499,0.801-1.063,1.567-1.717,2.285c-0.324,0.361-0.662,0.713-1.035,1.048c-0.368,0.336-0.758,0.662-1.21,0.953l-0.264-0.143
							c-0.004-0.538,0.054-1.042,0.134-1.534c0.075-0.495,0.185-0.971,0.309-1.439c0.242-0.94,0.574-1.832,0.971-2.688
							c0.395-0.858,0.946-1.631,1.492-2.407c0.276-0.386,0.534-0.782,0.848-1.149c0.306-0.37,0.659-0.715,0.998-1.067l0.438,1.206
							c-0.787-0.63-1.615-1.158-2.545-1.43c-0.92-0.296-1.995-0.206-2.971-0.363c-0.973-0.166-1.824-0.635-2.664-1.132
							c-0.829-0.526-1.623-1.137-2.308-2.024L384.056,89.312z" />
				</g>
				<g>
					<path fill="#39B54A" d="M382.768,95.479c0.735-0.49,1.436-0.682,2.125-0.783c0.686-0.07,1.369-0.111,2.013,0.177
							c0.643,0.299,1.255,0.854,1.916,0.994c0.659,0.165,1.349,0.056,2.052-0.16l1.157-0.355l-0.599,1.785
							c-0.112,0.334-0.217,0.674-0.359,0.983c-0.138,0.312-0.322,0.584-0.49,0.872c-0.341,0.568-0.679,1.139-1.152,1.594
							c-0.471,0.457-0.996,0.867-1.599,1.211c-0.299,0.173-0.61,0.337-0.951,0.475c-0.336,0.142-0.692,0.267-1.101,0.347l-0.228-0.195
							c0.016-0.417,0.086-0.787,0.175-1.141c0.084-0.358,0.199-0.69,0.326-1.012c0.248-0.648,0.573-1.229,0.954-1.764
							c0.378-0.537,0.891-0.957,1.401-1.381c0.258-0.209,0.499-0.433,0.787-0.616c0.283-0.187,0.604-0.343,0.917-0.504l0.559,1.43
							c-0.634-0.373-1.28-0.639-1.959-0.631c-0.676-0.017-1.4,0.38-2.095,0.522c-0.693,0.131-1.348-0.066-1.999-0.294
							c-0.647-0.258-1.284-0.608-1.886-1.255L382.768,95.479z" />
				</g>
				<g>
					<path fill="#39B54A" d="M379.973,100.618c0.365-0.651,0.784-1.008,1.221-1.273c0.442-0.234,0.89-0.441,1.4-0.314
							c0.512,0.135,1.069,0.517,1.55,0.485c0.242-0.005,0.472-0.075,0.693-0.192c0.221-0.119,0.432-0.293,0.641-0.476l1.229-1.071
							l-0.381,2.49c-0.041,0.264-0.079,0.529-0.151,0.767c-0.071,0.239-0.187,0.441-0.285,0.658c-0.203,0.428-0.405,0.857-0.744,1.176
							c-0.336,0.321-0.729,0.596-1.2,0.808c-0.234,0.107-0.479,0.206-0.754,0.279c-0.128,0.045-0.287,0.065-0.435,0.094
							c-0.147,0.029-0.297,0.056-0.473,0.062l-0.233-0.188c-0.031-0.173-0.036-0.325-0.039-0.475c-0.003-0.151-0.017-0.31,0-0.445
							c0.014-0.285,0.058-0.545,0.114-0.796c0.108-0.505,0.294-0.947,0.537-1.343c0.239-0.399,0.616-0.687,0.992-0.976
							c0.191-0.142,0.365-0.298,0.583-0.418c0.217-0.121,0.468-0.214,0.717-0.309l0.847,1.419c-0.261-0.095-0.52-0.181-0.769-0.212
							c-0.249-0.029-0.489-0.012-0.716,0.07c-0.46,0.144-0.842,0.701-1.271,1.011c-0.43,0.303-0.922,0.271-1.419,0.212
							c-0.503-0.09-1.023-0.271-1.599-0.748L379.973,100.618z" />
				</g>
				</g>
				<g id="leaf3">
				<g>
					<path id="stem3" className="stem" fill="#39B54A" d="M490.85,70.706c0,0,0.283-0.344,0.856-0.969c0.571-0.632,1.445-1.5,2.599-2.575
							c1.161-1.062,2.6-2.327,4.335-3.661c0.854-0.688,1.78-1.396,2.791-2.093c1.011-0.698,2.041-1.484,3.175-2.21
							c1.141-0.719,2.326-1.467,3.556-2.242c0.605-0.401,1.25-0.763,1.91-1.117c0.657-0.358,1.316-0.738,2.003-1.092
							c2.697-1.5,5.724-2.727,8.806-4.058c1.526-0.697,3.145-1.26,4.722-1.952c1.601-0.647,3.172-1.402,4.783-2.1
							c1.621-0.677,3.241-1.392,4.862-2.127c1.618-0.742,3.225-1.537,4.876-2.223c1.643-0.712,3.285-1.425,4.922-2.135
							c0.823-0.346,1.646-0.69,2.464-1.034c0.828-0.319,1.666-0.602,2.495-0.902c1.663-0.583,3.314-1.161,4.945-1.732
							c1.632-0.571,3.241-1.146,4.857-1.598c1.605-0.491,3.197-0.93,4.754-1.367c1.554-0.448,3.081-0.854,4.573-1.233
							c2.998-0.693,5.858-1.242,8.489-1.773c1.318-0.249,2.583-0.487,3.784-0.714c1.203-0.221,2.361-0.334,3.44-0.491
							c2.163-0.291,4.053-0.517,5.609-0.683c1.557-0.161,2.779-0.288,3.613-0.374c0.836-0.07,1.28-0.108,1.28-0.108l0.052,0.296
							c0,0-0.431,0.13-1.233,0.374c-0.807,0.228-1.988,0.562-3.493,0.988c-1.504,0.421-3.334,0.907-5.435,1.435
							c-1.05,0.266-2.166,0.55-3.344,0.849c-1.169,0.33-2.398,0.676-3.682,1.037c-2.567,0.701-5.354,1.438-8.304,2.192
							c-1.458,0.441-2.96,0.879-4.504,1.292c-1.541,0.425-3.112,0.866-4.726,1.271c-1.617,0.386-3.225,0.904-4.864,1.384
							c-1.642,0.485-3.301,0.975-4.975,1.47c-1.684,0.469-3.362,0.991-5.044,1.537c-1.692,0.517-3.394,1.035-5.094,1.554
							c-1.715,0.487-3.376,1.115-5.039,1.724c-1.657,0.625-3.302,1.263-4.921,1.929c-3.188,1.439-6.285,2.96-9.162,4.644
							c-2.854,1.708-5.613,3.358-8.229,4.923c-2.644,1.503-5.109,2.965-7.396,4.312c-1.153,0.655-2.216,1.348-3.251,1.973
							c-1.028,0.636-2.025,1.208-2.92,1.806c-0.912,0.577-1.766,1.117-2.555,1.616c-0.776,0.518-1.489,0.995-2.146,1.412
							c-1.284,0.867-2.313,1.542-2.984,2.025c-0.691,0.467-1.052,0.739-1.052,0.739L490.85,70.706z" />
				</g>
				<g>
					<path fill="none" d="M514.821,55.366" />
				</g>
				<g>
					<g>
					<path fill="#39B54A" d="M509.384,39.53c-0.392,1.345-0.867,2.645-1.387,3.92c-0.514,1.278-1.06,2.539-1.653,3.773
								c-0.579,1.242-1.188,2.468-1.831,3.676c-0.635,1.212-1.331,2.39-2.104,3.525c-0.758,1.145-1.59,2.249-2.378,3.376
								c-0.788,1.128-1.539,2.277-2.212,3.467c-0.67,1.193-1.291,2.413-1.87,3.654c-0.572,1.247-1.1,2.518-1.634,3.785l-0.863-1.421
								c1.253-0.161,2.505-0.312,3.759-0.408l3.763-0.261c2.51-0.158,5.019-0.314,7.53-0.293c2.512,0.012,5.026,0.101,7.542,0.265
								c2.518,0.182,5.033,0.399,7.556,0.836l0.007,0.3c-2.5,0.549-5.005,0.878-7.511,1.171c-2.506,0.275-5.014,0.477-7.522,0.601
								c-2.51,0.133-5.022,0.089-7.535,0.042l-3.771-0.093c-1.257-0.041-2.515-0.136-3.772-0.241l-1.741-0.145l0.878-1.276
								c0.779-1.133,1.568-2.26,2.312-3.413c0.734-1.157,1.43-2.336,2.074-3.542c0.642-1.209,1.205-2.459,1.733-3.73
								c0.527-1.271,1.01-2.566,1.567-3.821c0.541-1.263,1.159-2.484,1.839-3.671c0.673-1.191,1.379-2.364,2.114-3.521
								c0.721-1.165,1.49-2.303,2.291-3.424c0.797-1.124,1.637-2.224,2.56-3.277L509.384,39.53z" />
					</g>
					<g>
					<path fill="#39B54A" d="M514.442,35.884c-0.396,1.356-0.873,2.667-1.395,3.955c-0.516,1.291-1.063,2.563-1.66,3.811
								c-0.582,1.254-1.189,2.495-1.836,3.715c-0.637,1.225-1.33,2.418-2.104,3.568c-0.761,1.157-1.589,2.278-2.383,3.417
								c-0.787,1.143-1.551,2.299-2.229,3.502c-0.344,0.598-0.654,1.215-0.977,1.825c-0.324,0.609-0.606,1.241-0.911,1.861
								c-0.574,1.259-1.109,2.539-1.646,3.819l-0.835-1.394c1.279-0.145,2.559-0.28,3.837-0.363c1.28-0.086,2.561-0.144,3.839-0.208
								c2.561-0.123,5.119-0.247,7.681-0.191c1.28,0.017,2.561,0.062,3.841,0.132c1.28,0.054,2.562,0.144,3.843,0.241
								c2.562,0.218,5.123,0.473,7.688,0.949l0.002,0.3c-2.557,0.515-5.114,0.809-7.672,1.065c-1.279,0.117-2.56,0.226-3.839,0.3
								c-1.279,0.088-2.56,0.153-3.839,0.19c-2.561,0.094-5.12,0.009-7.682-0.074c-1.28-0.046-2.561-0.083-3.842-0.151
								c-1.28-0.063-2.562-0.179-3.842-0.304l-1.679-0.164l0.843-1.23c0.783-1.145,1.567-2.29,2.312-3.455
								c0.355-0.592,0.733-1.172,1.068-1.775c0.338-0.602,0.687-1.197,1.002-1.812c0.643-1.222,1.199-2.49,1.73-3.772
								c0.525-1.286,1.018-2.589,1.575-3.856c0.545-1.274,1.171-2.505,1.854-3.705c0.674-1.205,1.386-2.389,2.122-3.559
								c0.725-1.177,1.495-2.329,2.299-3.463c0.799-1.137,1.64-2.25,2.563-3.318L514.442,35.884z" />
					</g>
					<g>
					<path fill="#39B54A" d="M518.727,32.792c-0.282,1.299-0.651,2.556-1.068,3.791c-0.411,1.237-0.856,2.458-1.351,3.656
								c-0.476,1.207-0.993,2.393-1.533,3.568c-0.538,1.176-1.15,2.317-1.835,3.424c-0.67,1.114-1.412,2.192-2.102,3.296
								c-0.7,1.099-1.331,2.231-1.903,3.391c-0.56,1.166-1.084,2.349-1.544,3.563c-0.47,1.209-0.88,2.447-1.315,3.672l-0.898-1.434
								c2.315-0.269,4.63-0.347,6.944-0.442c2.313-0.088,4.629-0.167,6.943-0.077c2.312,0.081,4.627,0.243,6.939,0.467
								c2.313,0.248,4.626,0.529,6.938,1.026l-0.003,0.3c-2.315,0.477-4.632,0.738-6.945,0.965c-2.315,0.204-4.631,0.345-6.945,0.406
								c-2.313,0.069-4.628-0.03-6.94-0.139c-2.314-0.116-4.628-0.214-6.94-0.503l-1.654-0.207l0.755-1.228
								c0.683-1.108,1.392-2.203,2.038-3.326c0.659-1.119,1.253-2.269,1.812-3.435c0.546-1.173,1.033-2.374,1.451-3.608
								c0.431-1.228,0.806-2.483,1.255-3.702c0.434-1.227,0.939-2.418,1.521-3.575c0.578-1.157,1.179-2.304,1.82-3.431
								c0.625-1.135,1.298-2.247,2.006-3.342c0.701-1.099,1.45-2.174,2.285-3.208L518.727,32.792z" />
					</g>
					<g>
					<path fill="#39B54A" d="M524.952,28.305c-0.276,1.335-0.638,2.632-1.056,3.901c-0.41,1.273-0.86,2.528-1.354,3.763
								c-0.47,1.246-1.008,2.459-1.553,3.67c-0.556,1.206-1.19,2.374-1.896,3.51c-1.418,2.268-2.92,4.497-3.961,6.94
								c-1.113,2.411-1.906,4.97-2.768,7.498l-0.957-1.594c2.212-0.106,4.416-0.049,6.622-0.038c2.205,0.022,4.407,0.084,6.604,0.29
								c1.1,0.097,2.195,0.218,3.292,0.352c1.098,0.116,2.19,0.292,3.286,0.442c2.188,0.352,4.375,0.734,6.551,1.318l-0.016,0.3
								c-2.226,0.352-4.44,0.502-6.653,0.622c-1.104,0.033-2.211,0.094-3.313,0.094c-1.104,0.018-2.208,0.022-3.312,0.003
								c-2.205-0.027-4.402-0.197-6.599-0.407c-2.195-0.221-4.394-0.396-6.581-0.734l-1.759-0.272l0.801-1.322
								c1.383-2.285,2.834-4.537,3.966-6.938c1.202-2.369,1.945-4.951,2.771-7.495c0.418-1.27,0.903-2.508,1.472-3.708
								c0.577-1.196,1.162-2.388,1.813-3.548c0.63-1.172,1.302-2.323,2.014-3.456c0.704-1.136,1.465-2.246,2.311-3.316L524.952,28.305
								z" />
					</g>
					<g>
					<path fill="#39B54A" d="M531.473,25.869c-0.256,1.291-0.607,2.536-1.01,3.758c-0.185,0.618-0.422,1.212-0.63,1.819
								c-0.214,0.604-0.447,1.199-0.688,1.791c-0.479,1.184-0.988,2.354-1.543,3.502c-0.555,1.148-1.224,2.242-1.924,3.321
								c-0.708,1.075-1.435,2.141-2.09,3.241c-0.661,1.097-1.209,2.249-1.727,3.415c-1.03,2.334-1.73,4.827-2.626,7.225l-1.039-1.751
								c1.956-0.045,3.907-0.023,5.859,0c1.953,0.006,3.9,0.121,5.844,0.314c0.972,0.088,1.94,0.223,2.911,0.338
								c0.971,0.125,1.938,0.275,2.907,0.43c1.937,0.325,3.868,0.704,5.792,1.252l-0.016,0.3c-1.973,0.338-3.936,0.506-5.896,0.621
								c-0.979,0.05-1.958,0.096-2.936,0.115c-0.979,0.01-1.956,0.04-2.933,0.023c-1.951-0.017-3.9-0.112-5.843-0.317
								c-1.942-0.187-3.887-0.375-5.827-0.631l-1.926-0.253l0.887-1.499c1.307-2.203,2.808-4.312,3.979-6.579
								c0.583-1.135,1.136-2.284,1.575-3.487c0.444-1.201,0.818-2.436,1.212-3.661c0.399-1.222,0.832-2.429,1.378-3.582
								c0.547-1.152,1.137-2.283,1.759-3.4c0.311-0.559,0.626-1.114,0.962-1.66c0.343-0.543,0.656-1.1,1.021-1.632
								c0.698-1.079,1.446-2.135,2.292-3.145L531.473,25.869z" />
					</g>
					<g>
					<path fill="#39B54A" d="M537.004,26.151c-0.09,1.06-0.271,2.081-0.508,3.08c-0.102,0.506-0.251,0.993-0.379,1.489
								c-0.127,0.496-0.281,0.98-0.437,1.465c-0.309,0.969-0.656,1.922-1.038,2.862c-0.379,0.94-0.871,1.834-1.4,2.713
								c-0.542,0.873-1.104,1.738-1.612,2.625c-0.499,0.891-0.925,1.812-1.276,2.764c-0.354,0.95-0.651,1.925-0.9,2.918
								c-0.258,0.99-0.482,1.995-0.756,2.979l-0.875-1.6c1.624,0.104,3.229,0.322,4.837,0.518c1.607,0.189,3.204,0.456,4.778,0.834
								c1.576,0.371,3.14,0.814,4.693,1.306c1.551,0.513,3.094,1.066,4.604,1.804l-0.054,0.295c-1.673,0.162-3.312,0.139-4.943,0.076
								c-1.628-0.084-3.247-0.217-4.854-0.421c-1.607-0.198-3.194-0.506-4.769-0.894c-1.575-0.38-3.153-0.738-4.712-1.21l-1.514-0.458
								l0.639-1.141c0.497-0.892,1.044-1.763,1.558-2.648c0.521-0.882,0.996-1.783,1.412-2.708c0.419-0.924,0.765-1.878,1.037-2.863
								c0.263-0.988,0.471-1.999,0.7-3.001c0.241-0.997,0.52-1.979,0.911-2.914c0.391-0.936,0.812-1.858,1.274-2.765
								c0.231-0.453,0.463-0.906,0.722-1.348c0.259-0.442,0.494-0.893,0.777-1.325c0.535-0.876,1.124-1.73,1.807-2.547L537.004,26.151
								z" />
					</g>
					<g>
					<path fill="#39B54A" d="M544.356,24.879c-0.112,0.983-0.312,1.927-0.563,2.844c-0.246,0.922-0.533,1.823-0.853,2.71
								c-0.32,0.887-0.683,1.754-1.064,2.612c-0.39,0.854-0.853,1.674-1.394,2.458c-0.527,0.791-1.114,1.552-1.629,2.349
								c-0.529,0.789-0.975,1.618-1.357,2.475c-0.78,1.708-1.26,3.556-1.788,5.382l-0.791-1.552c1.591,0.066,3.147,0.316,4.714,0.508
								c1.564,0.208,3.121,0.451,4.651,0.835c0.766,0.189,1.526,0.398,2.285,0.625c0.763,0.207,1.513,0.473,2.269,0.713
								c1.501,0.533,2.996,1.094,4.452,1.857l-0.058,0.295c-1.637,0.163-3.232,0.124-4.823,0.057c-0.791-0.06-1.586-0.094-2.37-0.188
								c-0.788-0.074-1.572-0.165-2.354-0.275c-1.562-0.216-3.098-0.573-4.626-0.965c-1.523-0.408-3.062-0.758-4.562-1.292
								l-1.479-0.526l0.688-1.026c1.058-1.579,2.164-3.135,2.969-4.832c0.409-0.845,0.758-1.719,1.021-2.632
								c0.278-0.906,0.483-1.846,0.75-2.758c0.252-0.918,0.582-1.801,0.983-2.649c0.411-0.844,0.843-1.679,1.314-2.495
								c0.475-0.815,0.979-1.616,1.526-2.396c0.539-0.785,1.136-1.543,1.814-2.262L544.356,24.879z" />
					</g>
				</g>
				<g>
					<path fill="#39B54A" d="M553.145,22.32c-0.136,0.975-0.378,1.893-0.659,2.79c-0.29,0.893-0.605,1.771-0.979,2.62
							c-0.374,0.848-0.756,1.692-1.216,2.494c-0.457,0.804-1.022,1.55-1.622,2.278c-0.592,0.732-1.209,1.451-1.714,2.229
							c-0.513,0.774-0.938,1.595-1.304,2.448c-0.758,1.69-1.174,3.563-1.912,5.264l-0.846-1.87c1.306,0.191,2.604,0.409,3.896,0.646
							c1.295,0.232,2.566,0.561,3.822,0.959c1.254,0.402,2.499,0.843,3.727,1.358c1.229,0.51,2.444,1.078,3.624,1.795l-0.069,0.292
							c-1.377,0.11-2.718,0.071-4.044-0.026c-1.327-0.092-2.639-0.259-3.939-0.463c-1.3-0.208-2.584-0.487-3.845-0.862
							c-1.263-0.371-2.521-0.76-3.771-1.176l-1.681-0.56l0.836-1.311c0.997-1.563,2.316-2.955,3.297-4.528
							c0.502-0.78,0.945-1.591,1.301-2.449c0.363-0.854,0.614-1.767,0.892-2.667c0.269-0.904,0.57-1.79,0.981-2.618
							c0.408-0.83,0.896-1.618,1.389-2.402c0.495-0.783,1.049-1.537,1.625-2.276c0.587-0.735,1.214-1.449,1.945-2.106L553.145,22.32z" />
				</g>
				<g>
					<path fill="#39B54A" d="M559.034,21.471c-0.126,0.901-0.343,1.752-0.608,2.577c-0.117,0.421-0.283,0.814-0.424,1.223
							c-0.144,0.406-0.311,0.8-0.479,1.192c-0.34,0.784-0.711,1.55-1.12,2.295c-0.407,0.747-0.932,1.43-1.48,2.098
							c-0.561,0.663-1.14,1.314-1.657,2.001c-0.516,0.687-0.943,1.422-1.317,2.187c-0.763,1.521-1.199,3.222-1.812,4.827l-0.627-1.705
							c0.65,0.15,1.287,0.337,1.923,0.526l1.903,0.577c1.272,0.374,2.508,0.847,3.713,1.399c0.605,0.269,1.195,0.577,1.79,0.874
							c0.594,0.298,1.175,0.631,1.758,0.959c1.157,0.679,2.296,1.404,3.373,2.294l-0.106,0.28c-1.396-0.05-2.729-0.267-4.044-0.528
							c-0.654-0.142-1.311-0.28-1.951-0.452c-0.643-0.174-1.288-0.336-1.919-0.537c-1.269-0.389-2.505-0.856-3.704-1.423l-1.807-0.834
							c-0.601-0.282-1.199-0.565-1.784-0.886l-1.349-0.737l0.721-0.968c1.024-1.377,2.226-2.659,3.1-4.12
							c0.444-0.726,0.835-1.482,1.138-2.286c0.302-0.804,0.541-1.643,0.799-2.472c0.27-0.823,0.564-1.631,0.977-2.375
							c0.409-0.746,0.855-1.47,1.336-2.177c0.24-0.353,0.481-0.706,0.748-1.044c0.269-0.338,0.512-0.689,0.804-1.014
							c0.553-0.667,1.154-1.307,1.849-1.896L559.034,21.471z" />
				</g>
				<g>
					<path fill="#39B54A" d="M566.511,18.724c-0.116,0.951-0.364,1.827-0.636,2.69c-0.281,0.857-0.619,1.684-0.992,2.489
							c-0.382,0.801-0.77,1.599-1.275,2.329c-0.496,0.737-1.125,1.398-1.729,2.074c-0.603,0.676-1.181,1.367-1.623,2.134
							c-0.452,0.761-0.835,1.562-1.143,2.404c-0.312,0.84-0.583,1.704-0.879,2.553c-0.304,0.846-0.678,1.651-1.052,2.457l-0.654-1.995
							c0.996,0.358,1.994,0.712,2.967,1.126c0.969,0.419,1.904,0.91,2.822,1.445c0.455,0.273,0.921,0.526,1.364,0.825
							c0.451,0.286,0.898,0.577,1.335,0.894c0.878,0.623,1.739,1.281,2.544,2.066l-0.123,0.273c-1.12-0.082-2.186-0.288-3.232-0.532
							c-0.526-0.117-1.042-0.258-1.554-0.405c-0.52-0.135-1.018-0.314-1.523-0.474c-1.009-0.331-1.998-0.706-2.954-1.153
							c-0.955-0.452-1.882-0.963-2.812-1.471l-1.461-0.798l0.806-1.197c0.496-0.737,0.991-1.474,1.558-2.17
							c0.574-0.692,1.173-1.372,1.73-2.073c0.562-0.699,1.049-1.441,1.466-2.222c0.428-0.775,0.72-1.627,0.986-2.493
							s0.507-1.747,0.881-2.552c0.362-0.812,0.844-1.557,1.332-2.298c0.496-0.736,1.027-1.452,1.617-2.137
							c0.599-0.678,1.22-1.344,1.973-1.936L566.511,18.724z" />
				</g>
				<g>
					<path fill="#39B54A" d="M570.85,18.172c-0.066,0.876-0.244,1.695-0.461,2.495c-0.213,0.801-0.482,1.572-0.787,2.327
							c-0.312,0.75-0.631,1.497-1.029,2.201c-0.398,0.706-0.906,1.355-1.446,1.987c-0.534,0.636-1.081,1.265-1.521,1.949
							c-0.445,0.682-0.795,1.413-1.102,2.166c-0.604,1.51-0.898,3.18-1.518,4.683l-0.78-1.854c1.112,0.228,2.218,0.477,3.317,0.745
							s2.171,0.629,3.221,1.058c1.049,0.432,2.089,0.896,3.104,1.439c1.019,0.534,2.02,1.124,2.975,1.859l-0.088,0.287
							c-1.204,0.07-2.363-0.005-3.506-0.136c-1.145-0.123-2.265-0.324-3.375-0.557c-1.108-0.236-2.197-0.541-3.258-0.938
							c-1.06-0.397-2.113-0.813-3.161-1.251l-1.54-0.643l0.761-1.211c0.862-1.376,2.051-2.586,2.931-3.955
							c0.436-0.688,0.827-1.396,1.123-2.154c0.302-0.755,0.496-1.566,0.703-2.37c0.201-0.807,0.435-1.598,0.777-2.332
							c0.342-0.735,0.764-1.428,1.192-2.118c0.438-0.685,0.909-1.353,1.438-1.992c0.524-0.64,1.089-1.26,1.763-1.824L570.85,18.172z" />
				</g>
				<g>
					<path fill="#39B54A" d="M575.866,18.579c-0.069,0.77-0.222,1.495-0.423,2.191c-0.188,0.706-0.432,1.379-0.695,2.042
							c-0.556,1.311-1.139,2.607-2.101,3.694c-0.46,0.556-0.984,1.076-1.442,1.632c-0.472,0.549-0.875,1.135-1.208,1.761
							c-0.691,1.236-1.075,2.641-1.506,4.021l-0.564-1.513c1.23,0.182,2.394,0.547,3.573,0.864c0.583,0.178,1.175,0.332,1.75,0.531
							c0.578,0.19,1.143,0.419,1.702,0.659c0.559,0.246,1.107,0.512,1.651,0.796c0.551,0.266,1.082,0.584,1.619,0.884
							c1.061,0.646,2.108,1.317,3.084,2.188l-0.104,0.281c-1.309,0.027-2.542-0.145-3.766-0.344c-0.604-0.122-1.215-0.226-1.807-0.381
							c-0.598-0.138-1.188-0.293-1.771-0.47c-0.582-0.182-1.159-0.375-1.724-0.606c-0.565-0.223-1.116-0.491-1.675-0.735
							c-1.103-0.527-2.223-1.005-3.275-1.667l-1.205-0.758l0.641-0.756c0.933-1.104,1.912-2.182,2.583-3.43
							c0.349-0.616,0.627-1.271,0.836-1.964c0.224-0.686,0.381-1.407,0.603-2.093c0.4-1.396,1.182-2.584,1.988-3.756
							c0.417-0.579,0.854-1.147,1.35-1.683c0.479-0.545,1.01-1.062,1.621-1.534L575.866,18.579z" />
				</g>
				<g>
					<path fill="#39B54A" d="M580.256,19.753c-0.027,0.669-0.135,1.291-0.296,1.883c-0.146,0.601-0.346,1.168-0.569,1.724
							c-0.225,0.554-0.486,1.086-0.77,1.607c-0.292,0.516-0.647,0.994-1.082,1.427c-0.42,0.441-0.9,0.848-1.311,1.295
							c-0.426,0.438-0.771,0.922-1.058,1.441c-0.598,1.023-0.879,2.229-1.228,3.396l-0.547-1.568c1.021,0.138,1.97,0.458,2.94,0.721
							c0.965,0.28,1.918,0.589,2.817,1.034c0.449,0.22,0.895,0.458,1.331,0.714c0.442,0.237,0.865,0.529,1.297,0.799
							c0.844,0.587,1.677,1.201,2.434,2.008l-0.108,0.279c-1.104,0.074-2.133-0.045-3.149-0.19c-0.5-0.097-1.008-0.171-1.494-0.3
							c-0.494-0.111-0.98-0.239-1.461-0.385c-0.961-0.288-1.869-0.711-2.766-1.164c-0.891-0.47-1.803-0.883-2.644-1.478l-1.218-0.862
							l0.672-0.707c0.84-0.883,1.747-1.726,2.337-2.753c0.31-0.506,0.557-1.047,0.726-1.634c0.185-0.578,0.299-1.196,0.474-1.78
							c0.159-0.592,0.396-1.139,0.7-1.648c0.311-0.504,0.642-0.997,1.012-1.468c0.371-0.47,0.763-0.927,1.213-1.351
							c0.435-0.433,0.92-0.836,1.486-1.192L580.256,19.753z" />
				</g>
				<g>
					<path fill="#39B54A" d="M584.128,21.871c-0.017,0.548-0.108,1.04-0.248,1.498c-0.124,0.469-0.302,0.898-0.498,1.314
							c-0.417,0.815-0.857,1.612-1.648,2.154c-0.375,0.286-0.811,0.527-1.187,0.813c-0.388,0.276-0.714,0.598-0.974,0.967
							c-0.544,0.722-0.807,1.65-1.107,2.549l-0.479-1.618c0.82,0.034,1.57,0.251,2.339,0.425c0.767,0.177,1.514,0.404,2.215,0.751
							c0.704,0.341,1.376,0.767,2.029,1.242c0.645,0.499,1.277,1.024,1.837,1.749l-0.105,0.28c-0.897,0.172-1.722,0.145-2.535,0.091
							c-0.804-0.078-1.589-0.204-2.342-0.416c-0.756-0.206-1.466-0.53-2.156-0.906c-0.689-0.379-1.396-0.713-2.032-1.232l-1.273-1.038
							l0.793-0.58c0.766-0.56,1.569-1.092,2.091-1.83c0.273-0.36,0.48-0.769,0.627-1.222c0.156-0.445,0.256-0.933,0.413-1.377
							c0.275-0.917,0.9-1.581,1.551-2.226c0.337-0.313,0.69-0.614,1.101-0.875c0.395-0.271,0.835-0.509,1.352-0.691L584.128,21.871z" />
				</g>
				<g>
					<path fill="#39B54A" d="M587.91,25.124c-0.038,0.444-0.141,0.817-0.277,1.148c-0.126,0.345-0.296,0.639-0.479,0.918
							c-0.391,0.532-0.795,1.046-1.482,1.239c-0.328,0.115-0.706,0.172-1.039,0.281c-0.345,0.096-0.636,0.252-0.88,0.463
							c-0.497,0.41-0.771,1.073-1.064,1.716l-0.543-1.807c0.596-0.19,1.163-0.185,1.736-0.214c0.572-0.029,1.141-0.016,1.688,0.138
							c0.549,0.144,1.086,0.376,1.614,0.666c0.264,0.157,0.525,0.316,0.782,0.516c0.13,0.087,0.254,0.219,0.382,0.326
							c0.127,0.109,0.253,0.225,0.376,0.369l-0.041,0.297c-0.155,0.105-0.31,0.183-0.461,0.253c-0.151,0.069-0.308,0.162-0.456,0.21
							c-0.302,0.123-0.597,0.206-0.893,0.285c-0.588,0.137-1.167,0.215-1.735,0.206c-0.567,0.001-1.118-0.14-1.662-0.322
							c-0.544-0.183-1.092-0.332-1.613-0.675l-2.048-1.347l1.506-0.46c0.675-0.207,1.367-0.394,1.839-0.833
							c0.239-0.215,0.434-0.483,0.573-0.812c0.15-0.316,0.257-0.684,0.413-0.994c0.28-0.657,0.844-0.992,1.422-1.31
							c0.3-0.146,0.613-0.276,0.973-0.356c0.346-0.094,0.729-0.146,1.175-0.126L587.91,25.124z" />
				</g>
				<g>
					<path fill="#39B54A" d="M592.375,26.87c-0.062,0.379-0.167,0.675-0.298,0.919c-0.123,0.261-0.275,0.462-0.438,0.644
							c-0.341,0.335-0.693,0.646-1.242,0.573c-0.265-0.015-0.562-0.096-0.826-0.113c-0.271-0.03-0.51,0.005-0.71,0.115
							c-0.413,0.193-0.669,0.694-0.943,1.159l-0.304-1.975c0.433-0.193,0.834-0.192,1.241-0.234c0.406-0.037,0.807-0.032,1.184,0.112
							c0.378,0.134,0.741,0.356,1.095,0.632c0.175,0.151,0.351,0.305,0.519,0.497c0.086,0.084,0.164,0.211,0.247,0.315
							c0.082,0.105,0.163,0.218,0.239,0.357l-0.051,0.296c-0.118,0.106-0.23,0.187-0.344,0.258c-0.112,0.071-0.229,0.166-0.337,0.217
							c-0.223,0.126-0.438,0.214-0.651,0.299c-0.426,0.145-0.842,0.235-1.242,0.237c-0.402,0.012-0.781-0.114-1.153-0.283
							c-0.372-0.173-0.75-0.304-1.098-0.629l-2.295-2.157l1.991,0.182c0.537,0.049,1.093,0.134,1.491-0.088
							c0.206-0.099,0.374-0.271,0.508-0.509c0.143-0.225,0.25-0.514,0.393-0.736c0.263-0.488,0.721-0.593,1.191-0.673
							c0.242-0.026,0.495-0.032,0.778,0.021c0.274,0.037,0.576,0.124,0.92,0.296L592.375,26.87z" />
				</g>
				</g>
				<g id="leaf2">
				<g>
					<path id="stem2" className="stem" fill="#39B54A" d="M492.444,73.199c0,0-0.572-1.569-1.763-4.208c-0.585-1.321-1.357-2.894-2.27-4.673
							c-0.919-1.776-2.033-3.734-3.319-5.817c-1.287-2.084-2.808-4.251-4.464-6.509c-1.707-2.212-3.441-4.634-5.562-6.878
							c-0.5-0.593-1.066-1.125-1.63-1.671c-0.563-0.545-1.127-1.104-1.737-1.614c-1.229-1.01-2.481-2.035-3.863-2.895
							c-2.677-1.856-5.717-3.219-8.769-4.426c-3.114-1.082-6.256-1.996-9.392-2.708c-1.573-0.332-3.114-0.732-4.683-0.922
							c-1.56-0.227-3.084-0.523-4.616-0.617c-0.762-0.075-1.519-0.13-2.265-0.219c-0.373-0.041-0.745-0.081-1.114-0.121
							c-0.373-0.017-0.743-0.034-1.111-0.051c-1.474-0.05-2.913-0.157-4.315-0.236c-2.813,0.014-5.48-0.131-7.946,0.021
							c-1.236,0.031-2.42,0.061-3.546,0.088c-1.126,0.077-2.195,0.15-3.199,0.219c-1.009,0.058-1.953,0.113-2.826,0.163
							c-0.872,0.082-1.672,0.157-2.395,0.225c-1.454,0.125-2.596,0.222-3.375,0.289c-0.784,0.052-1.203,0.079-1.203,0.079
							l-0.061-0.294c0,0,0.401-0.12,1.153-0.345c0.757-0.21,1.869-0.518,3.283-0.909c0.709-0.197,1.495-0.41,2.358-0.611
							c0.87-0.17,1.81-0.354,2.814-0.55c1.009-0.186,2.083-0.384,3.215-0.593c1.144-0.137,2.348-0.282,3.604-0.432
							c2.512-0.336,5.263-0.393,8.172-0.478c2.911,0.046,5.989,0.177,9.142,0.66c1.583,0.166,3.169,0.524,4.774,0.852
							c1.613,0.286,3.208,0.768,4.816,1.218c1.624,0.397,3.182,1.079,4.767,1.654c1.573,0.615,3.078,1.418,4.61,2.116
							c2.967,1.635,5.895,3.283,8.611,5.167c2.761,1.815,5.358,3.781,7.819,5.781c2.481,1.983,4.663,4.183,6.542,6.424
							c1.84,2.276,3.317,4.623,4.585,6.833c1.2,2.249,2.221,4.348,2.942,6.286c0.751,1.922,1.285,3.642,1.648,5.076
							c0.379,1.429,0.583,2.575,0.707,3.356c0.115,0.784,0.148,1.207,0.148,1.207L492.444,73.199z" />
				</g>
				<g>
					<path fill="#39B54A" d="M467.029,62.525c1.11,0.009,2.193,0.108,3.262,0.263c1.07,0.146,2.126,0.338,3.173,0.559
							c2.085,0.474,4.163,0.974,6.117,1.886c0.984,0.433,1.947,0.937,2.93,1.373c0.979,0.45,1.979,0.83,3.003,1.128
							c0.517,0.135,1.024,0.296,1.547,0.41c0.521,0.122,1.042,0.238,1.572,0.33c1.055,0.194,2.123,0.348,3.187,0.518l-1.202,0.464
							c0.732-1.719,1.633-3.336,2.496-4.977c0.445-0.811,0.875-1.632,1.339-2.433c0.458-0.804,0.953-1.586,1.457-2.362
							c0.509-0.773,1.038-1.535,1.583-2.286c0.529-0.761,1.104-1.494,1.666-2.236c1.16-1.46,2.346-2.905,3.714-4.24l0.256,0.155
							c-0.545,1.832-1.272,3.553-2.026,5.26c-0.396,0.842-0.777,1.691-1.205,2.514c-0.411,0.833-0.841,1.654-1.288,2.464
							c-0.452,0.808-0.915,1.609-1.413,2.388c-0.493,0.783-1.021,1.545-1.531,2.317c-1.052,1.526-2.065,3.075-3.246,4.523
							l-0.599,0.734l-0.604-0.271c-0.981-0.44-1.958-0.898-2.947-1.315c-0.492-0.214-0.992-0.403-1.494-0.586
							c-0.499-0.192-1.014-0.336-1.52-0.506c-1.021-0.312-2.063-0.543-3.13-0.705c-1.061-0.175-2.143-0.282-3.203-0.46
							c-2.134-0.31-4.146-1.031-6.148-1.78c-0.996-0.39-1.985-0.808-2.959-1.274c-0.978-0.457-1.938-0.968-2.871-1.57L467.029,62.525z
							" />
				</g>
				<g>
					<path fill="#39B54A" d="M465.781,56.638c1.068,0.049,2.106,0.186,3.125,0.38c1.021,0.186,2.025,0.416,3.02,0.677
							c0.993,0.264,1.973,0.569,2.942,0.896c0.967,0.336,1.911,0.74,2.823,1.232c0.918,0.475,1.812,1.016,2.733,1.482
							c0.916,0.481,1.859,0.886,2.831,1.21c0.969,0.331,1.956,0.612,2.963,0.835l3.039,0.625l-1.343,0.55
							c0.536-1.67,1.248-3.254,1.906-4.865c0.675-1.602,1.376-3.191,2.211-4.716c0.415-0.764,0.847-1.519,1.297-2.265
							c0.432-0.755,0.916-1.485,1.379-2.226c0.973-1.458,1.971-2.903,3.157-4.257l0.27,0.131c-0.332,1.769-0.851,3.447-1.396,5.113
							c-0.295,0.821-0.57,1.653-0.897,2.459c-0.309,0.815-0.635,1.622-0.979,2.419c-0.684,1.598-1.499,3.132-2.342,4.653
							c-0.858,1.513-1.665,3.052-2.646,4.505l-0.624,0.924l-0.719-0.374l-2.752-1.431c-0.923-0.462-1.866-0.866-2.827-1.22
							c-0.959-0.36-1.946-0.642-2.96-0.845c-1.009-0.219-2.044-0.363-3.058-0.573c-1.019-0.192-2.005-0.474-2.968-0.822
							c-0.959-0.359-1.911-0.739-2.849-1.16c-0.937-0.424-1.861-0.878-2.771-1.378c-0.912-0.491-1.804-1.039-2.666-1.675
							L465.781,56.638z" />
				</g>
				<g>
					<path fill="#39B54A" d="M466.042,52.465c0.961-0.038,1.896,0.014,2.815,0.12c0.922,0.096,1.83,0.242,2.729,0.413
							c1.79,0.378,3.573,0.776,5.236,1.585c0.838,0.38,1.654,0.836,2.49,1.226c0.83,0.406,1.685,0.735,2.558,0.996
							c0.875,0.258,1.766,0.458,2.673,0.605c0.905,0.152,1.826,0.254,2.739,0.378l-1.24,0.582c0.194-0.787,0.428-1.557,0.687-2.316
							l0.799-2.269c0.546-1.507,1.104-3.007,1.812-4.443c0.348-0.721,0.718-1.433,1.106-2.136c0.372-0.71,0.79-1.401,1.196-2.097
							c0.853-1.375,1.731-2.737,2.809-4.015l0.274,0.119c-0.188,1.66-0.575,3.235-0.99,4.797c-0.227,0.773-0.442,1.551-0.703,2.31
							c-0.245,0.766-0.508,1.523-0.793,2.271c-0.559,1.501-1.266,2.938-1.986,4.369l-1.102,2.137c-0.374,0.71-0.774,1.408-1.212,2.09
							l-0.575,0.893l-0.666-0.311c-0.835-0.391-1.665-0.802-2.508-1.165c-0.844-0.366-1.701-0.681-2.576-0.937
							c-0.875-0.254-1.771-0.439-2.69-0.547c-0.914-0.124-1.847-0.183-2.758-0.317c-1.836-0.219-3.551-0.849-5.261-1.5
							c-0.851-0.343-1.691-0.711-2.52-1.129c-0.83-0.409-1.645-0.87-2.433-1.423L466.042,52.465z" />
				</g>
				<g>
					<path fill="#39B54A" d="M463.002,49.309c1.635-0.425,3.234-0.505,4.828-0.537c1.588,0.023,3.175,0.058,4.721,0.481
							c1.547,0.412,3.071,1.05,4.643,1.234c1.565,0.227,3.166,0.147,4.77,0.016l-0.96,0.561c0.169-0.728,0.347-1.452,0.572-2.156
							c0.222-0.705,0.471-1.398,0.713-2.094c0.491-1.389,0.979-2.779,1.634-4.098c0.317-0.664,0.661-1.315,1.026-1.958
							c0.351-0.649,0.734-1.283,1.126-1.915c0.804-1.253,1.642-2.492,2.682-3.643l0.275,0.119c-0.131,1.546-0.465,3.003-0.833,4.446
							c-0.194,0.717-0.396,1.431-0.631,2.13c-0.221,0.706-0.462,1.402-0.731,2.086c-0.518,1.378-1.2,2.684-1.88,3.991
							c-0.343,0.652-0.68,1.307-1.044,1.951c-0.361,0.645-0.77,1.269-1.187,1.889l-0.474,0.706l-0.486-0.146
							c-1.542-0.461-3.09-0.871-4.669-0.974c-1.575-0.146-3.197,0.163-4.797,0.244c-1.6,0.093-3.16-0.202-4.718-0.509
							c-1.553-0.361-3.1-0.772-4.611-1.527L463.002,49.309z" />
				</g>
				<g>
					<path fill="#39B54A" d="M459,46.35c0.75-0.296,1.5-0.495,2.25-0.652c0.75-0.171,1.5-0.274,2.25-0.371
							c1.5-0.151,3-0.271,4.5-0.069c1.5,0.208,3,0.694,4.5,0.765c1.5,0.092,3-0.085,4.5-0.373l-0.805,0.579
							c0.11-0.74,0.201-1.487,0.348-2.216s0.327-1.446,0.51-2.162c0.368-1.432,0.721-2.869,1.243-4.249
							c0.26-0.69,0.535-1.376,0.847-2.049c0.299-0.677,0.617-1.348,0.961-2.011c0.343-0.663,0.699-1.32,1.095-1.966
							c0.39-0.647,0.804-1.286,1.285-1.902l0.283,0.096c0.013,0.782-0.042,1.541-0.122,2.292c-0.073,0.753-0.185,1.493-0.312,2.228
							c-0.125,0.736-0.275,1.463-0.445,2.184c-0.157,0.725-0.351,1.438-0.56,2.146c-0.415,1.417-1,2.775-1.57,4.14
							c-0.286,0.682-0.573,1.363-0.896,2.032c-0.323,0.669-0.7,1.32-1.059,1.978l-0.36,0.664L477,47.349c-1.5-0.287-3-0.465-4.5-0.373
							c-1.5,0.071-3,0.557-4.5,0.765c-1.5,0.203-3,0.082-4.5-0.069c-0.75-0.096-1.5-0.2-2.25-0.371c-0.75-0.158-1.5-0.356-2.25-0.652
							V46.35z" />
				</g>
				<g>
					<path fill="#39B54A" d="M455.488,43.361c0.676-0.365,1.362-0.63,2.053-0.868c0.69-0.244,1.386-0.427,2.083-0.608
							c1.398-0.319,2.801-0.598,4.232-0.632c1.433-0.017,2.898,0.259,4.335,0.269c1.435,0.001,2.841-0.252,4.23-0.643l-0.614,0.688
							l-0.417-2.321c-0.126-0.774-0.208-1.547-0.266-2.321c-0.11-1.547-0.256-3.094-0.233-4.642c0.011-0.773,0.058-1.547,0.128-2.321
							c0.08-0.773,0.15-1.547,0.273-2.321c0.236-1.547,0.528-3.094,1.058-4.642h0.301c0.529,1.547,0.821,3.094,1.058,4.642
							c0.124,0.773,0.194,1.547,0.274,2.321c0.069,0.773,0.116,1.547,0.127,2.321c0.022,1.547-0.123,3.094-0.233,4.642
							c-0.058,0.773-0.14,1.547-0.266,2.321l-0.417,2.321l-0.127,0.711l-0.487-0.023c-1.442-0.067-2.869,0.004-4.268,0.329
							c-1.396,0.333-2.763,0.933-4.162,1.24c-1.401,0.29-2.831,0.334-4.267,0.339c-0.719-0.019-1.438-0.04-2.166-0.122
							c-0.726-0.076-1.455-0.179-2.195-0.382L455.488,43.361z" />
				</g>
				<g>
					<path fill="#39B54A" d="M452.466,39.483c1.12-0.744,2.289-1.211,3.469-1.622c1.187-0.376,2.38-0.71,3.608-0.843
							c1.231-0.123,2.52,0.065,3.771,0.056c1.25-0.015,2.459-0.267,3.639-0.673l-0.491,0.708c-0.229-0.685-0.521-1.358-0.785-2.036
							c-0.282-0.675-0.501-1.361-0.701-2.052c-0.365-1.386-0.781-2.763-1.029-4.17c-0.131-0.703-0.206-1.415-0.276-2.129
							c-0.042-0.718-0.111-1.432-0.112-2.158c-0.021-0.722-0.007-1.451,0.036-2.185c0.038-0.733,0.116-1.473,0.26-2.226l0.296-0.054
							c0.398,0.654,0.732,1.32,1.024,1.993c0.298,0.672,0.567,1.35,0.801,2.034c0.254,0.68,0.44,1.373,0.653,2.06
							c0.186,0.692,0.365,1.386,0.489,2.09c0.262,1.405,0.355,2.84,0.5,4.267c0.056,0.716,0.093,1.436,0.063,2.167
							c-0.009,0.728-0.045,1.46-0.02,2.182l0.024,0.695L467.17,37.6c-1.248,0.032-2.468,0.219-3.645,0.642
							c-1.177,0.429-2.316,1.056-3.515,1.371c-1.198,0.306-2.433,0.41-3.676,0.472c-1.249,0.028-2.507,0-3.815-0.307L452.466,39.483z" />
				</g>
				<g>
					<path fill="#39B54A" d="M445.959,37.967c1.043-0.835,2.159-1.41,3.29-1.929c1.14-0.488,2.294-0.928,3.495-1.2
							c1.202-0.268,2.494-0.216,3.739-0.328c1.25-0.1,2.429-0.445,3.572-0.922l-0.338,0.815c-0.898-1.192-1.995-2.275-2.834-3.501
							c-0.429-0.608-0.839-1.226-1.254-1.842c-0.404-0.622-0.819-1.238-1.181-1.884c-0.729-1.288-1.32-2.655-1.854-4.053
							c-0.268-0.699-0.505-1.415-0.716-2.146c-0.215-0.728-0.392-1.478-0.511-2.26l0.262-0.147c0.607,0.508,1.156,1.049,1.666,1.611
							c0.516,0.559,1.003,1.134,1.461,1.726c0.918,1.182,1.779,2.396,2.501,3.689c0.364,0.645,0.674,1.319,0.996,1.987
							c0.312,0.675,0.626,1.347,0.923,2.029c0.612,1.355,0.967,2.853,1.52,4.242l0.286,0.72l-0.624,0.095
							c-1.226,0.187-2.413,0.505-3.531,1.07c-1.122,0.551-2.198,1.267-3.364,1.664c-1.167,0.391-2.381,0.616-3.608,0.792
							c-1.236,0.145-2.488,0.234-3.813,0.063L445.959,37.967z" />
				</g>
				<g>
					<path fill="#39B54A" d="M442.706,35.117c0.399-0.397,0.828-0.695,1.256-0.996c0.435-0.279,0.871-0.556,1.323-0.78
							c0.896-0.469,1.826-0.838,2.777-1.134c0.954-0.283,1.998-0.279,3.028-0.32c0.511-0.031,1.021-0.067,1.509-0.176
							c0.49-0.103,0.965-0.254,1.43-0.437l-0.271,0.705c-0.768-1.09-1.834-2.002-2.703-3.032c-0.419-0.523-0.812-1.063-1.208-1.601
							c-0.388-0.542-0.786-1.08-1.142-1.641c-0.354-0.562-0.677-1.144-0.961-1.749c-0.288-0.602-0.551-1.219-0.795-1.848
							c-0.243-0.629-0.461-1.272-0.647-1.936c-0.187-0.662-0.343-1.343-0.431-2.065l0.258-0.153c0.593,0.42,1.118,0.881,1.612,1.36
							c0.495,0.479,0.957,0.977,1.395,1.49c0.438,0.514,0.854,1.038,1.248,1.578c0.396,0.537,0.755,1.097,1.081,1.677
							c0.325,0.58,0.608,1.185,0.901,1.784c0.286,0.604,0.573,1.206,0.835,1.824c0.494,1.253,0.789,2.625,1.383,3.819l0.287,0.578
							l-0.559,0.127c-0.486,0.111-0.964,0.253-1.426,0.445c-0.464,0.185-0.906,0.443-1.346,0.706
							c-0.874,0.547-1.733,1.139-2.682,1.444c-0.952,0.292-1.928,0.511-2.934,0.63c-0.5,0.069-1.016,0.087-1.533,0.102
							c-0.522-0.006-1.045-0.011-1.599-0.114L442.706,35.117z" />
				</g>
				<g>
					<path fill="#39B54A" d="M435.427,33.408c0.855-0.796,1.785-1.346,2.733-1.835c0.478-0.234,0.959-0.456,1.451-0.643
							c0.49-0.189,0.983-0.371,1.496-0.495c1.017-0.264,2.131-0.21,3.205-0.286c1.08-0.058,2.089-0.351,3.056-0.78l-0.247,0.856
							c-0.975-0.883-2.178-1.568-3.141-2.462c-0.492-0.437-0.953-0.901-1.433-1.35c-0.461-0.463-0.938-0.914-1.371-1.403
							c-0.427-0.493-0.834-1.005-1.21-1.542c-0.195-0.263-0.366-0.546-0.551-0.818c-0.184-0.273-0.362-0.549-0.527-0.839
							c-0.343-0.566-0.662-1.153-0.956-1.762c-0.297-0.606-0.562-1.24-0.773-1.919l0.227-0.196c0.644,0.308,1.231,0.661,1.789,1.042
							c0.562,0.378,1.097,0.778,1.606,1.2c0.263,0.204,0.511,0.421,0.754,0.642c0.243,0.221,0.499,0.432,0.73,0.663
							c0.479,0.45,0.926,0.925,1.353,1.419c0.422,0.498,0.799,1.035,1.191,1.558c0.375,0.538,0.77,1.061,1.13,1.612
							c0.746,1.081,1.252,2.37,1.985,3.461l0.489,0.727l-0.736,0.129c-1.042,0.183-2.042,0.502-2.971,1.057
							c-0.934,0.537-1.829,1.203-2.82,1.551c-0.493,0.183-1.004,0.307-1.517,0.423c-0.513,0.12-1.036,0.205-1.562,0.276
							c-1.06,0.123-2.138,0.186-3.292,0.001L435.427,33.408z" />
				</g>
				<g>
					<path fill="#39B54A" d="M428.176,35.13c0.707-1.036,1.557-1.819,2.443-2.536c0.902-0.69,1.821-1.35,2.873-1.776
							c1.058-0.415,2.261-0.573,3.313-0.998c1.05-0.429,1.979-1.071,2.832-1.849l-0.148,1.161l-1.565-0.953
							c-0.511-0.329-0.989-0.692-1.455-1.067c-0.468-0.373-0.937-0.745-1.392-1.131c-0.464-0.377-0.905-0.778-1.323-1.203
							c-0.83-0.855-1.58-1.793-2.277-2.785c-0.352-0.493-0.682-1.007-0.989-1.546c-0.312-0.534-0.601-1.091-0.841-1.698l0.209-0.215
							c0.612,0.224,1.179,0.497,1.721,0.793c0.547,0.292,1.071,0.608,1.573,0.946c1.011,0.67,1.97,1.393,2.848,2.199
							c0.436,0.406,0.85,0.835,1.239,1.289c0.398,0.444,0.783,0.903,1.17,1.36c0.388,0.456,0.764,0.923,1.106,1.424l0.997,1.538
							l0.606,0.936l-0.756,0.226c-1.106,0.331-2.135,0.797-3.042,1.477c-0.905,0.685-1.66,1.634-2.561,2.329
							c-0.906,0.683-1.944,1.131-3.001,1.55c-1.071,0.392-2.18,0.717-3.432,0.79L428.176,35.13z" />
				</g>
				<g>
					<path fill="#39B54A" d="M424.135,33.412c0.421-0.863,0.98-1.499,1.571-2.083c0.604-0.56,1.24-1.072,1.959-1.444
							c0.72-0.371,1.617-0.451,2.427-0.674c0.819-0.208,1.506-0.633,2.122-1.176l-0.105,0.86c-0.826-0.564-1.821-0.962-2.614-1.559
							c-0.778-0.612-1.583-1.197-2.283-1.886c-0.688-0.7-1.286-1.491-1.81-2.354c-0.268-0.426-0.512-0.876-0.731-1.349
							c-0.225-0.469-0.418-0.968-0.562-1.515l0.213-0.211c0.545,0.151,1.043,0.35,1.509,0.58c0.47,0.226,0.917,0.474,1.341,0.746
							c0.857,0.533,1.643,1.139,2.334,1.835c0.682,0.708,1.257,1.52,1.86,2.304c0.588,0.8,0.974,1.799,1.53,2.631l0.435,0.651
							l-0.541,0.209c-0.766,0.296-1.459,0.71-2.021,1.341c-0.571,0.616-1.057,1.375-1.718,1.843c-0.662,0.467-1.409,0.794-2.185,1.073
							c-0.791,0.254-1.613,0.458-2.573,0.433L424.135,33.412z" />
				</g>
				<g>
					<path fill="#39B54A" d="M420.756,32.229c-0.065-0.338-0.049-0.611-0.036-0.888c0.031-0.262,0.064-0.523,0.139-0.751
							c0.133-0.469,0.348-0.874,0.62-1.235c0.281-0.354,0.785-0.532,1.274-0.724c0.238-0.101,0.471-0.205,0.65-0.352
							c0.184-0.144,0.322-0.321,0.438-0.518l0.008,0.59c-0.446-0.43-1.19-0.615-1.761-0.944c-0.289-0.162-0.513-0.376-0.766-0.567
							c-0.247-0.196-0.492-0.394-0.703-0.619c-0.427-0.447-0.71-1.013-0.929-1.632c-0.108-0.311-0.195-0.639-0.252-0.992
							s-0.077-0.736-0.055-1.154l0.231-0.191c0.415,0.057,0.788,0.149,1.124,0.272c0.335,0.123,0.641,0.27,0.926,0.435
							c0.566,0.332,1.07,0.716,1.428,1.22c0.183,0.25,0.33,0.527,0.477,0.807c0.141,0.284,0.308,0.545,0.413,0.859
							c0.215,0.622,0.257,1.388,0.595,1.908l0.236,0.366l-0.229,0.225c-0.163,0.16-0.302,0.337-0.396,0.55
							c-0.1,0.209-0.144,0.46-0.185,0.716c-0.067,0.52-0.12,1.053-0.395,1.411c-0.285,0.351-0.625,0.657-1.05,0.9
							c-0.202,0.127-0.448,0.222-0.695,0.316c-0.267,0.079-0.527,0.161-0.871,0.179L420.756,32.229z" />
				</g>
				<g>
					<path fill="#39B54A" d="M414.516,32.202c0.085-0.735,0.341-1.245,0.632-1.707c0.312-0.435,0.647-0.839,1.112-1.072
							c0.469-0.228,1.129-0.201,1.663-0.343c0.53-0.146,0.916-0.483,1.206-0.947l0.13,1.059c-0.63-0.021-1.326,0.111-1.91-0.02
							c-0.586-0.126-1.191-0.206-1.73-0.444c-0.535-0.246-1.023-0.601-1.473-1.047c-0.229-0.214-0.443-0.462-0.646-0.736
							c-0.204-0.273-0.39-0.587-0.558-0.945l0.117-0.276c0.374-0.127,0.729-0.211,1.067-0.254c0.339-0.044,0.666-0.062,0.979-0.045
							c0.634,0.014,1.229,0.119,1.777,0.334c0.545,0.223,1.022,0.604,1.52,0.938c0.5,0.33,0.887,0.923,1.34,1.362l0.871,0.845
							l-0.741,0.214c-0.525,0.152-0.956,0.431-1.241,0.901c-0.282,0.475-0.438,1.117-0.784,1.506
							c-0.351,0.384-0.832,0.595-1.335,0.777c-0.524,0.154-1.084,0.26-1.815,0.142L414.516,32.202z" />
				</g>
				</g>
				<g id="leaf1">
				<g>
					<path id="stem1" className="stem" fill="#39B54A" d="M492.706,72.839c0,0,0.36-0.001,1.035-0.002c0.676,0.008,1.666,0.044,2.917,0.14
							c1.255,0.083,2.768,0.25,4.501,0.477c1.726,0.253,3.676,0.55,5.778,0.988c1.049,0.228,2.143,0.465,3.272,0.711
							c1.125,0.273,2.277,0.598,3.461,0.933c2.373,0.65,4.779,1.672,7.29,2.613c1.251,0.486,2.506,1.027,3.8,1.497
							c1.281,0.51,2.619,0.897,3.932,1.399c2.63,0.979,5.394,1.726,8.103,2.648c1.363,0.444,2.728,0.887,4.085,1.329
							c1.355,0.454,2.662,1.015,3.972,1.545c1.304,0.543,2.597,1.083,3.873,1.616c1.25,0.597,2.485,1.186,3.699,1.766
							c1.219,0.574,2.396,1.18,3.553,1.765c1.137,0.627,2.225,1.282,3.277,1.927c1.055,0.645,2.091,1.252,3.071,1.875
							c0.998,0.593,1.88,1.303,2.769,1.912c1.741,1.276,3.333,2.444,4.717,3.512c1.33,1.146,2.469,2.167,3.391,3.021
							c0.917,0.856,1.638,1.528,2.129,1.987c0.479,0.47,0.735,0.72,0.735,0.72l-0.188,0.233c0,0-0.312-0.176-0.901-0.506
							c-0.575-0.341-1.421-0.842-2.494-1.479c-1.07-0.638-2.351-1.433-3.799-2.363c-1.518-0.832-3.197-1.807-5.027-2.868
							c-1.787-1.127-3.842-2.153-5.956-3.33c-1.065-0.573-2.148-1.181-3.245-1.823c-1.131-0.581-2.289-1.167-3.446-1.805
							c-1.163-0.63-2.345-1.27-3.542-1.918c-1.222-0.602-2.459-1.21-3.706-1.824c-2.48-1.258-5.015-2.525-7.569-3.765
							c-2.529-1.307-5.181-2.334-7.798-3.311c-2.632-0.938-5.3-1.639-7.896-2.233c-2.62-0.518-5.128-1.071-7.502-1.599
							c-2.363-0.57-4.573-1.194-6.633-1.703c-2.042-0.586-3.938-1.02-5.598-1.511c-1.672-0.424-3.122-0.859-4.323-1.184
							c-2.398-0.673-3.774-1.093-3.774-1.093L492.706,72.839z" />
				</g>
				<g>
					<path fill="#39B54A" d="M499.855,93.499c-0.535-0.759-0.979-1.542-1.362-2.343c-0.392-0.798-0.739-1.608-1.05-2.428
							c-0.312-0.82-0.579-1.652-0.821-2.491c-0.238-0.84-0.38-1.707-0.459-2.59c-0.08-0.883-0.117-1.779-0.22-2.656
							c-0.098-0.879-0.285-1.732-0.535-2.569c-0.488-1.677-1.307-3.263-2.038-4.873l-0.172-0.377l0.394-0.648
							c0.753-1.24,1.623-2.382,2.462-3.55c0.846-1.163,1.726-2.298,2.713-3.342c0.979-1.051,2.021-2.05,3.097-3.022
							c1.098-0.953,2.221-1.885,3.496-2.69l0.229,0.191c-0.56,1.401-1.271,2.675-2.009,3.927c-0.761,1.233-1.554,2.44-2.409,3.595
							c-0.847,1.162-1.802,2.233-2.792,3.275c-0.997,1.037-1.961,2.101-3.043,3.067l0.222-1.025c0.191,1.758,0.296,3.54,0.73,5.232
							c0.212,0.847,0.484,1.677,0.849,2.483c0.358,0.807,0.783,1.596,1.164,2.397c0.382,0.8,0.701,1.618,0.924,2.462
							c0.22,0.845,0.414,1.697,0.563,2.562c0.15,0.864,0.263,1.738,0.333,2.624c0.078,0.884,0.096,1.785,0.021,2.71L499.855,93.499z" />
				</g>
				<g>
					<path fill="#39B54A" d="M504.357,94.545c-0.565-0.768-1.039-1.563-1.461-2.376c-0.432-0.81-0.812-1.635-1.17-2.468
							c-0.677-1.677-1.333-3.361-1.583-5.173c-0.147-0.899-0.222-1.821-0.36-2.723c-0.123-0.907-0.322-1.789-0.589-2.65
							c-0.271-0.86-0.599-1.703-0.978-2.529c-0.375-0.827-0.8-1.639-1.204-2.457l-0.188-0.384l0.422-0.633
							c0.445-0.667,0.92-1.307,1.417-1.926l1.504-1.843c1.014-1.219,2.038-2.428,3.182-3.526c0.566-0.553,1.15-1.09,1.749-1.613
							c0.585-0.536,1.207-1.037,1.82-1.546c1.257-0.991,2.536-1.96,3.972-2.783l0.22,0.205c-0.731,1.484-1.619,2.822-2.528,4.138
							c-0.47,0.645-0.933,1.296-1.43,1.915c-0.484,0.63-0.983,1.247-1.501,1.847c-1.023,1.209-2.165,2.308-3.318,3.396l-1.746,1.617
							c-0.587,0.534-1.195,1.049-1.833,1.534l0.232-1.017c0.138,0.902,0.255,1.811,0.421,2.703c0.163,0.895,0.376,1.772,0.647,2.632
							c0.274,0.859,0.617,1.696,1.036,2.51c0.403,0.818,0.871,1.616,1.266,2.438c0.834,1.628,1.261,3.385,1.666,5.147
							c0.186,0.887,0.346,1.782,0.456,2.692c0.12,0.907,0.188,1.832,0.164,2.784L504.357,94.545z" />
				</g>
				<g>
					<path fill="#39B54A" d="M510.11,94.554c-0.603-0.711-1.115-1.457-1.577-2.222c-0.473-0.761-0.896-1.541-1.294-2.33
							c-0.763-1.592-1.509-3.19-1.858-4.94c-0.198-0.866-0.322-1.761-0.506-2.633c-0.167-0.878-0.41-1.727-0.717-2.551
							c-0.604-1.654-1.516-3.188-2.417-4.726l-0.253-0.431l0.507-0.674c0.95-1.267,2.032-2.393,3.098-3.538
							c1.071-1.138,2.151-2.267,3.345-3.275c1.188-1.015,2.432-1.969,3.715-2.881c1.301-0.894,2.624-1.764,4.094-2.476l0.205,0.219
							c-0.808,1.421-1.76,2.685-2.735,3.925c-0.993,1.222-2.025,2.401-3.115,3.52c-1.083,1.125-2.278,2.13-3.483,3.126
							c-1.211,0.989-2.405,1.996-3.731,2.863l0.254-1.105c0.358,1.747,0.705,3.498,1.364,5.13c0.322,0.818,0.709,1.612,1.172,2.377
							c0.446,0.771,0.953,1.519,1.385,2.295c0.909,1.536,1.425,3.223,1.923,4.916c0.23,0.853,0.438,1.716,0.597,2.598
							c0.167,0.877,0.284,1.775,0.312,2.706L510.11,94.554z" />
				</g>
				<g>
					<path fill="#39B54A" d="M514.293,94.555c-1.167-1.344-2.012-2.814-2.811-4.304c-0.383-0.751-0.742-1.511-1.066-2.285
							c-0.331-0.771-0.604-1.566-0.789-2.395c-0.199-0.823-0.329-1.674-0.494-2.511c-0.168-0.835-0.367-1.659-0.66-2.445
							c-0.558-1.584-1.403-3.054-2.296-4.506l-0.266-0.433l0.536-0.636c0.509-0.604,1.021-1.2,1.57-1.756
							c0.546-0.56,1.11-1.096,1.671-1.639c1.122-1.08,2.247-2.161,3.484-3.104c0.612-0.48,1.243-0.938,1.89-1.378
							c0.637-0.452,1.296-0.876,1.96-1.295c1.343-0.82,2.71-1.611,4.219-2.233l0.192,0.229c-0.871,1.38-1.886,2.591-2.923,3.774
							c-0.525,0.583-1.057,1.159-1.61,1.708c-0.544,0.561-1.104,1.104-1.683,1.625c-1.142,1.059-2.398,1.98-3.654,2.902
							c-0.632,0.458-1.258,0.923-1.901,1.364c-0.643,0.445-1.318,0.85-2,1.248l0.271-1.068c0.338,1.67,0.721,3.323,1.392,4.862
							c0.322,0.775,0.738,1.513,1.185,2.239c0.45,0.725,0.937,1.435,1.352,2.173c0.429,0.733,0.771,1.5,1.056,2.29
							c0.29,0.787,0.545,1.589,0.777,2.399c0.432,1.633,0.815,3.285,0.879,5.064L514.293,94.555z" />
				</g>
				<g>
					<path fill="#39B54A" d="M518.204,96.793c-0.539-0.723-0.988-1.474-1.386-2.24c-0.406-0.764-0.765-1.542-1.097-2.329
							c-0.628-1.583-1.241-3.171-1.454-4.88c-0.23-1.704-0.282-3.462-0.789-5.082c-0.456-1.635-1.236-3.173-2.008-4.713l-0.222-0.443
							l0.562-0.637c1.02-1.157,2.156-2.164,3.281-3.185c1.13-1.016,2.268-2.023,3.507-2.898c1.235-0.883,2.521-1.702,3.845-2.471
							c1.338-0.751,2.695-1.477,4.188-2.033l0.187,0.235c-0.891,1.321-1.913,2.472-2.957,3.596c-1.06,1.106-2.155,2.164-3.304,3.158
							c-1.142,1-2.388,1.87-3.639,2.73c-1.257,0.855-2.502,1.725-3.865,2.444l0.341-1.08c0.215,1.708,0.421,3.42,0.95,5.033
							c0.479,1.628,1.413,3.119,2.167,4.664c0.773,1.539,1.146,3.2,1.505,4.866c0.16,0.838,0.296,1.684,0.382,2.544
							c0.096,0.858,0.141,1.731,0.094,2.633L518.204,96.793z" />
				</g>
				<g>
					<path fill="#39B54A" d="M523.108,96.801c-0.571-0.666-1.057-1.362-1.48-2.08c-0.434-0.715-0.823-1.445-1.179-2.188
							c-0.356-0.743-0.668-1.501-0.958-2.268c-0.283-0.769-0.48-1.568-0.608-2.393c-0.135-0.822-0.218-1.662-0.371-2.478
							c-0.14-0.82-0.375-1.606-0.666-2.372c-0.571-1.535-1.462-2.957-2.286-4.401l-0.369-0.648l0.868-0.706
							c1.069-0.868,2.221-1.593,3.348-2.359c1.133-0.756,2.285-1.477,3.509-2.074c0.609-0.303,1.229-0.586,1.856-0.857
							c0.618-0.288,1.269-0.521,1.902-0.779c1.297-0.47,2.608-0.914,4.021-1.182l0.147,0.261c-0.946,1.081-1.993,1.987-3.056,2.867
							c-0.544,0.417-1.075,0.857-1.636,1.245c-0.554,0.403-1.11,0.795-1.682,1.167c-1.135,0.752-2.341,1.38-3.566,1.974
							c-1.231,0.583-2.439,1.207-3.729,1.689l0.499-1.354c0.281,1.64,0.498,3.303,1.03,4.852c0.262,0.776,0.58,1.533,0.992,2.255
							c0.399,0.727,0.869,1.429,1.288,2.149c0.425,0.718,0.779,1.461,1.05,2.234c0.263,0.776,0.505,1.56,0.701,2.359
							c0.196,0.799,0.358,1.611,0.479,2.438c0.128,0.824,0.196,1.67,0.178,2.547L523.108,96.801z" />
				</g>
				<g>
					<path fill="#39B54A" d="M528.861,98.057c-0.608-0.669-1.131-1.374-1.597-2.101c-0.474-0.724-0.902-1.465-1.303-2.219
							c-0.396-0.755-0.752-1.527-1.087-2.307c-0.325-0.784-0.581-1.596-0.753-2.442c-0.188-0.84-0.312-1.706-0.511-2.541
							c-0.183-0.842-0.447-1.65-0.779-2.432c-0.646-1.569-1.601-3.015-2.51-4.478l-0.467-0.751l1.06-0.672
							c1.229-0.778,2.53-1.376,3.81-2.032c1.286-0.638,2.584-1.247,3.938-1.721c0.677-0.24,1.36-0.46,2.052-0.665
							c0.684-0.223,1.391-0.391,2.086-0.581c1.413-0.333,2.838-0.64,4.344-0.754l0.114,0.276c-1.14,0.989-2.357,1.787-3.589,2.558
							c-0.626,0.361-1.241,0.746-1.88,1.075c-0.631,0.348-1.27,0.679-1.915,0.992c-1.289,0.63-2.635,1.125-3.993,1.592
							c-1.364,0.449-2.706,0.955-4.123,1.281l0.594-1.423c0.367,1.683,0.69,3.384,1.32,4.96c0.308,0.792,0.681,1.556,1.137,2.287
							c0.439,0.737,0.954,1.444,1.404,2.177c0.468,0.727,0.851,1.487,1.163,2.276c0.305,0.793,0.586,1.594,0.828,2.412
							c0.239,0.819,0.448,1.65,0.613,2.499c0.173,0.846,0.289,1.714,0.318,2.618L528.861,98.057z" />
				</g>
				<g>
					<path fill="#39B54A" d="M534.19,99.56c-0.615-0.636-1.146-1.311-1.623-2.008c-0.487-0.693-0.928-1.407-1.344-2.131
							c-0.797-1.464-1.574-2.936-1.961-4.581c-0.216-0.812-0.359-1.656-0.565-2.472c-0.192-0.823-0.457-1.613-0.787-2.375
							c-0.334-0.761-0.722-1.497-1.16-2.212c-0.434-0.716-0.915-1.412-1.376-2.116l-0.483-0.737l1.061-0.644
							c0.633-0.384,1.28-0.729,1.938-1.048l1.98-0.936c1.325-0.61,2.656-1.206,4.046-1.65c0.692-0.229,1.394-0.435,2.101-0.622
							c0.701-0.204,1.422-0.362,2.137-0.53c1.444-0.299,2.9-0.569,4.434-0.639l0.107,0.28c-1.188,0.972-2.451,1.743-3.727,2.486
							c-0.645,0.353-1.285,0.715-1.942,1.032c-0.652,0.333-1.312,0.648-1.979,0.94c-1.33,0.597-2.719,1.043-4.113,1.475l-2.099,0.627
							c-0.702,0.202-1.414,0.378-2.142,0.515l0.576-1.381c0.206,0.816,0.393,1.642,0.625,2.446c0.229,0.806,0.509,1.591,0.843,2.351
							c0.337,0.759,0.738,1.489,1.215,2.187c0.461,0.705,0.982,1.382,1.436,2.091c0.948,1.397,1.506,2.967,2.043,4.544
							c0.251,0.796,0.479,1.604,0.659,2.432c0.188,0.824,0.327,1.67,0.379,2.554L534.19,99.56z" />
				</g>
				<g>
					<path fill="#39B54A" d="M540.616,99.568c-0.616-0.528-1.149-1.1-1.633-1.696c-0.499-0.589-0.939-1.208-1.359-1.836
							c-0.812-1.272-1.604-2.556-2.013-4.035c-0.229-0.728-0.387-1.491-0.605-2.223c-0.205-0.739-0.48-1.442-0.822-2.112
							c-0.667-1.347-1.636-2.54-2.577-3.745l-0.639-0.815l1.276-0.657c0.596-0.307,1.203-0.573,1.818-0.813
							c0.612-0.243,1.232-0.465,1.85-0.698c0.621-0.219,1.238-0.451,1.864-0.651c0.624-0.205,1.261-0.367,1.9-0.518
							c0.643-0.147,1.289-0.271,1.942-0.376c0.646-0.123,1.31-0.196,1.969-0.282c1.329-0.131,2.666-0.234,4.062-0.13l0.082,0.288
							c-1.132,0.824-2.321,1.442-3.521,2.032c-0.604,0.274-1.206,0.561-1.821,0.799c-0.609,0.255-1.227,0.491-1.849,0.705
							c-0.623,0.209-1.25,0.407-1.889,0.562c-0.639,0.16-1.284,0.289-1.928,0.43c-0.646,0.127-1.291,0.265-1.939,0.382
							c-0.648,0.121-1.305,0.214-1.974,0.268l0.641-1.472c0.427,1.47,0.829,2.952,1.531,4.281c0.344,0.668,0.753,1.303,1.232,1.901
							c0.465,0.606,0.991,1.181,1.449,1.79c0.961,1.197,1.54,2.589,2.098,3.991c0.266,0.709,0.511,1.428,0.695,2.177
							c0.202,0.741,0.354,1.507,0.423,2.316L540.616,99.568z" />
				</g>
				<g>
					<path fill="#39B54A" d="M546.675,101.569c-0.602-0.513-1.117-1.069-1.592-1.647c-0.491-0.57-0.924-1.169-1.335-1.78
							c-0.798-1.233-1.58-2.475-2.034-3.886c-0.455-1.41-0.703-2.927-1.338-4.245c-0.61-1.331-1.478-2.529-2.412-3.692l-0.642-0.797
							l1.28-0.521c0.651-0.265,1.305-0.523,1.966-0.735c0.662-0.207,1.328-0.391,1.996-0.575c1.335-0.364,2.668-0.736,4.034-0.929
							c1.367-0.198,2.748-0.324,4.146-0.366c1.398-0.03,2.807-0.021,4.256,0.207l0.057,0.295c-1.259,0.752-2.561,1.286-3.87,1.781
							c-1.312,0.483-2.639,0.882-3.979,1.209c-1.341,0.332-2.717,0.485-4.091,0.646c-0.688,0.079-1.374,0.158-2.066,0.213
							c-0.691,0.05-1.395,0.054-2.098,0.052l0.641-1.318c0.403,1.437,0.874,2.839,1.604,4.108c0.704,1.282,1.794,2.366,2.678,3.555
							c0.885,1.189,1.44,2.547,1.981,3.914c0.258,0.689,0.493,1.39,0.673,2.12c0.193,0.722,0.349,1.465,0.416,2.253L546.675,101.569z" />
				</g>
				<g>
					<path fill="#39B54A" d="M552.543,103.809c-0.54-0.481-0.993-0.999-1.394-1.539c-0.418-0.533-0.774-1.091-1.11-1.659
							c-0.643-1.147-1.263-2.304-1.488-3.629c-0.135-0.652-0.199-1.335-0.329-1.99c-0.113-0.662-0.301-1.292-0.557-1.894
							c-0.494-1.21-1.3-2.288-2.078-3.378l-0.621-0.87l1.423-0.568c1.089-0.434,2.204-0.675,3.315-0.956
							c0.558-0.125,1.113-0.265,1.674-0.371c0.561-0.111,1.126-0.179,1.694-0.234c0.568-0.052,1.142-0.08,1.717-0.088
							c0.573-0.027,1.153-0.003,1.731,0.008c1.163,0.063,2.331,0.156,3.53,0.46l0.045,0.297c-1.055,0.648-2.142,1.085-3.232,1.492
							c-0.549,0.183-1.096,0.378-1.65,0.523c-0.552,0.164-1.105,0.307-1.665,0.427c-0.559,0.116-1.12,0.22-1.688,0.281
							c-0.566,0.066-1.14,0.099-1.708,0.145c-1.146,0.063-2.282,0.166-3.449,0.076l0.802-1.438c0.245,1.316,0.461,2.645,0.989,3.84
							c0.256,0.602,0.58,1.174,0.979,1.715c0.382,0.548,0.827,1.068,1.203,1.619c0.797,1.082,1.199,2.331,1.579,3.59
							c0.175,0.636,0.33,1.28,0.423,1.951c0.111,0.663,0.169,1.349,0.142,2.071L552.543,103.809z" />
				</g>
				<g>
					<path fill="#39B54A" d="M558.115,103.815c-0.523-0.345-0.953-0.735-1.332-1.149c-0.393-0.408-0.725-0.844-1.03-1.293
							c-0.306-0.449-0.575-0.916-0.809-1.399c-0.236-0.482-0.356-1.02-0.446-1.573c-0.077-0.559-0.136-1.126-0.253-1.666
							c-0.121-0.537-0.325-1.035-0.593-1.502c-0.511-0.947-1.363-1.729-2.045-2.594l-1.021-1.293l2.127-0.411
							c0.852-0.164,1.695-0.188,2.543-0.271c0.847-0.07,1.691-0.057,2.533,0.058c0.842,0.103,1.681,0.292,2.521,0.524
							c0.837,0.25,1.674,0.545,2.504,1.022l-0.009,0.3c-0.856,0.428-1.71,0.674-2.561,0.875c-0.85,0.183-1.699,0.323-2.546,0.377
							c-0.847,0.066-1.69,0.03-2.531-0.089c-0.842-0.131-1.685-0.205-2.521-0.418l1.106-1.704c0.247,1.073,0.322,2.228,0.74,3.219
							c0.196,0.501,0.456,0.972,0.799,1.404c0.347,0.43,0.753,0.831,1.14,1.241c0.375,0.417,0.72,0.847,0.946,1.333
							c0.23,0.485,0.425,0.987,0.583,1.507s0.29,1.052,0.363,1.613c0.085,0.555,0.119,1.134,0.06,1.757L558.115,103.815z" />
				</g>
				<g>
					<path fill="#39B54A" d="M563.454,103.829c-0.907-0.39-1.562-0.935-2.14-1.527c-0.553-0.608-1.079-1.232-1.334-2.024
							c-0.25-0.794-0.26-1.736-0.617-2.464c-0.338-0.74-0.908-1.337-1.577-1.874l-1.138-0.914l1.962-0.359
							c0.413-0.075,0.828-0.177,1.236-0.217c0.409-0.054,0.812-0.042,1.214-0.049c0.808-0.008,1.613-0.021,2.397,0.137
							c0.784,0.16,1.559,0.395,2.32,0.731c0.381,0.164,0.76,0.345,1.133,0.566c0.375,0.214,0.746,0.455,1.106,0.765l-0.039,0.298
							c-0.43,0.206-0.851,0.342-1.268,0.453c-0.418,0.116-0.831,0.193-1.241,0.253c-0.822,0.128-1.631,0.155-2.43,0.106
							c-0.799-0.05-1.575-0.271-2.353-0.488c-0.389-0.11-0.778-0.203-1.16-0.361c-0.384-0.144-0.76-0.351-1.139-0.53l0.824-1.272
							c0.179,0.838,0.455,1.616,0.964,2.251c0.488,0.648,1.326,1.08,1.922,1.661c0.593,0.583,0.913,1.334,1.207,2.101
							c0.27,0.783,0.461,1.613,0.401,2.598L563.454,103.829z" />
				</g>
				<g>
					<path fill="#39B54A" d="M567.793,106.405c-0.394-0.177-0.697-0.388-0.954-0.617c-0.271-0.223-0.485-0.468-0.675-0.723
							c-0.185-0.257-0.344-0.523-0.471-0.802c-0.135-0.276-0.195-0.58-0.181-0.913c-0.001-0.327,0.078-0.684,0.105-1.022
							c0.038-0.342,0.018-0.661-0.075-0.953c-0.174-0.587-0.645-1.062-1.138-1.528l-0.619-0.583l1.524-0.734
							c0.278-0.134,0.559-0.249,0.837-0.324c0.277-0.069,0.558-0.114,0.836-0.17c0.558-0.11,1.113-0.196,1.672-0.121
							c0.558,0.064,1.114,0.21,1.672,0.434c0.278,0.117,0.558,0.243,0.836,0.407c0.14,0.069,0.278,0.183,0.418,0.274
							c0.14,0.093,0.278,0.192,0.418,0.32v0.3c-0.14,0.128-0.278,0.227-0.418,0.32c-0.14,0.092-0.278,0.205-0.418,0.274
							c-0.278,0.165-0.558,0.29-0.836,0.407c-0.558,0.223-1.114,0.37-1.672,0.434c-0.559,0.076-1.114-0.011-1.672-0.121
							c-0.278-0.057-0.559-0.101-0.836-0.17c-0.278-0.075-0.559-0.189-0.837-0.324l0.906-1.317c-0.057,0.676-0.091,1.344,0.172,1.897
							c0.126,0.279,0.324,0.531,0.58,0.76c0.246,0.233,0.543,0.446,0.762,0.69c0.233,0.238,0.392,0.505,0.476,0.8
							c0.091,0.292,0.15,0.597,0.185,0.911c0.029,0.316,0.033,0.642-0.021,0.989c-0.038,0.342-0.124,0.702-0.299,1.096
							L567.793,106.405z" />
				</g>
				</g>
			</g>
			</g>
			<path id="textPath" fill="none" d="M265.346,209.046c0,0,223.687,39.829,459.593,0" />
			<g id="strIsland">
			{/*<text id="alex" transform="matrix(1 0 0 1 300 130)" fill="#FFFFFF" font-family="Quicksand-Regular" font-size="70">Alex’s</text>
			<text id="Repository" transform="matrix(1 0 0 1 490 130)" fill="#FFFFFF" font-family="Quicksand-Regular" font-size="70">repository</text>*/}
			<text id="alex_repository" transform="matrix(1 0 0 1 30 28)" fill="#FFFFFF" fontFamily="Quicksand-Regular" fontSize={68}>
				<textPath id="textMe" xlinkHref="#textPath">
				alex’s repository
				</textPath>
			</text>
			</g>
			<g id="frontSea" className="sea">
			<g id="frontSea2">
				<path fill="#0068FF" d="M-1,248.875c0,0,58.125,52.312,86,0.062c0,0,21.437,33.871,56.437,30.076c0,0,33.781,3.721,53.781-30.029
					c0,0,36.266,63.758,100.016,0.008c0,0,41.258,67.504,103.758,0.004c0,0,41.254,66.252,97.504,0.002
					c0,0,57.502,67.501,103.752,0.001c0,0,46.251,70.001,92.501,0.001c0,0,41.251,63.75,85.001,0c0,0,50,50,87.5,0
					c0,0,38.5,43.75,69.75,0c0,0,40,50,68,0v76H-1" />
				<g>
				<path fill="none" stroke="#FFFFFF" strokeWidth={3} strokeLinecap="round" strokeMiterlimit={10} d="M1,249
						c0,0,56.125,52.25,84,0c0,0,21.437,33.84,56.437,30.045c0,0,33.781,3.705,53.781-30.045c0,0,36.266,63.75,100.016,0
						c0,0,41.258,67.5,103.758,0c0,0,41.254,66.25,97.504,0c0,0,57.502,67.5,103.752,0c0,0,46.251,70,92.501,0
						c0,0,41.251,63.75,85.001,0c0,0,50,50,87.5,0c0,0,38.5,43.75,69.75,0c0,0,38,50,66,0" />
				</g>
			</g>
			<g id="frontSea1">
				<path fill="#0068FF" d="M-999,249.25c0,0,63.125,52.125,91-0.125c0,0,16.437,33.777,51.437,29.982c0,0,33.781,3.674,53.781-30.076
					c0,0,36.266,63.733,100.016-0.016c0,0,41.258,67.491,103.758-0.009c0,0,41.254,66.246,97.504-0.004
					c0,0,57.502,67.498,103.752-0.002c0,0,46.251,69.999,92.501-0.001c0,0,41.251,63.749,85.001-0.001c0,0,50,50,87.5,0
					c0,0,40.5,43.75,71.75,0c0,0,35,50,63,0v76h-1002" />
				<g>
				<path fill="none" stroke="#FFFFFF" strokeWidth={3} strokeMiterlimit={10} d="M-1000,249c0,0,62.125,52.25,90,0
						c0,0,16.437,33.84,51.437,30.045c0,0,33.781,3.705,53.781-30.045c0,0,36.266,63.75,100.016,0c0,0,41.258,67.5,103.758,0
						c0,0,41.254,66.25,97.504,0c0,0,57.502,67.5,103.752,0c0,0,46.251,70,92.501,0c0,0,41.251,63.75,85.001,0c0,0,50,50,87.5,0
						c0,0,40.5,43.75,71.75,0c0,0,36,50,64,0" />
				</g>
			</g>
			</g>
		</svg>
	)
}


let IslandThemeColours: ThemeColours = {
	primary: '#F4F06E',
	secondary: '#0068FF',
	background: '#0068FF',
    buttonSelected: '#00FFFF',
	heading: '#0068FF'
}
/*heading: '#F4F06E'*/

export var IslandTheme: Theme = new Theme("island", <Island/>, IslandThemeColours);
