import React from 'react';
import { Theme, ThemeColours } from '../themes';

import './cali.css';

function Cali(): JSX.Element {

	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 1000 300" enableBackground="new 0 0 1000 300" xmlSpace="preserve">
   <g id="background">
	   <rect id="sky" fill="#EF8D90" width="999.843" height="301"/>
	   <rect id="sea" y="157" fill="#55B6BA" width="1000" height="144"/>
	   <rect id="sand" y="221" fill="#FFEBD1" width="999.843" height="80"/>
	   <rect id="road" y="243" fill="#303242" width="999.843" height="58"/>
   </g>
   <g id="clouds" display="none">
	   <path id="cloud1" display="inline" fill="#2BE5E0" d="M27,57h233c0,0-3-34.667-39.667-23.333c0,0-26-49.886-56-2.276
		   c0,0-28.352-43.39-58.333-1.783c0,0-28.516-41.607-45.015,4.01C60.985,33.617,23.666,18.334,27,57z"/>
	   <path id="cloud3" display="inline" fill="#2BE5E0" d="M968,80H746c0,0,4.579-33.976,31.578-16.988c0,0,26.999-61.09,64.497-4.756
		   c0,0,26.998-38.953,55.497-1.059c0,0,28.499-26.579,40.498,3.408C938.07,60.605,961.932,48.41,968,80z"/>
	   <path id="cloud2" display="inline" fill="#F2EAEC" d="M222,80h233c0,0-3-34.667-39.666-23.333c0,0-26-49.886-56-2.276
		   c0,0-28.353-43.39-58.334-1.783c0,0-28.516-41.607-45.015,4.01C255.985,56.617,218.666,41.334,222,80z"/>
   </g>
   <g id="mountains">
	   <path fill="#49969A" d="M0,157c0,0,249.743-153.583,468.662,0H0z"/>
	   <path fill="#49969A" d="M999,157c0,0-113.428-121.084-236.422,0"/>
	   <path fill="#49969A" d="M849.573,157c0,0-99.157-102-175.572,0H849.573z"/>
   </g>
   <g>
		<text id='retroAlex' className='retroAlex'>Alex's Repository</text>
	</g>
   <g id="scrollables">
		<g id='scrollable1'>
			<image overflow="visible" enableBackground="new    " width="4179" height="975" href="/caliBackgroundRaster.png"  transform="matrix(0.2402 0 0 0.2396 -1.2231 1.1123)"></image>
		</g>
	
		<g id='scrollable2'>
			<image overflow="visible" enableBackground="new    " width="4179" height="975" href="/caliBackgroundRaster.png"  transform="matrix(0.2402 0 0 0.2396 999 1.1123)"></image>
		</g>
   </g>
   <g>
	   <path fill="none" stroke="#F0D1B7" strokeWidth="10" strokeMiterlimit="10" d="M-103.504,321.579"/>
	   <path fill="none" stroke="#F0D1B7" strokeWidth="5" strokeMiterlimit="10" d="M-101.481,344.392c0,0,28.568,12.857,62.492,0"/>
	   <path fill="none" stroke="#F0D1B7" strokeWidth="5" strokeMiterlimit="10" d="M-101.481,327.5c0,0,29.078,15.631,62.492,0"/>
	   <rect x="-107.551" y="318" fill="#B96D67" width="11" height="44"/>
	   <rect x="-45.551" y="318" fill="#B96D67" width="11" height="44"/>
   </g>
   <g id="vw_bus">
	   <g id="wheelGroup">
		   <rect id="axle" x="401.099" y="230.988" fill="#3A4458" width="188.987" height="36.634"/>
		   <g id="mealsOnWheels">
			<g id="wheel2">
				<circle cx="567.514" cy="267.309" r="19.005"/>
				<g>
					<circle fill="none" stroke="#FFFFFF" strokeMiterlimit="10" cx="567.514" cy="267.308" r="12.987"/>
					<circle fill="#FFFFFF" cx="567.514" cy="267.308" r="11.282"/>
					<g>
						<path opacity="0.5" d="M573.312,260.935c-1.519-1.326-3.5-2.136-5.674-2.136v7.81L573.312,260.935z"/>
						<polygon opacity="0.5" points="567.514,266.733 567.639,266.733 567.639,266.608 						"/>
						<polygon opacity="0.5" points="567.46,266.733 567.486,266.761 567.514,266.733 						"/>
						<polygon opacity="0.5" points="567.486,266.761 567.639,266.912 567.639,266.733 567.514,266.733 						"/>
						<path opacity="0.5" d="M561.807,261.08c-1.558,1.43-2.587,3.419-2.767,5.653h8.42L561.807,261.08z"/>
						<path opacity="0.5" d="M576.237,266.733h-8.599v0.179l6.352,6.352c1.413-1.537,2.282-3.58,2.282-5.832
							C576.272,267.196,576.256,266.964,576.237,266.733z"/>
						<path opacity="0.5" d="M567.486,266.761l-6.344,6.344c1.583,1.811,3.903,2.959,6.496,2.959v-9.151L567.486,266.761z"/>
					</g>
					<circle fill="#B3B3B3" cx="567.514" cy="267.308" r="7.23"/>
				</g>
			</g>
			<g id="wheel1">
				<circle cx="432.019" cy="267.422" r="19.006"/>
				<g>
					<circle fill="none" stroke="#FFFFFF" strokeMiterlimit="10" cx="432.019" cy="267.422" r="12.987"/>
					<circle fill="#FFFFFF" cx="432.019" cy="267.422" r="11.282"/>
					<g>
						<path opacity="0.5" d="M437.676,260.926c-1.519-1.326-3.5-2.136-5.674-2.136v7.81L437.676,260.926z"/>
						<polygon opacity="0.5" points="431.877,266.725 432.002,266.725 432.002,266.6 						"/>
						<polygon opacity="0.5" points="431.823,266.725 431.85,266.752 431.877,266.725 						"/>
						<polygon opacity="0.5" points="431.85,266.752 432.002,266.903 432.002,266.725 431.877,266.725 						"/>
						<path opacity="0.5" d="M426.17,261.071c-1.558,1.43-2.587,3.419-2.767,5.653h8.42L426.17,261.071z"/>
						<path opacity="0.5" d="M440.601,266.725h-8.599v0.179l6.352,6.352c1.413-1.537,2.282-3.58,2.282-5.832
							C440.636,267.188,440.619,266.955,440.601,266.725z"/>
						<path opacity="0.5" d="M431.85,266.752l-6.344,6.344c1.583,1.811,3.903,2.959,6.496,2.959v-9.151L431.85,266.752z"/>
					</g>
					<circle fill="#B3B3B3" cx="432.019" cy="267.422" r="7.23"/>
				</g>
			</g>
		</g>
	   </g>
	   <g id="bodyGroup">
		   <g id="body">
			   <path id="bottom" fill="#2BE5E0" d="M612.591,237.02c-1.96-7.604-5.021-16.027-11.194-17.901
				   c-13.726-4.17-55.176-3.525-55.176-3.525l-154.791-0.497c-0.481,7.163-2.672,40.688-1.923,46.082l15.725,2.449l4.759-8.518
				   c0,0-0.168-1.867,4.03-1.867h34.754c0,0,2.016-0.303,3.526,2.89l5.374,9.437H547c0,0,1.509-18.606,20.179-18.942
				   c0,0,14.324-1.034,20.117,14.706l2.519,6.156l13.284,0.023l9.887-6.313C612.983,261.195,614.774,250.654,612.591,237.02z"/>
			   <path id="top" fill="#FFFFFF" d="M601.396,219.117c6.172,1.874,9.233,10.299,11.193,17.902
				   c-1.126-7.024-3.305-14.869-7.329-22.521l-14.941-23.589h4.529c0,0-1.97-5.526-19.476-6.971
				   c-46.841-3.859-119.431-4.498-159.42-1.299c-12.593,1.008-15.027,3.412-18.05,7.154v0.357c0,0-5.96,12.342-6.463,23.592
				   c0,0,0.008,0.48-0.049,1.347l154.832,0.499C546.221,215.592,587.671,214.947,601.396,219.117z"/>
			   <path id="door" opacity="0.1" fill="none" stroke="#000000" strokeMiterlimit="10" enableBackground="new    " d="
				   M586.212,190.663l13.237,22.81c1.764,2.641,4.674,26.297,4.674,26.297v19.293h-13.639c-3.021-0.685-3.348-2.781-3.348-2.781
				   l-4.229-9.651c-1.132-3.022-4.422-2.549-4.422-2.549L545.92,243.8v-30.956"/>
		   </g>
		   <g id="bumpers">
			   <g id="backBumper">
				   <path fill="#FFFDFD" d="M383.295,268.488l17.293,0.5c0,0,3.526-0.551,4.533-4.996c0,0,0.756-1.639-1.259-1.557l-15.195-1.168
					   l-1.847-9.396h-3.022L383.295,268.488z"/>
				   <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeMiterlimit="10" d="M393.748,263.441c0,0-5.351-7.933-7.114-8.688"
					   />
			   </g>
			   <g id="frontBumper">
				   <path fill="#FFFFFF" d="M616.509,251.188h2.519l0.504,16.436h-23.672c0,0-5.668,1.121-7.43-5.77c0,0-0.507-1.646,2.644-1.332
					   l22.917-0.008L616.509,251.188z"/>
				   <path fill="none" stroke="#FFFDFD" strokeWidth="5" strokeMiterlimit="10" d="M607.946,260.871c0,0,0.573-4.316,1.006-5.234
					   c0.362-0.771,1.01-2.267,3.022-2.393c2.015-0.128,4.4,0,4.4,0"/>
			   </g>
		   </g>
		   <g id="grill">
			   <line fill="none" stroke="#666666" strokeMiterlimit="10" x1="409.316" y1="218.663" x2="426.434" y2="218.663"/>
			   <line fill="none" stroke="#666666" strokeMiterlimit="10" x1="409.316" y1="220.717" x2="426.434" y2="220.717"/>
			   <line fill="none" stroke="#666666" strokeMiterlimit="10" x1="409.316" y1="222.771" x2="426.434" y2="222.771"/>
			   <line fill="none" stroke="#666666" strokeMiterlimit="10" x1="409.316" y1="224.826" x2="426.434" y2="224.826"/>
			   <line fill="none" stroke="#666666" strokeMiterlimit="10" x1="409.316" y1="226.879" x2="426.434" y2="226.879"/>
			   <line fill="none" stroke="#666666" strokeMiterlimit="10" x1="409.316" y1="228.934" x2="426.434" y2="228.934"/>
			   <line fill="none" stroke="#666666" strokeMiterlimit="10" x1="409.316" y1="230.988" x2="426.434" y2="230.988"/>
			   <line fill="none" stroke="#666666" strokeMiterlimit="10" x1="409.316" y1="233.042" x2="426.434" y2="233.042"/>
			   <line fill="none" stroke="#666666" strokeMiterlimit="10" x1="409.316" y1="235.098" x2="426.434" y2="235.098"/>
			   <line fill="none" stroke="#666666" strokeMiterlimit="10" x1="409.316" y1="237.15" x2="426.434" y2="237.15"/>
		   </g>
		   <g id="lights">
			   <g id="headlight">
				   <path fill="#325F71" d="M612.768,246.682c0,0-0.271,1.041-1.844,1.711c0,0-2.127,0-2.513-5.762
					   c-0.429-6.473,1.97-7.395,1.97-7.395c0.926,0.084,2.21,1.783,2.21,1.783"/>
				   <ellipse fill="#FFEBD1" cx="611.692" cy="241.993" rx="1.98" ry="5.582"/>
			   </g>
			   <g id="indicatorLight">
				   <path fill="#325F71" d="M607.035,227.104c0,0-0.538-0.871-1.451-0.713c0,0-1.861,0.877-0.471,4.754
					   c0.471,1.316,1.704,2.573,2.609,2.57c0.463,0,1.119-0.632,1.313-1.729L607.035,227.104z"/>
				   
					   <ellipse transform="matrix(0.3419 0.9397 -0.9397 0.3419 615.7858 -419.7001)" fill="#FF931E" cx="607.566" cy="229.832" rx="2.846" ry="1.43"/>
			   </g>
		   </g>
		   <path id="roofDivider" fill="none" stroke="#55B6BA" strokeMiterlimit="10" d="M594.85,190.911c0,0-83.253-4.364-196.947-1.114"
			   />
		   <g id="handle">
			   <path opacity="0.1" fill="#1A1A1A" enableBackground="new    " d="M557.073,226.296c0,0-1.302-2.228-3.779-2.246
				   c-2.477-0.021-3.986,1.909-3.986,1.909s0.502,2.395,3.883,2.436C556.57,228.436,557.073,226.296,557.073,226.296z"/>
			   <path fill="#F2F2F2" d="M557.219,225.916c0,0-3.725-0.751-7.311-0.018c0,0-0.036-0.729-0.707-0.729h-0.965
				   c0,0-1.237,0.214-1.237,1.401l0,0c0,0,0,1.338,1.352,1.338h0.957c0,0,0.587-0.114,0.579-0.741c0,0,3.908,0.599,7.332-0.329
				   V225.916L557.219,225.916z"/>
		   </g>
		   <g id="surfboard">
			   <path fill="#FC1146" d="M384.303,174.791c17.125-4.591,140.656-5.162,156.218,0C540.534,175.883,384.386,175.716,384.303,174.791
				   z"/>
			   <path fill="#FC1146" d="M515.357,172.222c0,0,0.886-4.699,4.835-6.58c0,0,5.838-1.977,2.218,0.9c0,0-2.247,2.393-1.081,5.981
				   L515.357,172.222z"/>
		   </g>
		   <g id="roofRack">
			   <path fill="none" stroke="#F2EAEC" strokeWidth="3" strokeMiterlimit="10" d="M398.155,176.895c0,0-3.526-6.088,3.643-8.133
				   l121.148-0.389c7.558,2.393,3.651,8.521,3.651,8.521H398.155z"/>
			   
				   <line fill="none" stroke="#F2EAEC" strokeWidth="3" strokeMiterlimit="10" x1="401.441" y1="169.02" x2="401.441" y2="189.562"/>
			   
				   <line fill="none" stroke="#F2EAEC" strokeWidth="3" strokeMiterlimit="10" x1="523.324" y1="168.334" x2="523.324" y2="188.877"/>
			   
				   <line fill="none" stroke="#F2EAEC" strokeWidth="3" strokeMiterlimit="10" x1="452.795" y1="168.334" x2="452.795" y2="188.877"/>
			   
				   <line fill="none" stroke="#F2EAEC" strokeWidth="3" strokeMiterlimit="10" x1="426.092" y1="169.02" x2="426.092" y2="177.236"/>
			   
				   <line fill="none" stroke="#F2EAEC" strokeWidth="3" strokeMiterlimit="10" x1="475.392" y1="169.02" x2="475.392" y2="177.236"/>
			   
				   <line fill="none" stroke="#F2EAEC" strokeWidth="3" strokeMiterlimit="10" x1="499.358" y1="169.02" x2="499.358" y2="177.236"/>
			   
				   <line fill="#B96D67" stroke="#B96D67" strokeWidth="3" strokeMiterlimit="10" x1="402.525" y1="176.895" x2="522.24" y2="176.977"/>
		   </g>
		   <g id="windows">
			   <g>
				   <g>
					   <path fill="#55B6BA" d="M540.166,209.979c0,1.883-1.541,3.424-3.424,3.424H515.02c-1.883,0-3.424-1.541-3.424-3.424V195.01
						   c0-1.884,1.541-3.423,3.424-3.423h21.723c1.883,0,3.424,1.539,3.424,3.423V209.979z"/>
				   </g>
				   <g>
					   <path fill="#F2F2F2" d="M539.514,209.482c0,1.883-1.541,3.424-3.424,3.424h-20.416c-1.882,0-3.423-1.541-3.423-3.424V195.51
						   c0-1.883,1.541-3.424,3.423-3.424h20.416c1.883,0,3.424,1.541,3.424,3.424V209.482z"/>
				   </g>
				   <g>
					   <path fill="#55B6BA" d="M539.088,209.156c0,1.884-1.541,3.424-3.424,3.424h-19.565c-1.883,0-3.424-1.54-3.424-3.424v-13.322
						   c0-1.883,1.541-3.424,3.424-3.424h19.565c1.883,0,3.424,1.541,3.424,3.424V209.156z"/>
				   </g>
			   </g>
			   <polygon fill="#55B6BA" points="588.175,191.583 590.316,191.918 603.941,213.48 600.515,212.568 			"/>
			   <g>
				   <g>
					   <path fill="#55B6BA" d="M506.419,209.979c0,1.883-1.541,3.424-3.424,3.424h-21.724c-1.882,0-3.423-1.541-3.423-3.424V195.01
						   c0-1.884,1.541-3.423,3.423-3.423h21.724c1.883,0,3.424,1.539,3.424,3.423V209.979z"/>
				   </g>
				   <g>
					   <path fill="#F2F2F2" d="M505.767,209.482c0,1.883-1.541,3.424-3.424,3.424h-20.417c-1.884,0-3.424-1.541-3.424-3.424V195.51
						   c0-1.883,1.54-3.424,3.424-3.424h20.417c1.883,0,3.424,1.541,3.424,3.424V209.482z"/>
				   </g>
				   <g>
					   <path fill="#55B6BA" d="M505.34,209.156c0,1.884-1.541,3.424-3.423,3.424h-19.565c-1.883,0-3.424-1.54-3.424-3.424v-13.322
						   c0-1.883,1.541-3.424,3.424-3.424h19.565c1.882,0,3.423,1.541,3.423,3.424V209.156z"/>
				   </g>
			   </g>
			   <g>
				   <g>
					   <path fill="#55B6BA" d="M472.611,210.062c0,1.883-1.541,3.424-3.424,3.424h-21.723c-1.882,0-3.423-1.541-3.423-3.424v-14.967
						   c0-1.885,1.541-3.425,3.423-3.425h21.722c1.884,0,3.425,1.54,3.425,3.425V210.062z"/>
				   </g>
				   <g>
					   <path fill="#F2F2F2" d="M471.959,209.566c0,1.882-1.541,3.422-3.424,3.422h-20.416c-1.883,0-3.424-1.54-3.424-3.422v-13.974
						   c0-1.883,1.541-3.424,3.424-3.424h20.416c1.883,0,3.424,1.541,3.424,3.424V209.566L471.959,209.566z"/>
				   </g>
				   <g>
					   <path fill="#55B6BA" d="M471.533,209.24c0,1.884-1.54,3.424-3.424,3.424h-19.564c-1.884,0-3.424-1.54-3.424-3.424v-13.321
						   c0-1.883,1.54-3.424,3.424-3.424h19.564c1.884,0,3.424,1.541,3.424,3.424V209.24z"/>
				   </g>
			   </g>
			   <g>
				   <g>
					   <path fill="#49969A" d="M439.107,209.979c0,1.883-1.54,3.424-3.425,3.424h-21.721c-1.883,0-3.424-1.541-3.424-3.424V195.01
						   c0-1.882,1.541-3.423,3.424-3.423h21.721c1.885,0,3.425,1.541,3.425,3.423V209.979z"/>
				   </g>
				   <g>
					   <path fill="#FFFFFF" d="M438.454,209.482c0,1.883-1.54,3.424-3.424,3.424h-20.416c-1.883,0-3.424-1.541-3.424-3.424V195.51
						   c0-1.883,1.541-3.424,3.424-3.424h20.416c1.884,0,3.424,1.541,3.424,3.424V209.482L438.454,209.482z"/>
				   </g>
				   <g>
					   <path fill="#55B6BA" d="M438.03,209.156c0,1.883-1.541,3.424-3.423,3.424H415.04c-1.883,0-3.424-1.541-3.424-3.424v-13.321
						   c0-1.884,1.541-3.424,3.424-3.424h19.565c1.884,0,3.424,1.54,3.424,3.424L438.03,209.156L438.03,209.156z"/>
				   </g>
			   </g>
			   <g>
				   <path fill="#55B6BA" stroke="#55B6BA" strokeMiterlimit="10" d="M545.92,212.568v-18.637c0,0,0.16-2.309,3.311-2.35
					   l34.584,0.324c0,0,2.937-0.116,4.069,2.402l9.431,18.092L545.92,212.568z"/>
				   <path fill="#FFFFFF" d="M546.829,212.329v-18.21c0,0,0-2.256,3.077-2.297l33.711,0.316c0,0,2.83-0.111,3.938,2.35l9.351,17.677
					   L546.829,212.329z"/>
				   <path fill="#55B6BA" d="M547.418,211.859V194.48c0,0,0-2.151,2.973-2.189l32.574,0.301c0,0,2.736-0.105,3.808,2.241l9.034,16.87
					   L547.418,211.859z"/>
			   </g>
		   </g>
		   <g id="mirrors">
			   <polyline fill="none" stroke="#B3B3B3" strokeMiterlimit="10" points="595.564,206.337 598.375,206.337 601.046,212.868 
				   601.419,217.592 			"/>
			   
				   <ellipse transform="matrix(0.0868 -0.9962 0.9962 0.0868 337.4101 782.887)" fill="#B3B3B3" cx="595.745" cy="207.397" rx="6.502" ry="0.9"/>
		   </g>
	   </g>
   </g>
   </svg>
   
	)
}


let CaliThemeColours: ThemeColours = {
	primary: '#F18C8E',
	secondary: '#0DE5E1',
	background: '#F18C8E',
    buttonSelected: '#0DE5E1',
	heading: '#F1D1B5'
}

export var CaliTheme: Theme = new Theme("cali", <Cali/>, CaliThemeColours);
