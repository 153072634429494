import React from 'react';
import { Theme, ThemeColours } from '../themes';

import './blossom.css';

function BlossomText(): JSX.Element{
	let hour: number = new Date().getHours();

	return (
		<svg>
			<text id="welcome" className={'chineseText'}>大家好</text>
			<text id="time" className={'chineseText'}>
				{hour<12 ? '早安' : (hour<18 ? '午安' : '晚安')}
			</text>
		</svg>
	);
}

function Blossom(): JSX.Element {

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" style={{backgroundColor: 'white'}}>
			<g id="chineseClouds" transform="translate(800,0)">
			<g>
				<path fill="none" stroke="#1B1464" strokeWidth={3} strokeMiterlimit={10} d="M326.05,62.036c0,0,18.992,17.67,24.241-8.302
				c0,0,21.91-11.786-0.5-27.28c0,0,0.66-28.779-25.041-16.958c0,0-52.904-11.219-69.799,13.497
				c-21.605,31.606-34.821,10.614-34.821,10.614s-9.673-8.87,3.398-18.112c0,0-24.369,11.247-6.561,26.993
				c0,0-16.477,25.103,14.203,22.049c0,0,5.413,28.489,26.468,4.498c0,0,23.054,26.49,37.424-3.999
				C295.061,65.035,313.554,89.525,326.05,62.036z" />
				<path fill="none" stroke="#1B1464" strokeWidth={3} strokeMiterlimit={10} d="M333.546,41.74c0-5.231,11.332,0.803,6.873,8.226
				c-4.458,7.423-18.388,4.715-18.471-2.146c-0.29-23.615,33.61-20.242,27.843,5.914c-5.769,26.155-24.241,8.302-24.241,8.302
				s-9.547,27.221-30.489,2.999c0,0-12.146,29.71-37.424,3.999c-25.277-25.712,14.85-35.879,22.327-24.242
				c7.278,11.327-2.409,21.863-12.943,18.84C256,60.47,262.322,44.28,271.342,49.967" />
				<path fill="none" stroke="#1B1464" strokeWidth={3} strokeMiterlimit={10} d="M325.55,62.036c0.5,0-12.127,26.164-30.489,2.999
				c-18.362-23.164,7.715-33.952,15.252-23.294c7.536,10.658-5.195,20.529-10.397,15.469c-7.625-7.417,2.899-11.827,3.801-10.625" />
			</g>
			<g>
				<path fill="none" stroke="#1B1464" strokeWidth={3} strokeMiterlimit={10} d="M572.943,30.774c0,0-16.461,12.859,4.13,20.356
				c0,0,7.257,18.879,21.568,9.362c0,0,16.67,14.077,28.363-2.753c0,0-1.104,19.459,41.854-6.609
				c28.097-17.05,32.432-4.403,32.676-0.734c0.368,5.507-8.995,15.879-16.522,2.937c0,0,0.187,12.289,13.035,11.565
				c19.551-1.102,28.027-15.97,20.889-26.265c-21.749-31.372-53.381-16.691-53.381-16.691s-14.319-31.392-38.551-5.508
				c0,0-16.397-20.552-28.165-0.157C598.841,16.277,570.602,0.478,572.943,30.774z" />
				<path fill="none" stroke="#1B1464" strokeWidth={3} strokeMiterlimit={10} d="M627.006,16.435c0,0,22.027-26.251,38.551,5.508
				c16.521,31.759-28.639,29.91-30.106,15.597s12.42-23.065,19.028-12.712c6.609,10.352-5.404,11.808-7.241,10.712" />
				<path fill="none" stroke="#1B1464" strokeWidth={3} strokeMiterlimit={10} d="M577.073,51.13c0,0,6.862,18.642,21.568,9.362
				c24.505-15.46-10.045-30.343-12.797-17.657c-2.754,12.701,10.396,13.447,10.396,6.387" />
			</g>
			<g>
				<path fill="none" stroke="#1B1464" strokeWidth={3} strokeMiterlimit={10} d="M420.604,19.787c0,0-36.023-26.849-35.845,16.493
				c0,0-29.51,12.215-0.179,28.045c0,0,8.064,23.832,24.152,1.923c2.564,2.367,22.247,18.441,30.823-5.667
				c0,0,22.59,13.977,21.078-12.403c0,0,27.456-24.991-11.049-28.391C449.585,19.787,436.1-8.543,420.604,19.787z" />
				<path fill="none" stroke="#1B1464" strokeWidth={3} strokeMiterlimit={10} d="M408.732,66.248c0,0-13.329,21.839-24.152-1.923
				c-10.822-23.762,25.96-22.972,19.086-3.808c-3.811,10.625-14.167,0.775-12.156-2.519" />
				<path fill="none" stroke="#1B1464" strokeWidth={3} strokeMiterlimit={10} d="M439.556,60.581c0,0,22.352,14.336,21.078-12.403
				c-1.272-26.739-30.446-9.004-21.078,0c9.37,9.004,12.015-0.061,11.638-3.461" />
				<path fill="none" stroke="#1B1464" strokeWidth={3} strokeMiterlimit={10} d="M449.585,19.787c0,0-16.225-28.38-28.981,0
				c-8.319,18.509,26.49,16.355,22.033,4.962c-5.932-15.161-15.831-0.94-10.921,0.193" />
			</g>
			</g>
			<g>
			<path fill="#1B1464" stroke="#1B1464" strokeMiterlimit={10} d="M152.613,250.5c0,0,3.074-85.509,6.148-92.584
			c3.074-7.074,8.197-45.261,8.197-45.261s5.124-42.688,9.222-49.592c4.098-6.904,9.222-25.527,47.134-10.981
			c0,0,10.247,13.506,10.247,22.268c0,8.762,10.247,52.628,8.197,70.564c0,0,12.296,72.587,6.148,105.587L152.613,250.5
			L152.613,250.5z" />
			<path fill="#1B1464" stroke="#1B1464" strokeMiterlimit={10} d="M-4.093,263.5c0,0-5.759-85.092,1.722-94s2.98-63.339,7.48-64.623
			s19.499-23.305,31.499-20.544C48.608,87.094,80.105,73,80.105,73s46.498,1.959,46.498,6.527c0,4.568,16.499,63.383,17.999,88.723
			s6,61.259,6,61.259s7.5,23.405,6,30.491L-4.093,263.5z" />
			<path fill="#1B1464" stroke="#1B1464" strokeMiterlimit={10} d="M919.588,249c0,0-8.999-88.641,0-117.273
			c0,0,7.172-36.761,9-58.393C930.412,51.74,927,51,929.334,37c0,0,7.244-12.5,30.333-9.333c6.29,0.863,10.919,12.43,10.919,12.43
			s20.499,109.152,20.499,130.322c0,0,8.08,58.581,8,78.581C999.054,256.693,919.588,249,919.588,249z" />
			<path fill="#1B1464" stroke="#1B1464" strokeMiterlimit={10} d="M281.086,305c0,0,8.999-88.641,0-117.273
			c0,0-7.172-36.761-9-58.393c-1.824-21.594-5.103-18.324-9.752-34.333c0,0-6.522-13.741-21.327-11.333
			c-6.267,1.019-10.919,12.43-10.919,12.43S209.589,205.25,209.589,226.42c0,0-8.08,58.581-8,78.581
			C201.62,312.694,281.086,305,281.086,305z" />
			<path fill="#1B1464" stroke="#1B1464" strokeMiterlimit={10} d="M284.77,249c0,0,2.333-80.504,8.332-97.456s4.5-72.157,7.5-76.803
			S320.1,61.25,329.1,65.673c9,4.423,9,47.068,12,51.994s2.79,62.802,4,78.235c2.25,28.686,3.25,53.599,3.25,53.599L284.77,249z" />
			</g>
			<g>
			<g>
				<path fill="#F9A2E8" d="M723.5,199.333c0,0-10.833,9.333-22.833-4c0,0-17.333,4.668-20-10.666c0,0-17.333-20.815,12.667-28.741
				c0,0,26.999-17.15,30.166,9.463c0,0,17.166,4.612,12.5,17.278C736,182.667,740.02,198.15,723.5,199.333z" />
				<path fill="#F9BBF0" d="M649.667,193.333c0,0-18-5.226-15.333-17.944c0,0-10.271-12.551,7.531-18.97
				c0,0,17.137-20.247,22.469,4.222c0,0,14.154-0.531,7.744,14.748c0,0,8.256,10.61-6.411,17.944
				C665.667,193.333,657.667,203.333,649.667,193.333z" />
				<path fill="#F9A2E8" d="M645,139.727c0,0-12.666-20.393,4.667-26.393c0,0,15.778-21.334,29.223,3.333
				c0,0,16.777,10.753,4.777,21.376c0,0-2.14,15.795-19.403,11.876C664.264,149.919,645,152.787,645,139.727z" />
				<path fill="#F7C4F0" d="M713.264,82.333c0,0-9.158-19.333-26.877,0c0,0-27.053,6.022-15.72,26.011c0,0-2.562,16.155,15.72,15.405
				c0,0,10.808,20.583,26.877,4.917c0,0,15.069,5,16.236-11c0,0,22.244-2.667,13.705-17.667c0,0,4.914-15.667-13.812-15
				C729.393,85,722.527,73.667,713.264,82.333z" />
				<path fill="#F7BCEE" d="M845.667,56.454c0,0-7.333-9.639-15.333,1.288c0,0-12.668,1.121-9.334,11.69c0,0-11.333,18.235,10,18.235
				c0,0,7.334,11.2,18.667,1.101c0,0,16,3.9,12.667-12.101c0,0,6.666-4.445-1.334-11.999C861,64.667,859,50.906,845.667,56.454z" />
				<path fill="#F9A2E8" d="M920,153.544c0,0,24.667-0.894,13.823-22.889c0,0,9.511-18.489-13.822-23.51c0,0-12-26.268-29.334-6.145
				c0,0-34.667-11.469-25.333,21.605c0,0-22.667,22.895,6.666,31.145c0,0,4.667,22.639,25,8.25C897,162,918.667,172.963,920,153.544z
				" />
				<path fill="#F9A2E8" d="M885.668,98.333c0,0,0.666-15.333-15.099-14.985c0,0-12.938-14.348-26.901-3.015
				c0,0-20.001,0.42-15.334,20.544c0,0-14,16.123,10,23.457c0,0,10,14.665,26.667,2.333c0,0,18,5,18-10.254
				C883.001,116.413,896.334,109,885.668,98.333z" />
				<path fill="#F9A2E8" d="M828,62.333c0,0-2-18-22.666-14.666c0,0-14-22.656-34.667,4.005c0,0-26,3.993-14,27.994
				c0,0-12,14.876,10,19.772c0,0,10.733,16.438,24.033,0c0,0,22.633,9.761,25.3-7.671c0,0,15.333-2.769,12-12.101
				C828,79.667,838.666,70.333,828,62.333z" />
				<path fill="#F9A2E8" d="M770,62.757c0,0,9.334-13.756-10.666-16.424c0,0-2.667-18.646-18.814-7.999
				c0,0-15.853-10.647-21.186,7.999c0,0-14.668,1.332-7.334,17.667c0,0-10.666,12.307,3.334,18.987c0,0,6.666,14.178,16.666,4.763
				c0,0,11.333,13.416,22,0c0,0,14.667,1.25,11.334-10.083C765.334,77.667,778.666,75.847,770,62.757z" />
				<path fill="#F7BAEE" d="M803.667,149.716c0,0,14.667-15.382-6.667-20.188c0,0-2-22.526-20-9.86c0,0-16.666-20-27.333,3.333
				c0,0-22,0-8,19.54c0,0-16.667,12.337,3.333,20.46c0,0,6.667,20.002,22.667,3.334c0,0,18,12,23.333-3.901
				C791,162.432,807.67,169.271,803.667,149.716z" />
				<path fill="#F9A2E8" d="M840.667,129.673c0,0,14,3.047,8.667-12.146c0,0,9.333-11.761-8.667-17.477c0,0-9.334-12.951-20.667,0
				c0,0-18-7.501-18.666,12.058c0,0-12,5.559,0,12.893c0,0,2.666,9.875,12.666,7.604c0,0,5.334,10.272,13.334,2.848
				C827.334,135.454,839.334,141.011,840.667,129.673z" />
				<path fill="#F9A2E8" d="M761.75,113.75c0,0,12.25-5.25,5.25-13.318c0,0,4.25-11.932-6-12.432c0,0-6.75-10-16-1.25
				c0,0-14-5.75-10.666,11.595c0,0-12.38,8.238,0.166,14.155c0,0,3.084,10.499,13.75,5.5C748.25,118,759,124.75,761.75,113.75z" />
				<path fill="#F9B7EF" d="M842.668,192.975c0,0-19.334,0.691-13.334-18.641c0,0-11.82-15.332,6.09-18.666
				c0,0,3.91-16.667,25.91-5.333c0,0,20-8.667,20,8.667c0,0,16.667,13.344-1.333,22.672c0,0,2.667,13.081-9.333,13.204
				C870.668,194.877,851.335,208.282,842.668,192.975z" />
			</g>
			<g>
				<g>
				<g>
					<path fill="#1B1464" d="M849.25,147.5c0,0,1.048-0.902,2.97-2.359c0.966-0.72,2.132-1.609,3.517-2.556
						c1.372-0.966,2.933-2.04,4.679-3.141c1.735-1.122,3.64-2.302,5.688-3.494c2.062-1.173,4.246-2.394,6.57-3.553
						c1.146-0.61,2.354-1.155,3.567-1.721c1.205-0.583,2.46-1.118,3.735-1.63c1.263-0.538,2.564-1.041,3.898-1.486
						c0.667-0.224,1.316-0.479,2.001-0.678l2.061-0.588c1.352-0.425,2.829-0.634,4.252-0.917c0.695-0.171,1.497-0.154,2.24-0.22
						l1.131-0.067l0.282-0.016l0.142-0.007l0.223,0.007l0.504,0.029c0.671,0.038,1.34,0.076,2.005,0.114
						c0.693,0.071,1.424,0.182,2.126,0.272c1.444,0.161,2.712,0.481,4.032,0.738c2.576,0.618,5.02,1.328,7.274,2.139
						c2.27,0.776,4.347,1.666,6.246,2.508c1.905,0.826,3.604,1.688,5.098,2.466c1.497,0.769,2.766,1.511,3.808,2.121
						c2.078,1.231,3.202,2.04,3.202,2.04s-1.312-0.412-3.607-1.131c-1.144-0.349-2.531-0.772-4.125-1.258
						c-1.592-0.457-3.387-0.973-5.345-1.536c-0.979-0.266-1.997-0.551-3.06-0.799c-1.058-0.272-2.146-0.551-3.271-0.797
						c-2.242-0.542-4.617-0.987-7.058-1.398c-1.212-0.127-2.491-0.384-3.677-0.441c-0.609-0.045-1.194-0.111-1.828-0.144
						c-0.667-0.016-1.336-0.032-2.008-0.048l-0.506,0.021l-0.062,0.03h-0.031c-0.096,0,0.094-0.031,0.064-0.03l-0.111-0.005
						l-0.223,0.015l-0.891,0.085l-0.893,0.079c-0.294,0.009-0.6,0.054-0.912,0.129c-1.239,0.27-2.477,0.442-3.733,0.85l-1.885,0.515
						c-0.628,0.174-1.252,0.412-1.876,0.614l-1.873,0.619c-0.62,0.229-1.238,0.458-1.854,0.687c-2.467,0.897-4.88,1.889-7.215,2.855
						c-2.337,0.969-4.584,1.954-6.724,2.88c-2.138,0.945-4.158,1.839-6.023,2.664c-3.746,1.67-6.868,3.062-9.055,4.036
						C850.526,146.966,849.25,147.5,849.25,147.5z" />
				</g>
				<g>
					<path fill="#1B1464" d="M735.411,147.5c0,0-0.421,0.019-1.211,0.054c-0.789,0.025-1.947,0.099-3.415,0.13
						c-1.468,0.042-3.254,0.139-5.294,0.173c-2.041,0.046-4.345,0.168-6.853,0.203c-2.505,0.076-5.218,0.158-8.088,0.244
						c-2.868,0.034-5.884,0.268-9.003,0.341c-3.123,0.06-6.325,0.353-9.591,0.479c-0.815,0.046-1.637,0.067-2.454,0.134
						c-0.814,0.071-1.634,0.143-2.454,0.215c-1.64,0.136-3.286,0.272-4.933,0.408l-1.235,0.092l-0.077,0.005l-0.039,0.003
						c0.229-0.03,0.041-0.005,0.082-0.01l-0.154,0.023l-0.312,0.046l-0.621,0.093c-0.396,0.068-0.862,0.11-1.203,0.199l-1.005,0.262
						c-0.661,0.123-1.373,0.459-2.063,0.668c-0.683,0.274-1.351,0.586-2.007,0.91c-2.593,1.391-4.896,3.299-6.857,5.467
						c-1.949,2.185-3.6,4.582-4.982,6.983c-1.362,2.415-2.52,4.81-3.484,7.076c-1.904,4.549-3.157,8.532-3.972,11.341
						C653.381,185.854,653,187.5,653,187.5s-0.014-0.422,0.007-1.214c0.028-0.79,0.056-1.956,0.199-3.429
						c0.138-1.475,0.331-3.269,0.696-5.307c0.351-2.041,0.823-4.34,1.545-6.806c0.703-2.47,1.605-5.128,2.877-7.833
						c1.258-2.706,2.808-5.516,4.912-8.137c2.1-2.608,4.734-5.065,7.928-6.866c0.805-0.429,1.632-0.841,2.479-1.207
						c0.884-0.313,1.689-0.694,2.668-0.91l1.423-0.361c0.47-0.113,0.841-0.145,1.268-0.221l0.619-0.098l0.311-0.049l0.154-0.025
						l0.199-0.024l0.079-0.007l1.269-0.104c1.693-0.125,3.387-0.25,5.073-0.375c1.686-0.142,3.366-0.229,5.029-0.244
						c1.662-0.03,3.312-0.059,4.943-0.088c0.815-0.011,1.625-0.021,2.43-0.032c0.804,0.019,1.601,0.039,2.391,0.057
						c3.161,0.086,6.218,0.15,9.104,0.362c2.891,0.176,5.619,0.347,8.132,0.618c5.028,0.46,9.199,1.043,12.112,1.494
						C733.761,147.157,735.411,147.5,735.411,147.5z" />
				</g>
				<g>
					<path fill="#1B1464" d="M764.602,169.963c0,0-1.109-0.326-3.047-0.768c-0.968-0.223-2.146-0.463-3.489-0.7
						c-1.345-0.248-2.871-0.437-4.52-0.621c-0.824-0.088-1.686-0.14-2.569-0.214c-0.889-0.012-1.8-0.09-2.735-0.076
						c-0.937,0.038-1.894-0.021-2.863,0.081c-0.483,0.03-0.975,0.062-1.469,0.095c-0.493,0.038-0.985,0.12-1.483,0.18
						c-1.995,0.218-3.997,0.692-5.992,1.248c-0.567,0.146-1.139,0.295-1.711,0.442l-1.643,0.412
						c-1.208,0.199-2.446,0.411-3.656,0.578c-1.179,0.097-2.359,0.206-3.531,0.28c-1.168,0.041-2.331,0.06-3.482,0.072
						c-1.147-0.021-2.284-0.054-3.406-0.099c-1.116-0.081-2.219-0.161-3.303-0.239c-4.322-0.398-8.298-1.086-11.666-1.824
						c-1.688-0.359-3.216-0.77-4.568-1.132c-1.354-0.356-2.521-0.727-3.479-1.032c-0.961-0.297-1.703-0.582-2.212-0.762
						c-0.505-0.193-0.774-0.298-0.774-0.298s1.157,0.015,3.156,0.078c1.989,0.065,4.831,0.157,8.241,0.271
						c1.693,0.065,3.527,0.119,5.461,0.166c1.932,0.061,3.964,0.078,6.057,0.104c1.045-0.002,2.106-0.004,3.182-0.006
						c1.069-0.037,2.15-0.063,3.24-0.076c1.081-0.058,2.171-0.114,3.266-0.172c1.08-0.101,2.165-0.198,3.25-0.298
						c1.024-0.145,2.052-0.287,3.073-0.432l1.652-0.367c0.523-0.112,1.045-0.226,1.564-0.336c1.174-0.242,2.321-0.58,3.489-0.681
						c0.58-0.073,1.156-0.146,1.729-0.217c0.571-0.077,1.145-0.06,1.708-0.093c0.564-0.014,1.123-0.053,1.676-0.045
						c0.551,0.021,1.099,0.043,1.639,0.064c1.079,0.023,2.122,0.182,3.138,0.294c1.012,0.138,1.981,0.339,2.92,0.513
						c0.926,0.239,1.825,0.432,2.669,0.688c1.689,0.501,3.199,1.061,4.502,1.626c1.314,0.531,2.407,1.12,3.298,1.601
						c0.896,0.475,1.56,0.924,2.014,1.213C764.375,169.785,764.602,169.963,764.602,169.963z" />
				</g>
				<g>
					<path fill="#1B1464" d="M721,168.559c0,0-0.209,0.422-0.634,1.077c-0.42,0.664-1.056,1.565-1.883,2.556
						c-0.826,0.992-1.845,2.078-2.999,3.151c-0.579,0.537-1.188,1.077-1.804,1.612c-0.612,0.542-1.266,1.054-1.915,1.568
						c-0.664,0.494-1.324,0.996-2.006,1.449c-0.67,0.467-1.355,0.892-2.027,1.295c-1.346,0.803-2.666,1.474-3.851,1.987
						c-1.183,0.519-2.229,0.878-2.991,1.099c-0.755,0.228-1.225,0.312-1.225,0.312s0.192-0.423,0.598-1.086
						c0.397-0.671,1.008-1.583,1.812-2.592c0.804-1.012,1.801-2.123,2.939-3.229c0.571-0.552,1.169-1.113,1.804-1.646
						c0.624-0.549,1.289-1.065,1.948-1.59c0.677-0.502,1.35-1.008,2.039-1.469c0.681-0.478,1.376-0.896,2.064-1.281
						c1.371-0.777,2.712-1.422,3.906-1.91c1.192-0.492,2.243-0.826,3.002-1.026C720.535,168.63,721,168.559,721,168.559z" />
				</g>
				<g>
					<path fill="#1B1464" d="M773.421,176.611c0,0-0.258-0.545-0.592-1.51c-0.347-0.967-0.781-2.356-1.165-4.033
						c-0.403-1.676-0.742-3.645-0.998-5.76c-0.269-2.113-0.373-4.384-0.431-6.649c-0.001-1.136,0.022-2.273,0.08-3.392
						c0.044-1.119,0.124-2.218,0.229-3.281c0.257-2.132,0.603-4.113,0.977-5.796c0.443-1.689,0.913-3.074,1.28-4.031
						c0.357-0.958,0.62-1.493,0.62-1.493s0.171,0.586,0.369,1.589c0.21,1.004,0.445,2.421,0.623,4.095
						c0.253,1.688,0.457,3.641,0.564,5.722c0.073,1.045,0.157,2.125,0.188,3.22c0.055,1.097,0.04,2.206,0.068,3.319
						c0.006,1.115,0.018,2.229-0.02,3.328c-0.023,1.1-0.08,2.182-0.131,3.231c-0.11,2.099-0.319,4.063-0.54,5.759
						c-0.232,1.692-0.501,3.108-0.729,4.106C773.595,176.035,773.421,176.611,773.421,176.611z" />
				</g>
				<g>
					<path fill="#1B1464" d="M743.987,154.419c0,0-0.754-0.901-1.97-2.546c-1.219-1.642-2.894-4.027-4.791-6.96
						c-0.94-1.47-1.938-3.078-2.955-4.8c-1.011-1.727-2.064-3.555-3.086-5.488c-0.523-0.959-1.036-1.945-1.537-2.956
						c-0.507-1.008-1.028-2.021-1.503-3.075c-0.989-2.085-1.924-4.241-2.777-6.467c-0.423-1.11-0.826-2.237-1.175-3.409
						c-0.18-0.583-0.381-1.137-0.502-1.771l-0.207-0.922l-0.103-0.46l-0.051-0.229l-0.025-0.115l-0.013-0.057l-0.006-0.029
						l-0.029-0.178l-0.087-0.847c-0.02-0.114-0.021-0.343-0.021-0.542l0.002-0.607c-0.003-0.425,0.057-0.756,0.104-1.107
						c0.044-0.352,0.101-0.697,0.191-1.024c0.304-1.344,0.818-2.548,1.427-3.621c1.194-2.173,2.72-3.86,4.212-5.336
						c1.512-1.461,3.052-2.66,4.51-3.693c2.933-2.045,5.548-3.402,7.41-4.271c1.863-0.87,2.981-1.24,2.981-1.24
						s-0.889,0.754-2.441,2.075c-1.528,1.332-3.703,3.23-6.081,5.65c-1.188,1.207-2.432,2.542-3.599,4.017
						c-1.154,1.473-2.282,3.087-3.034,4.797c-0.375,0.853-0.681,1.719-0.805,2.566c-0.051,0.213-0.064,0.422-0.074,0.63
						c-0.014,0.206-0.042,0.432-0.019,0.572l0.002,0.24c0,0.083-0.016,0.138,0.016,0.304l0.121,0.843
						c0.006,0.042-0.041-0.267-0.019-0.125l0.006,0.023l0.014,0.046l0.024,0.093l0.051,0.186l0.1,0.372l0.198,0.745
						c0.111,0.489,0.311,1.015,0.475,1.531c0.176,0.518,0.331,1.035,0.521,1.556l0.599,1.567c0.776,2.1,1.666,4.195,2.527,6.266
						c0.886,2.072,1.757,4.11,2.6,6.083c1.704,3.952,3.288,7.666,4.614,10.872c0.67,1.6,1.248,3.088,1.774,4.4
						c0.534,1.309,0.965,2.47,1.328,3.422C743.611,153.305,743.987,154.419,743.987,154.419z" />
				</g>
				<g>
					<path fill="#1B1464" d="M701.607,116.667c0,0-0.398,0.217-1.084,0.509c-0.687,0.299-1.662,0.681-2.825,1.04
						c-1.164,0.363-2.516,0.717-3.958,1.012c-1.441,0.305-2.979,0.541-4.505,0.75c-0.771,0.077-1.53,0.183-2.283,0.249
						c-0.757,0.07-1.492,0.134-2.213,0.18c-0.723,0.039-1.418,0.077-2.079,0.113c-0.677,0.005-1.285,0.065-1.883,0.065
						c-0.604-0.009-1.124,0.041-1.622,0.042s-0.939-0.006-1.294,0.025c-0.726,0.034-1.194,0.015-1.194,0.015s0.249-0.359,0.782-0.92
						c0.264-0.29,0.603-0.619,1.022-0.928c0.418-0.317,0.904-0.699,1.458-1.005c0.555-0.31,1.168-0.677,1.831-0.95
						c0.663-0.279,1.375-0.59,2.118-0.808c0.744-0.233,1.521-0.47,2.313-0.62c0.791-0.187,1.604-0.308,2.413-0.417
						c0.814-0.097,1.627-0.171,2.431-0.197c0.802-0.049,1.592-0.025,2.356,0c1.529,0.065,2.957,0.255,4.177,0.514
						c1.219,0.256,2.229,0.577,2.935,0.853C701.211,116.45,701.607,116.667,701.607,116.667z" />
				</g>
				<g>
					<path fill="#1B1464" d="M680.5,147.5c0,0-0.088,0.046-0.252,0.132c-0.164,0.065-0.419,0.173-0.763,0.256
						c-0.358,0.102-0.741,0.157-1.222,0.215c-0.236,0.031-0.488,0.046-0.76,0.067c-0.258,0.003-0.526-0.013-0.812-0.021
						c-0.562-0.035-1.197-0.064-1.828-0.18c-0.62-0.129-1.275-0.282-1.943-0.476c-0.683-0.174-1.342-0.428-1.985-0.732
						c-0.655-0.284-1.302-0.618-1.948-0.946c-0.65-0.335-1.229-0.749-1.807-1.162c-0.579-0.405-1.124-0.841-1.644-1.271
						c-0.518-0.433-1.012-0.858-1.422-1.331c-0.419-0.462-0.8-0.917-1.141-1.351c-0.678-0.868-1.179-1.661-1.5-2.248
						C661.148,137.875,661,137.5,661,137.5s0.414,0.029,1.07,0.156c0.663,0.119,1.567,0.335,2.577,0.678
						c0.504,0.172,1.035,0.373,1.584,0.604c0.536,0.25,1.129,0.455,1.708,0.717c0.58,0.257,1.174,0.531,1.75,0.848
						c0.59,0.291,1.145,0.679,1.74,0.953c0.574,0.32,1.152,0.635,1.699,0.989c0.279,0.167,0.548,0.349,0.812,0.533
						c0.27,0.165,0.534,0.327,0.797,0.487c0.521,0.323,1.018,0.668,1.487,1.013c0.471,0.334,0.922,0.611,1.337,0.923
						c0.416,0.321,0.8,0.6,1.146,0.833c0.346,0.232,0.666,0.521,0.913,0.674c0.257,0.161,0.473,0.345,0.631,0.438
						C680.415,147.446,680.5,147.5,680.5,147.5z" />
				</g>
				<g>
					<path fill="#1B1464" d="M886.334,129.335c0,0,0.183-0.544,0.543-1.438c0.354-0.9,0.88-2.153,1.545-3.604
						c0.66-1.454,1.464-3.105,2.319-4.846c0.856-1.741,1.773-3.567,2.647-5.389c0.45-0.905,0.863-1.821,1.278-2.713
						c0.392-0.901,0.799-1.778,1.142-2.639c0.36-0.859,0.681-1.695,0.956-2.498c0.288-0.802,0.53-1.571,0.724-2.293
						c0.12-0.363,0.185-0.711,0.266-1.048c0.092-0.338,0.154-0.662,0.204-0.968c0.126-0.618,0.198-1.168,0.252-1.636
						c0.056-0.467,0.065-0.841,0.088-1.104c0.023-0.267,0.036-0.409,0.036-0.409s0.083,0.114,0.239,0.328
						c0.133,0.222,0.409,0.537,0.612,0.989c0.22,0.448,0.504,1.007,0.698,1.687c0.123,0.338,0.208,0.704,0.289,1.093
						c0.087,0.39,0.164,0.806,0.208,1.238c0.108,0.869,0.142,1.819,0.086,2.812c-0.054,0.994-0.18,2.037-0.41,3.082
						c-0.213,1.053-0.513,2.113-0.884,3.161c-0.356,1.053-0.811,2.08-1.282,3.092c-0.503,0.995-1.017,1.982-1.599,2.903
						c-0.561,0.935-1.174,1.811-1.791,2.638c-1.232,1.655-2.54,3.072-3.741,4.208c-1.198,1.137-2.301,1.985-3.11,2.537
						C886.846,129.074,886.334,129.335,886.334,129.335z" />
				</g>
				<g>
					<path fill="#1B1464" d="M871.941,135.171c0,0,0.577,0.087,1.533,0.354c0.961,0.258,2.305,0.692,3.844,1.352
						c1.54,0.655,3.279,1.531,5.057,2.618c0.891,0.541,1.789,1.137,2.676,1.78c0.894,0.639,1.764,1.338,2.621,2.058
						c0.841,0.742,1.68,1.49,2.445,2.301c0.785,0.787,1.502,1.631,2.165,2.474c0.665,0.842,1.249,1.715,1.766,2.568
						c0.249,0.436,0.482,0.866,0.7,1.282c0.194,0.435,0.383,0.853,0.562,1.255c0.147,0.42,0.288,0.821,0.423,1.203
						c0.104,0.398,0.201,0.771,0.277,1.122c0.129,0.721,0.191,1.347,0.177,1.87c0.028,0.51-0.069,0.921-0.107,1.187
						C896.028,158.859,896,159,896,159s-0.071-0.127-0.204-0.364c-0.119-0.235-0.305-0.565-0.567-0.963
						c-0.232-0.401-0.604-0.846-0.979-1.345c-0.203-0.241-0.419-0.497-0.647-0.768c-0.236-0.26-0.486-0.531-0.748-0.814
						c-0.539-0.557-1.125-1.143-1.768-1.747c-0.645-0.604-1.33-1.231-2.054-1.874c-1.444-1.285-3.021-2.638-4.613-3.998
						c-3.173-2.731-6.389-5.534-8.703-7.854c-1.163-1.15-2.109-2.166-2.761-2.908C872.299,135.632,871.941,135.171,871.941,135.171z" />
				</g>
				<g>
					<path fill="#1B1464" d="M829.936,173.389c0,0,0.403-0.521,1.209-1.318c0.802-0.805,2.006-1.881,3.563-3.016
						c1.557-1.137,3.471-2.324,5.638-3.392c2.163-1.072,4.591-2,7.108-2.679c2.52-0.666,5.134-1.073,7.617-1.11
						c1.24-0.021,2.443,0.041,3.577,0.179c1.135,0.137,2.196,0.361,3.156,0.615c0.476,0.148,0.931,0.293,1.362,0.43
						c0.425,0.164,0.823,0.325,1.192,0.485c0.741,0.301,1.343,0.696,1.841,0.961c0.491,0.295,0.847,0.589,1.096,0.759
						c0.241,0.187,0.37,0.285,0.37,0.285s-0.672-0.016-1.761-0.094c-0.559,0.002-1.21-0.044-1.959-0.023
						c-0.744-0.005-1.574,0.026-2.469,0.077c-1.79,0.115-3.831,0.356-5.997,0.741c-0.539,0.104-1.089,0.206-1.646,0.312
						c-0.554,0.125-1.114,0.246-1.681,0.361c-1.127,0.275-2.278,0.527-3.419,0.854c-1.151,0.281-2.29,0.633-3.424,0.949
						c-1.133,0.342-2.247,0.678-3.325,1.002c-2.188,0.683-4.23,1.316-5.98,1.861c-1.771,0.551-3.285,0.994-4.362,1.293
						C830.575,173.23,829.936,173.389,829.936,173.389z" />
				</g>
				<g>
					<path fill="#1B1464" d="M823.667,159c0,0-1.155-1.497-3.043-4.208c-1.885-2.713-4.532-6.621-7.435-11.497
						c-1.457-2.435-3.005-5.099-4.479-8.03c-0.742-1.466-1.485-2.987-2.185-4.603c-0.695-1.638-1.358-3.285-1.736-5.441
						c-0.067-0.578-0.141-1.187,0-2.044c0.011-0.158,0.062-0.422,0.092-0.628l0.031-0.452c0.041-0.747,0.044-1.607,0.017-2.463
						c-0.054-1.724-0.198-3.518-0.353-5.323c-0.34-3.613-0.781-7.305-1.318-10.974c-0.518-3.668-1.125-7.339-1.87-10.842
						c-0.191-0.87-0.392-1.735-0.621-2.553c-0.216-0.798-0.476-1.654-0.717-2.167c-0.046-0.094-0.126-0.244-0.062-0.148l0.018,0.009
						l0.01,0.003c0.062,0.056-0.125-0.121,0.144,0.125l-0.128-0.111c-0.073-0.059-0.198-0.18-0.229-0.188
						c-0.041-0.021-0.08-0.045-0.117-0.071c-0.03-0.023-0.067-0.054-0.169-0.112l-0.498-0.303c-0.711-0.402-1.458-0.811-2.221-1.195
						c-3.064-1.528-6.146-2.945-9.078-4.225c-2.942-1.265-5.735-2.457-8.331-3.515c-5.195-2.095-9.573-3.742-12.707-4.646
						c-1.565-0.442-2.816-0.736-3.684-0.794c-0.427-0.016-0.773-0.051-0.988,0.006c-0.226,0.038-0.342,0.057-0.342,0.057
						s0.109-0.044,0.322-0.13c0.214-0.106,0.556-0.154,1.002-0.221c0.896-0.103,2.226-0.094,3.883,0.106
						c3.32,0.371,7.983,1.341,13.446,2.907c2.734,0.771,5.682,1.669,8.754,2.752c3.082,1.07,6.31,2.23,9.623,3.696
						c0.83,0.366,1.672,0.751,2.532,1.204l0.666,0.37c0.096,0.05,0.245,0.144,0.4,0.246l0.444,0.302
						c0.162,0.112,0.197,0.152,0.302,0.232l0.133,0.105c0.285,0.268,0.114,0.114,0.194,0.192l0.062,0.07l0.121,0.143
						c0.367,0.436,0.482,0.697,0.657,0.999c0.568,1.112,0.84,2.011,1.15,2.965c0.288,0.94,0.522,1.866,0.753,2.796
						c0.905,3.709,1.531,7.458,2.093,11.226c0.521,3.77,0.959,7.545,1.198,11.307c0.127,1.883,0.21,3.753,0.188,5.655
						c-0.009,0.956-0.041,1.899-0.143,2.935l-0.12,0.878c-0.047,0.232-0.093,0.403-0.141,0.685c0.003,0.022-0.002,0.043-0.01,0.064
						l-0.017,0.032l0.003,0.095c0.011,0.137-0.009,0.242,0.011,0.407c0.142,1.335,0.662,2.973,1.174,4.496
						c0.53,1.543,1.128,3.058,1.734,4.526c1.239,2.929,2.466,5.707,3.633,8.258C820.488,152.186,823.667,159,823.667,159z" />
				</g>
				<g>
					<path fill="#1B1464" d="M807.105,115.727c0,0,0.089-0.249,0.256-0.715c0.182-0.459,0.42-1.149,0.782-1.993
						c0.698-1.705,1.765-4.104,3.188-6.915c0.719-1.401,1.525-2.905,2.432-4.473c0.916-1.563,1.898-3.203,3.045-4.851
						c1.154-1.66,2.318-3.331,4.065-5.004c0.184-0.204,0.47-0.427,0.761-0.644l0.434-0.321l0.218-0.158l0.109-0.078l0.056-0.039
						l0.176-0.093c0.484-0.236,0.848-0.463,1.489-0.697c0.589-0.235,1.194-0.467,1.679-0.619l1.531-0.503
						c4.038-1.212,7.908-2.277,11.477-3.534c1.782-0.618,3.479-1.27,5.061-1.953c0.812-0.306,1.543-0.703,2.284-1.038
						c0.753-0.322,1.436-0.701,2.099-1.06c1.361-0.675,2.512-1.44,3.594-2.069c1.025-0.716,1.919-1.34,2.655-1.854
						c1.391-1.142,2.258-1.785,2.258-1.785s-0.516,0.907-1.544,2.477c-0.558,0.741-1.261,1.638-2.131,2.62
						c-0.923,0.915-1.936,2.027-3.214,3.015c-0.621,0.523-1.284,1.05-2.006,1.537c-0.718,0.495-1.453,1.033-2.254,1.494
						c-1.582,0.97-3.309,1.896-5.141,2.726c-0.911,0.428-1.852,0.829-2.811,1.199c-0.954,0.397-1.931,0.746-2.916,1.089
						c-1.96,0.697-3.991,1.297-5.931,1.908l-1.433,0.448c-0.51,0.154-0.869,0.296-1.248,0.428c-0.342,0.092-0.88,0.38-1.33,0.573
						l-0.021,0.01c0.053-0.028-0.146,0.077,0.11-0.061l-0.022,0.018l-0.046,0.036l-0.094,0.07l-0.188,0.133
						c-0.126,0.083-0.259,0.155-0.425,0.328c-0.626,0.543-1.273,1.232-1.917,1.905c-0.622,0.729-1.254,1.422-1.857,2.161
						c-1.216,1.446-2.368,2.91-3.469,4.291c-1.103,1.393-2.146,2.709-3.106,3.924c-1.94,2.433-3.584,4.463-4.767,5.868
						C807.815,114.939,807.105,115.727,807.105,115.727z" />
				</g>
				<g>
					<path fill="#1B1464" d="M820.625,95.53c0,0,0.006-0.097,0.018-0.279c0.021-0.171,0.021-0.441,0.067-0.75
						c0.058-0.639,0.172-1.51,0.34-2.506c0.177-0.991,0.393-2.114,0.681-3.266c0.118-0.586,0.297-1.167,0.441-1.765
						c0.162-0.59,0.326-1.189,0.491-1.789c0.18-0.593,0.359-1.186,0.536-1.769c0.19-0.583,0.359-1.157,0.553-1.716
						c0.195-0.562,0.384-1.106,0.562-1.623c0.205-0.52,0.392-1.017,0.558-1.484c0.379-0.932,0.718-1.739,0.984-2.321
						c0.267-0.581,0.478-0.93,0.478-0.93s0.227,0.317,0.519,0.903c0.143,0.293,0.324,0.653,0.49,1.075
						c0.16,0.421,0.287,0.902,0.423,1.435c0.061,0.268,0.121,0.547,0.186,0.836c0.044,0.289,0.069,0.587,0.105,0.897
						c0.056,0.618,0.113,1.278,0.068,1.947c-0.03,0.672-0.058,1.375-0.198,2.063c-0.102,0.699-0.266,1.396-0.465,2.082
						c-0.202,0.686-0.438,1.359-0.723,2.001c-0.254,0.653-0.603,1.256-0.922,1.84c-0.686,1.151-1.446,2.15-2.216,2.923
						c-0.752,0.781-1.497,1.339-2.056,1.691C820.996,95.385,820.625,95.53,820.625,95.53z" />
				</g>
				<g>
					<path fill="#1B1464" d="M801.667,86c0,0-0.201-0.373-0.44-1.027c-0.249-0.655-0.539-1.592-0.758-2.717
						c-0.222-1.125-0.374-2.438-0.398-3.841c-0.017-0.702-0.001-1.427,0.055-2.161c0.043-0.736,0.146-1.479,0.251-2.224
						c0.137-0.741,0.271-1.484,0.474-2.208c0.185-0.727,0.42-1.434,0.685-2.114c0.525-1.358,1.201-2.6,1.975-3.586
						c0.386-0.492,0.802-0.92,1.208-1.259c0.413-0.344,0.845-0.576,1.205-0.725c0.763-0.288,1.201-0.113,1.187-0.138
						c0,0-0.062,0.082-0.182,0.235c-0.121,0.119-0.221,0.388-0.339,0.642c-0.104,0.287-0.178,0.642-0.251,1.04
						c-0.065,0.411-0.127,0.863-0.175,1.373c-0.096,1.022-0.206,2.215-0.325,3.493c-0.07,0.641-0.131,1.312-0.229,1.988
						c-0.079,0.68-0.195,1.369-0.298,2.062c-0.452,2.769-1.153,5.559-1.965,7.657c-0.396,1.049-0.806,1.926-1.13,2.541
						C801.899,85.646,801.667,86,801.667,86z" />
				</g>
				<g>
					<path fill="#1B1464" d="M783.229,77.877c0,0-0.286-0.27-0.679-0.777c-0.4-0.506-0.911-1.25-1.393-2.181
						c-0.484-0.931-0.944-2.047-1.296-3.281c-0.18-0.616-0.331-1.261-0.444-1.924c-0.126-0.662-0.194-1.345-0.256-2.032
						c-0.032-0.689-0.064-1.382-0.022-2.071c0.021-0.688,0.104-1.37,0.22-2.031c0.228-1.321,0.639-2.56,1.18-3.565
						c0.274-0.503,0.57-0.944,0.873-1.31c0.309-0.365,0.613-0.648,0.893-0.853c0.253-0.218,0.517-0.331,0.675-0.411
						c0.161-0.078,0.252-0.108,0.252-0.108s-0.008,0.096-0.021,0.277c-0.024,0.168-0.027,0.42-0.032,0.717
						c-0.015,0.289,0.028,0.655,0.052,1.035c0.032,0.388,0.081,0.814,0.141,1.272c0.123,0.909,0.267,1.97,0.421,3.106
						c0.145,1.136,0.268,2.358,0.358,3.599c0.167,2.481,0.135,5.052-0.152,7.068c-0.133,1.006-0.315,1.869-0.478,2.486
						C783.364,77.509,783.229,77.877,783.229,77.877z" />
				</g>
				<g>
					<path fill="#1B1464" d="M756.325,149.651c0,0,0.643-1.426,1.767-3.921c1.116-2.492,2.724-6.042,4.532-10.328
						c0.922-2.134,1.884-4.459,2.828-6.935c0.976-2.46,1.952-5.07,2.794-7.792c0.839-2.714,1.629-5.545,1.993-8.335
						c0.077-0.689,0.155-1.385,0.15-2.042c0.021-0.338,0.007-0.659-0.021-0.968l-0.016-0.235l-0.007-0.118l-0.003-0.059l-0.002-0.029
						c-0.002-0.026,0.016,0.129,0.005,0.046l-0.082-0.576l-0.081-0.568l-0.1-0.458c-0.054-0.296-0.137-0.623-0.232-0.961
						c-0.173-0.665-0.39-1.331-0.646-1.99c-0.979-2.652-2.417-5.227-4.033-7.649c-1.62-2.424-3.417-4.712-5.274-6.824
						c-1.861-2.105-3.791-4.049-5.694-5.705c-0.476-0.413-0.954-0.792-1.424-1.138c-0.233-0.168-0.466-0.333-0.688-0.469
						c-0.115-0.079-0.217-0.133-0.317-0.191c-0.082-0.05-0.274-0.148-0.397-0.212c-0.543-0.278-1.143-0.597-1.703-0.93
						c-0.528-0.33-1.048-0.653-1.556-0.97c-2-1.282-3.765-2.46-5.339-3.505c-1.581-1.024-2.978-1.876-4.154-2.537
						c-1.18-0.657-2.146-1.097-2.818-1.393c-0.684-0.266-1.049-0.407-1.049-0.407s0.395,0.035,1.132,0.1
						c0.729,0.115,1.821,0.28,3.152,0.688c1.343,0.375,2.938,0.949,4.712,1.7c1.788,0.72,3.739,1.671,5.844,2.623
						c0.524,0.229,1.061,0.464,1.605,0.703c0.525,0.21,1.035,0.442,1.646,0.67l0.464,0.188c0.186,0.078,0.371,0.159,0.532,0.243
						c0.345,0.165,0.659,0.342,0.977,0.521c0.62,0.363,1.221,0.746,1.804,1.152c2.34,1.611,4.534,3.55,6.665,5.706
						c2.131,2.152,4.152,4.561,5.987,7.204c1.831,2.642,3.448,5.555,4.586,8.768c0.281,0.802,0.521,1.629,0.716,2.475
						c0.102,0.414,0.196,0.844,0.269,1.316l0.115,0.687l0.064,0.587l0.011,0.579L775,108.48v0.043l0.051,0.086l0.029,0.172
						l0.021,0.345c0.02,0.464,0.021,0.917-0.018,1.349c-0.037,0.888-0.162,1.721-0.291,2.55c-0.583,3.276-1.595,6.234-2.7,9.021
						c-1.111,2.783-2.326,5.38-3.527,7.81c-1.241,2.412-2.474,4.65-3.642,6.695c-2.374,4.07-4.52,7.347-6.076,9.604
						C757.284,148.411,756.325,149.651,756.325,149.651z" />
				</g>
				<g>
					<path fill="#1B1464" d="M756.325,83.345c0,0-0.484-0.461-1.237-1.323c-0.76-0.858-1.793-2.114-2.915-3.686
						c-1.128-1.568-2.352-3.449-3.543-5.528c-0.604-1.035-1.188-2.126-1.759-3.246c-0.587-1.111-1.107-2.281-1.651-3.437
						c-0.515-1.17-1.011-2.354-1.466-3.529c-0.461-1.176-0.854-2.362-1.232-3.506c-0.354-1.161-0.67-2.29-0.942-3.371
						c-0.241-1.1-0.464-2.13-0.617-3.104c-0.125-0.99-0.246-1.879-0.287-2.69c-0.023-0.823-0.004-1.533,0.008-2.107
						C740.84,46.616,741,46,741,46s0.284,0.645,0.662,1.649c0.252,0.506,0.568,1.109,0.883,1.793c0.35,0.68,0.744,1.447,1.175,2.283
						c0.432,0.833,0.922,1.734,1.393,2.692c0.497,0.956,1.02,1.964,1.522,3.017c0.534,1.049,1.063,2.136,1.579,3.254
						c0.548,1.107,1.039,2.257,1.569,3.395c2.047,4.58,3.86,9.293,4.974,12.969c0.562,1.834,0.965,3.401,1.212,4.518
						C756.225,82.682,756.325,83.345,756.325,83.345z" />
				</g>
				<g>
					<path fill="#1B1464" d="M804.387,103.667c0,0-0.104-0.03-0.303-0.085c-0.185-0.064-0.479-0.129-0.816-0.252
						c-0.698-0.213-1.647-0.548-2.744-0.966c-1.091-0.425-2.324-0.936-3.604-1.516c-1.283-0.573-2.61-1.226-3.938-1.871
						c-0.646-0.36-1.324-0.651-1.961-1.009c-0.641-0.352-1.285-0.66-1.895-1.013c-0.615-0.346-1.21-0.681-1.773-0.998
						c-0.567-0.339-1.104-0.66-1.601-0.956c-0.499-0.303-0.952-0.622-1.363-0.884c-0.411-0.256-0.769-0.518-1.065-0.726
						c-0.595-0.423-0.933-0.724-0.933-0.724s0.419-0.128,1.149-0.246c0.365-0.056,0.814-0.133,1.325-0.177
						c0.512-0.047,1.076-0.035,1.7-0.025c0.623,0.016,1.297,0.056,1.992,0.171c0.692,0.116,1.441,0.214,2.176,0.432
						c0.374,0.091,0.745,0.208,1.124,0.322c0.383,0.104,0.761,0.235,1.131,0.391c0.752,0.286,1.506,0.604,2.233,0.974
						c0.725,0.376,1.444,0.76,2.111,1.209c0.687,0.418,1.318,0.899,1.918,1.374c1.196,0.959,2.211,1.984,2.988,2.955
						c0.787,0.958,1.331,1.87,1.672,2.532C804.257,103.24,804.387,103.667,804.387,103.667z" />
				</g>
				<g>
					<path fill="#1B1464" d="M772.719,108.667c0,0-0.329-0.281-0.781-0.841c-0.468-0.554-1.048-1.386-1.612-2.446
						c-0.545-1.062-1.092-2.349-1.458-3.791c-0.204-0.716-0.349-1.475-0.457-2.252c-0.143-0.772-0.145-1.582-0.199-2.38
						c0.011-0.806,0.033-1.617,0.12-2.41c0.052-0.399,0.116-0.794,0.192-1.182c0.075-0.388,0.121-0.769,0.252-1.142
						c0.231-0.745,0.423-1.457,0.738-2.107c0.32-0.648,0.603-1.251,0.953-1.772c0.369-0.517,0.722-0.969,1.046-1.352
						c0.32-0.384,0.712-0.656,0.981-0.893c0.567-0.461,0.927-0.668,0.927-0.668s0.004,0.451-0.052,1.14
						c-0.02,0.349-0.079,0.742-0.091,1.206c-0.007,0.467-0.029,0.972-0.067,1.5c-0.03,0.532,0,1.124-0.034,1.709
						c-0.04,0.585,0.023,1.232-0.012,1.862c-0.017,0.637,0.044,1.312,0.008,1.976c0.032,0.677,0.021,1.362,0.035,2.05
						c0.048,2.758,0.076,5.607-0.07,7.87c-0.054,1.124-0.163,2.102-0.251,2.8C772.799,108.242,772.719,108.667,772.719,108.667z" />
				</g>
				<g>
					<path fill="#1B1464" d="M853.586,129.335c0,0-0.061-0.089-0.175-0.257c-0.101-0.167-0.281-0.402-0.461-0.707
						c-0.183-0.303-0.438-0.662-0.675-1.073c-0.244-0.406-0.521-0.863-0.819-1.362c-0.28-0.489-0.585-1.019-0.906-1.579
						c-0.292-0.562-0.655-1.127-0.957-1.732c-0.348-0.585-0.663-1.203-1.003-1.815c-0.359-0.602-0.652-1.248-1.028-1.844l-0.498-0.93
						c-0.188-0.295-0.354-0.601-0.526-0.902c-0.179-0.295-0.329-0.61-0.5-0.904c-0.178-0.289-0.357-0.572-0.516-0.867
						c-0.316-0.594-0.658-1.141-0.979-1.675c-0.304-0.551-0.602-1.075-0.907-1.541c-0.293-0.479-0.544-0.953-0.782-1.367
						c-0.258-0.392-0.465-0.77-0.645-1.078c-0.354-0.622-0.54-1.036-0.54-1.036s0.426,0.05,1.139,0.242
						c0.354,0.098,0.796,0.214,1.281,0.388c0.477,0.188,0.979,0.452,1.538,0.746c0.276,0.15,0.563,0.312,0.854,0.49
						c0.271,0.199,0.553,0.405,0.841,0.616c0.574,0.434,1.171,0.913,1.697,1.488c0.552,0.556,1.105,1.152,1.567,1.828
						c0.239,0.333,0.499,0.658,0.721,1.008l0.628,1.072c0.413,0.722,0.744,1.479,1.052,2.232c0.312,0.75,0.527,1.522,0.734,2.263
						c0.168,0.754,0.334,1.478,0.403,2.184c0.089,0.701,0.129,1.369,0.125,1.989c0.008,1.237-0.149,2.277-0.322,2.998
						C853.768,128.932,853.586,129.335,853.586,129.335z" />
				</g>
				<g>
					<path fill="#1B1464" d="M856.75,114.667c0,0-0.023-0.371,0.068-0.967c0.082-0.602,0.272-1.434,0.654-2.348
						c0.73-1.845,2.288-4.002,4.4-5.558c0.526-0.385,1.065-0.757,1.645-1.052c0.553-0.333,1.147-0.576,1.729-0.793
						c0.569-0.234,1.158-0.381,1.718-0.499c0.557-0.125,1.09-0.202,1.601-0.217c0.506-0.02,0.964-0.029,1.375,0.002
						c0.416,0.044,0.781,0.121,1.076,0.179c0.591,0.121,0.927,0.252,0.927,0.252s-0.24,0.296-0.657,0.706
						c-0.209,0.202-0.455,0.454-0.74,0.699c-0.278,0.266-0.586,0.567-0.922,0.86c-0.341,0.28-0.693,0.6-1.062,0.929
						c-0.371,0.308-0.763,0.633-1.166,0.969c-0.402,0.32-0.818,0.65-1.241,0.986c-0.424,0.328-0.865,0.643-1.29,0.986
						c-0.455,0.3-0.873,0.654-1.328,0.953c-0.445,0.31-0.878,0.629-1.331,0.907c-0.426,0.308-0.884,0.565-1.3,0.838
						c-0.439,0.259-0.855,0.504-1.239,0.729c-0.794,0.445-1.511,0.806-2.045,1.051c-0.253,0.133-0.488,0.215-0.631,0.286
						C856.833,114.631,856.75,114.667,856.75,114.667z" />
				</g>
				<g>
					<path fill="#1B1464" d="M769.874,123.543c0,0-0.443-0.292-1.146-0.833c-0.707-0.539-1.686-1.323-2.772-2.307
						c-1.098-0.977-2.312-2.15-3.563-3.428c-0.627-0.638-1.258-1.308-1.893-1.987c-0.626-0.685-1.262-1.38-1.898-2.076
						c-0.604-0.725-1.258-1.389-1.846-2.103c-0.604-0.7-1.207-1.364-1.765-2.05c-0.57-0.666-1.129-1.296-1.64-1.933
						c-0.522-0.612-1.026-1.182-1.479-1.746c-0.469-0.545-0.898-1.045-1.279-1.488c-0.374-0.473-0.723-0.853-1.017-1.174
						c-0.584-0.648-0.914-1.084-0.914-1.084s0.514,0.039,1.408,0.221c0.444,0.093,1.008,0.194,1.586,0.423
						c0.585,0.216,1.271,0.454,1.964,0.791c0.679,0.35,1.427,0.736,2.176,1.195c0.724,0.482,1.501,0.992,2.246,1.578
						c0.727,0.599,1.505,1.207,2.203,1.9c0.712,0.68,1.422,1.393,2.081,2.138c0.654,0.753,1.307,1.506,1.884,2.297
						c0.604,0.771,1.143,1.568,1.646,2.352c1.011,1.563,1.82,3.11,2.435,4.482c0.609,1.375,1.021,2.576,1.257,3.445
						C769.796,123.019,769.874,123.543,769.874,123.543z" />
				</g>
				<g>
					<path fill="#1B1464" d="M725.667,114.667c0,0-0.076-0.091-0.219-0.261c-0.13-0.172-0.351-0.412-0.582-0.729
						c-0.488-0.626-1.161-1.513-1.894-2.572c-0.359-0.526-0.751-1.096-1.164-1.698c-0.394-0.598-0.808-1.228-1.236-1.88
						c-0.824-1.283-1.704-2.65-2.583-4.019c-0.452-0.659-0.867-1.354-1.312-2c-0.465-0.627-0.839-1.327-1.294-1.915
						c-0.44-0.602-0.812-1.253-1.245-1.788c-0.422-0.545-0.765-1.151-1.162-1.618c-0.381-0.489-0.729-0.96-1.032-1.399
						c-0.331-0.418-0.619-0.783-0.856-1.084c-0.484-0.62-0.754-1.038-0.754-1.038s0.466,0.027,1.268,0.187
						c0.396,0.089,0.907,0.167,1.445,0.349c0.526,0.197,1.118,0.444,1.762,0.739c0.657,0.281,1.258,0.72,1.941,1.151
						c0.691,0.43,1.306,0.993,1.963,1.569c0.336,0.282,0.652,0.593,0.945,0.925c0.305,0.324,0.604,0.661,0.897,1.008
						c0.609,0.68,1.094,1.457,1.633,2.202c0.469,0.783,0.94,1.565,1.331,2.371c0.381,0.809,0.756,1.605,1.021,2.413
						c0.594,1.59,0.93,3.146,1.124,4.492c0.183,1.352,0.201,2.5,0.146,3.314C725.774,114.196,725.667,114.667,725.667,114.667z" />
				</g>
				</g>
				<path fill="#1B1464" d="M828,258c0,0-22.5-42.75-17.5-61.5s31.25-23.875,38.75-48.875s7.5-48.812,7.5-48.812
				s-7.83,30.219-9.123,34.219s-9.293,12.428-10.293,13.373c-1,0.945-26.457,24.271-30.229,28.271
				c-3.772,4-37.568-13.005-42.505-18.671c-4.936-5.667-40.269-25.583-47.936-32.459L687.991,97.05
				c44.676,61.591,101.259,80.7,106.259,105.7S776.75,258,776.75,258s16.229,2.125,27.062,2.125C816.146,260.125,828,258,828,258z" />
			</g>
			</g>
			<g>
			<g>
				<path fill="#1B1464" stroke="#1B1464" strokeWidth={9} strokeMiterlimit={10} d="M525.09,179.598
				C465.093,167.883,453.093,96.5,453.093,96.5h-0.003c0,0-12,71.484-71.997,83.199" />
			</g>
			<g>
				<g>
				<rect x={407} y={177} fill="none" stroke="#1B1464" strokeWidth={8} strokeMiterlimit={10} width={92} height={72} />
				<rect x={430} y={177} fill="none" stroke="#1B1464" strokeWidth={6} strokeMiterlimit={10} width={46} height={72} />
				</g>
				<rect x={407} y={233} fill="#1B1464" stroke="#1B1464" strokeWidth={4} strokeMiterlimit={10} width={92} height={16} />
				<g>
				<rect x={407} y={214} fill="none" stroke="#1B1464" strokeWidth={4} strokeMiterlimit={10} width={12} height={16} />
				<rect x={419} y={214} fill="none" stroke="#1B1464" strokeWidth={4} strokeMiterlimit={10} width={12} height={16} />
				<rect x={475} y={214} fill="none" stroke="#1B1464" strokeWidth={4} strokeMiterlimit={10} width={12} height={16} />
				<rect x={487} y={214} fill="none" stroke="#1B1464" strokeWidth={4} strokeMiterlimit={10} width={12} height={16} />
				<rect x={430} y={214} fill="none" stroke="#1B1464" strokeWidth={4} strokeMiterlimit={10} width={23} height={16} />
				<rect x={453} y={214} fill="none" stroke="#1B1464" strokeWidth={4} strokeMiterlimit={10} width={23} height={16} />
				<rect x={430} y={214} fill="none" stroke="#1B1464" strokeWidth={4} strokeMiterlimit={10} width={12} height={16} />
				<rect x={464} y={214} fill="none" stroke="#1B1464" strokeWidth={4} strokeMiterlimit={10} width={12} height={16} />
				</g>
			</g>
			</g>
			<g>
			<rect x="1358.5" y="198.5" fill="none" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} width={101} height={61} />
			<polyline fill="none" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} points="1460,259.5 1358.5,259.5 1358.5,211.5 
			1447.5,211.5 1447.5,245.5 1373.5,245.5 1373.5,222.5 1430.5,222.5 1430.5,232.5 1385,232.5 	" />
			</g>
			<g>
			<g>
				<rect x="898.5" y="251.5" fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} width={99} height={48} />
				<polyline fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} points="998,299.5 898.5,299.5 898.5,261.5 
				985.5,261.5 985.5,288.5 913.5,288.5 913.5,270.5 968.5,270.5 968.5,278.5 924,278.5 		" />
			</g>
			<g>
				<rect x="798.5" y="251.5" fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} width={100} height={48} />
				<polyline fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} points="899,299.5 798.5,299.5 798.5,261.5 
				886.5,261.5 886.5,288.5 813.5,288.5 813.5,270.5 869.5,270.5 869.5,278.5 825,278.5 		" />
			</g>
			<g>
				<rect x="698.5" y="251.5" fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} width={99} height={48} />
				<polyline fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} points="798,299.5 698.5,299.5 698.5,261.5 
				785.5,261.5 785.5,288.5 713.5,288.5 713.5,270.5 768.5,270.5 768.5,278.5 725,278.5 		" />
			</g>
			<g>
				<rect x="599.5" y="251.5" fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} width={99} height={48} />
				<polyline fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} points="699,299.5 599.5,299.5 599.5,261.5 
				686.5,261.5 686.5,288.5 614.5,288.5 614.5,270.5 669.5,270.5 669.5,278.5 625,278.5 		" />
			</g>
			<g>
				<rect x="498.5" y="251.5" fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} width={100} height={48} />
				<polyline fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} points="599,299.5 498.5,299.5 498.5,261.5 
				586.5,261.5 586.5,288.5 513.5,288.5 513.5,270.5 569.5,270.5 569.5,278.5 525,278.5 		" />
			</g>
			<g>
				<rect x="399.5" y="251.5" fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} width={99} height={48} />
				<polyline fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} points="499,299.5 399.5,299.5 399.5,261.5 
				486.5,261.5 486.5,288.5 414.5,288.5 414.5,270.5 469.5,270.5 469.5,278.5 426,278.5 		" />
			</g>
			<g>
				<rect x="299.5" y="251.5" fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} width={99} height={48} />
				<polyline fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} points="399,299.5 299.5,299.5 299.5,261.5 
				386.5,261.5 386.5,288.5 313.5,288.5 313.5,270.5 369.5,270.5 369.5,278.5 325,278.5 		" />
			</g>
			<g>
				<rect x="199.5" y="251.5" fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} width={100} height={48} />
				<polyline fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} points="300,299.5 199.5,299.5 199.5,261.5 
				287.5,261.5 287.5,288.5 214.5,288.5 214.5,270.5 270.5,270.5 270.5,278.5 226,278.5 		" />
			</g>
			<g>
				<rect x="99.5" y="251.5" fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} width={99} height={48} />
				<polyline fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} points="199,299.5 99.5,299.5 99.5,261.5 
				186.5,261.5 186.5,288.5 114.5,288.5 114.5,270.5 169.5,270.5 169.5,278.5 126,278.5 		" />
			</g>
			<g>
				<rect x="-0.5" y="251.5" fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} width={100} height={48} />
				<polyline fill="#FFFFFF" stroke="#1B1464" strokeWidth={5} strokeMiterlimit={10} points="100,299.5 -0.5,299.5 -0.5,261.5 
				87.5,261.5 87.5,288.5 14.5,288.5 14.5,270.5 70.5,270.5 70.5,278.5 26,278.5 		" />
			</g>
			</g>
			<g id="guides" display="none">
			<path id="guide9" display="inline" fill="none" stroke="#F9A2E8" strokeMiterlimit={10} d="M721,187.5
			c0,0-101.092,49.428-172.25,15.009s-2.338-44.172,0-32.546S504.591,243.414,339.599,241
			c-164.992-2.414-217.487-75.914-359.982-62.413" />
			<path id="guide8" display="inline" fill="none" stroke="#F9B7EF" strokeMiterlimit={10} d="M867.667,187.5
			c0,0-166.806,24.994-275.442-36.956c-108.636-61.95-178.697,1.918-266.661,17.231C237.6,183.088,134.105,152.98,89.108,138.786
			s-104.994,3.728-119.994,9.766" />
			<path id="guide7" display="inline" fill="none" stroke="#F9A2E8" strokeMiterlimit={10} d="M653,125.919
			c0,0-234.081,71.737-314.904,58.748c-80.823-12.989,5.422-43.905,5.861-25.667c0.439,18.238-124.353,61.413-358.343,63" />
			<path id="guide6" display="inline" fill="none" stroke="#F9A2E8" strokeMiterlimit={10} d="M915.588,114.043
			c0,0-231.039,105.086-308.211,78.441c-77.172-26.646-5.957-39.653-7.877-18.525S376.604,237.412,307.101,214
			S76.396,179.879,100,155.984s53.602,15.263,22.104,28.683s-176.991,7.817-176.991,7.817" />
			<path id="guide5" display="inline" fill="none" stroke="#F7C4F0" strokeMiterlimit={10} d="M706.245,86
			c0,0-128.979,61.241-213.245,20.744c-84.267-40.497-190.587,6.01-220.914,21.589S75.632,120.016,49.75,103.667
			s-53.635-13.59-80.636-2.333" />
			<path id="guide4" display="inline" fill="none" stroke="#F7BAEE" strokeMiterlimit={10} d="M792.667,138.1
			c0,0-268.401,52.589-373.667,12.444c-105.266-40.146-297.519-11.295-343.705,3.875s-124.182-8.81-130.182-16.319" />
			<path id="guide3" display="inline" fill="none" stroke="#F9A2E8" strokeMiterlimit={10} d="M734.758,79.509
			c0,0-168.188-30.675-225.673-3.534s-21.17,36.639-10.585,18.319c10.585-18.32-122.22-36.344-179.193-18.319
			C262.334,94,6.62,101.917-20.384,66.006" />
			<path id="guide2" display="inline" fill="none" stroke="#F9A2E8" strokeMiterlimit={10} d="M830.14,120.667
			c0,0-350.445,44.69-430.64,0c-80.193-44.691-403.39-6.413-448.387,14.505" />
			<path id="guide1" display="inline" fill="none" stroke="#F7BCEE" strokeMiterlimit={10} d="M841.465,66.006
			c0,0-223.051,51.733-292.715,26.66s-236.766,2.449-305.413,19.772C174.69,129.761,9.62,115.914-20.384,84.254" />
			<path id="guide10" display="inline" fill="none" stroke="#F7BAEE" strokeMiterlimit={10} d="M792.667,150
			c0,0-186.532,35.689-261.083-1.448c-74.551-37.137-236.763-3.741-255.538,9.692c-18.775,13.433,9.859,24.797,14.317,5.682
			c4.458-19.115-330.251,2.633-339.25,10.147" />
			</g>
			<g id="strBlossom">
			<text id="alex" transform="translate(40,150)" fill="#F9BBF0" fontFamily="Quicksand-Regular" fontSize={180}>Alexs</text>
			<text id="repository" transform="translate(310,150)" fill="#F7BCEE" fontFamily="Quicksand-Regular" fontSize={180}>repository</text>
			</g>
			<g id="petals">
			<path fill="#F9A2E8" stroke="#F9A2E8" strokeMiterlimit={10} d="M3.956-1.035c0,0-2.888-3.829-6.604-1.239
			c-3.717,2.591,0.571,7.434,0.962,7.659C-1.296,5.61,5.377,3.246,3.956-1.035z">
				<animateMotion begin="0s" dur="5s" rotate="auto" repeatCount="indefinite">
				<mpath href="#guide1">
				</mpath></animateMotion></path>
			<path fill="#F9BBF0" d="M4.952-1.298c0,0-5.636,0.469-6.222-2.58c0,0-3.283,5.078-0.352,7.289
			C1.31,5.622,4.605-0.712,4.952-1.298z">
				<animateMotion begin="-0.5s" dur="5s" rotate="auto" repeatCount="indefinite">
				<mpath href="#guide1">
				</mpath></animateMotion></path>
			<path fill="#F7BCEE" d="M4.952,0.039c0,0-2.24-4.371-6.109-2.914C-5.025-1.418-4.428,3.438-2.946,4.41
			C-1.465,5.382,4.407,2.954,4.952,0.039z">
				<animateMotion begin="-1s" dur="5s" rotate="auto" repeatCount="indefinite">
				<mpath href="#guide3">
				</mpath></animateMotion></path>
			<path fill="#F9A2E8" stroke="#F9A2E8" strokeMiterlimit={10} d="M3.956-1.035c0,0-2.888-3.829-6.604-1.239
			c-3.717,2.591,0.571,7.434,0.962,7.659C-1.296,5.61,5.377,3.246,3.956-1.035z">
				<animateMotion begin="-1.5s" dur="5s" rotate="auto" repeatCount="indefinite">
				<mpath href="#guide4">
				</mpath></animateMotion></path>
			<path fill="#F9BBF0" d="M4.952-1.298c0,0-5.636,0.469-6.222-2.58c0,0-3.283,5.078-0.352,7.289
			C1.31,5.622,4.605-0.712,4.952-1.298z">
				<animateMotion begin="-2s" dur="5s" rotate="auto" repeatCount="indefinite">
				<mpath href="#guide5">
				</mpath></animateMotion></path>
			<path fill="#F7BCEE" d="M4.952,0.039c0,0-2.24-4.371-6.109-2.914C-5.025-1.418-4.428,3.438-2.946,4.41
			C-1.465,5.382,4.407,2.954,4.952,0.039z">
				<animateMotion begin="-2.5s" dur="5s" rotate="auto" repeatCount="indefinite">
				<mpath href="#guide6">
				</mpath></animateMotion></path>
			<path fill="#F9A2E8" stroke="#F9A2E8" strokeMiterlimit={10} d="M3.956-1.035c0,0-2.888-3.829-6.604-1.239
			c-3.717,2.591,0.571,7.434,0.962,7.659C-1.296,5.61,5.377,3.246,3.956-1.035z">
				<animateMotion begin="-3s" dur="5s" rotate="auto" repeatCount="indefinite">
				<mpath href="#guide7">
				</mpath></animateMotion></path>
			<path fill="#F9BBF0" d="M4.952-1.298c0,0-5.636,0.469-6.222-2.58c0,0-3.283,5.078-0.352,7.289
			C1.31,5.622,4.605-0.712,4.952-1.298z">
				<animateMotion begin="-3.5s" dur="5s" rotate="auto" repeatCount="indefinite">
				<mpath href="#guide8">
				</mpath></animateMotion></path>
			<path fill="#F7BCEE" d="M4.952,0.039c0,0-2.24-4.371-6.109-2.914C-5.025-1.418-4.428,3.438-2.946,4.41
			C-1.465,5.382,4.407,2.954,4.952,0.039z">
				<animateMotion begin="-4s" dur="5s" rotate="auto" repeatCount="indefinite">
				<mpath href="#guide9">
				</mpath></animateMotion></path>
			</g>
			<BlossomText/>
			<image id='blossomQuote' href="/singleQuote.png" x="225" y="70" height="50px" width="30px"/>
		</svg>
	);
}


let BlossomThemeColours: ThemeColours = {
	primary: '#F9A2E8',
	secondary: '#1B1464',
	background: '#1B1464',
	buttonSelected: '#3e3691',
	heading: '#F9A2E8'
}
/*heading: '#F4F06E'*/

export var BlossomTheme: Theme = new Theme("blossom", <Blossom/>, BlossomThemeColours);
